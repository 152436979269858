import React from "react"
import styled from "styled-components"
import { Switch, Route, Redirect } from "react-router-dom"
import CreateAgentComponent from "dumbComponents/Authentication/SignUp"
import { PubnubForAIDashboard } from "dumbComponents/AIDashboard/Components/index"
import AiDashboard from "dumbComponents/AIDashboard"
import Listings from "dumbComponents/Listings"
//dashbord listing
import ListingsDashboard from "dumbComponents/ListingDashboard/index_dashboad"
import AddOffers from "dumbComponents/Offers"
import Offers from "dumbComponents/OffersDashboard"
import PendingRequests from "./PendingRequestions"
import BrokerHeader from "./Components/BrokerHeader"
import AgentDashboard from "./AgentDashboard"
import ChecklistTemplateDocument from "../ChecklistDocuments/index"
import UploadListingDocusign from "./AgentDashboard/Docusign/UploadListingDocusign"

// Global Pages
import Roster from "./GlobalPages/Roster"
import AllAssets from "./GlobalPages/AllAssets"
import Teams from "./GlobalPages/Teams"
import TCListing from "./GlobalPages/TCListing"
import Resources from "./GlobalPages/Resources"
import ViewPage from "./AgentDashboard/Docusign/ViewPage"
import OffersDocusign from "./AgentDashboard/OfferDocusign/Docusign"
import UploadOfferDocusign from "./AgentDashboard/OfferDocusign/UploadOfferDocusign"
import AddEditResources from "./GlobalPages/Resources/AddEditResources"
import AddDealsGlobal from "./GlobalPages/Deals/AddGlobalDeals"
import FinancingDashboard from "../../dumbComponents/FinancingDashboard"
import RecruitAndEarn from '../../dumbComponents/RecruitAndEarn';
import RecruitAndEarnAnalytics from '../../dumbComponents/RecruitAndEarn/Analytics';
import AgentPipeline from "./GlobalPages/AgentPipeline";
import RecordInvite from "../RecruitAndEarn/RecordInvite"
import MortgagePage from "./MortgageDashboard"
import UploadAIDocuments from "dumbComponents/AIDashboard/Components/AIDocuments/index"
// import RecruitAndEarnV2 from "../RecruitAndEarnV2"
import Cashback from "../Cashback"
import RecruitAndEarnV2 from "../RecruitDashboard"
import InviteAgent from "../RecruitDashboard/Invite"
import ICARedirect from "./ICARedirect/index"
import ConsumerAppTable from "dumbComponents/ConsumerApp/index"
import NewRoster from "./GlobalPages/NewRoster"

const Wrap = styled.div`
  width: 100%;
  padding-top: 60px;
`


class Dashboard extends React.PureComponent {
  state = {}

  componentDidMount() {
    // const {  } = this.props
    window.document.body.style.marginTop = "0px"
  }
  render() {
    const { location } = this.props
    return (
      <Wrap>
        <PubnubForAIDashboard />
        <BrokerHeader />
        <Switch>
          <Route exact path="/broker/ai" component={AiDashboard} />
          <Route exact path="/broker/requests" component={PendingRequests} />
          <Route exact path="/broker/mortgage-rates" component={MortgagePage} />
          <Route exact path="/broker/new-roster" component={NewRoster} />
          <Route exact path="/broker/roster" component={Roster} />
          <Route exact path="/broker/agent-pipeline" component={AgentPipeline} />
          <Route exact path="/broker/all-assets" component={AllAssets} />
          <Route path="/broker/teams" component={Teams} />
          <Route exact path="/broker/resources" component={Resources} />
          <Route path="/broker/listing-documents" component={ViewPage} />
          <Route path="/broker/checklist-documents" component={ChecklistTemplateDocument} />
          <Route path="/broker/consumer-app" component={ConsumerAppTable} />
          <Route path="/broker/offers-documents" component={OffersDocusign} />
          <Route exact path="/broker/resources/create" component={AddEditResources} />
          <Route exact path="/broker/resources/edit/:resourceId" component={AddEditResources} />
          <Route path="/broker/tc/list" component={TCListing} />
          <Route path="/broker/tc/offers" component={Offers} />
          <Route path="/broker/tc/add" component={AddDealsGlobal} />
          <Route path="/broker/tc/add-listings" component={Listings} />
          <Route path="/broker/tc/add-offers" component={AddOffers} />
          <Route path="/broker/dashboard/ica/documents-callback" component={ICARedirect} />
          <Route path="/broker/dashboard/:agentId" component={props => <AgentDashboard key={location.pathname} {...props} />} />
          <Route exact path="/broker/refer-a-friend" component={RecruitAndEarn} />
          <Route path="/broker/invite/add" component={RecordInvite} />
          <Route path="/broker/invite/edit/:inviteId" component={RecordInvite} />
          <Route path="/broker/create" component={CreateAgentComponent} />
          <Route path="/broker/financing" component={FinancingDashboard} />
          <Route path="/broker/recruit-and-earn" component={RecruitAndEarnV2} />
          <Route path="/broker/cashback" component={Cashback} />
          <Route path="/broker/invite" component={InviteAgent} />
          <Route path="/broker/tc/listings" component={ListingsDashboard} />
          <Route path="/broker/upload-templates-offers" component={UploadOfferDocusign} />
          <Route path="/broker/upload-templates-listing" component={UploadListingDocusign} />
          <Route path="/broker/upload-ai-documents" component={UploadAIDocuments} />
          {/* <Route exact path="/broker/agent-invite-analytics/:agentId" component={RecruitAndEarnAnalytics}/> */}
          <Redirect path="/broker" to="/broker/roster" />
        </Switch>
      </Wrap>
    )
  }
}

export default Dashboard
