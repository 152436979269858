import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import {
  fetchSubscriptionsAction,
  fetchStripePlansAction,
} from "container/Agent/actions"
import {
  getCurrentPlan,
  getSelctedPlansList,
  getAddons,
  createSelectedPlanMapFromAPI,
  getAllPlans,
} from "./selector"
import {
  getAgentsSubscriptionAction,
  changePlanDurationAction,
  getPlanbenefitsAction,
  selectPlanAction,
  setTeamSubDetailsAction,
  getClientSecretAction,
  makeDefaultMethodAction,
  selectAddonAction,
  checkoutBrokerPlanAction,
  checkCouponAction,
  removeCouponCodeAction,
  cancelSubscriptionAction,
  resetSelectionsAction,
  getSubsribedAddonsAction,
  clearAddonsAction,
  addOnPlanIdsAction,
  checkoutBrokerPlanV2Action,
  updateSubscriptionV2Action,
  toggleICADetailsModalAction,
  fetchICAListingsAction,
  fetchAgentICADetailsAction,
  triggerSenderViewDocuSignAction,
  triggerRecieverViewDocuSignAction,
  updateICADocumentStatusAction,
  toggleTeamAgreementTypeAction,
  setCurrentAgentViewedAction,
  getAgentBrokerageInfoMetadataAction,
  getAgentForRosterPageAction,
  uploadICAAction,
  uploadTeamAgreementAction,
  inviteWithoutSubscriptionAction,
  mergeReviewAndSendDocumentsAction,
  fetchTeamAgreementsAction,
  toggleUploadModeForICAAction,
  uploadICAFileAction,
  sendOnboardingEmailAction,
  copyOnboardingURLAction,
  fetchFullySignedICAAction,
  uploadFullySignedICAAction,
  toggleFullySignedICAUploadUIAction,
  saveTeamAgreementAction,
  currentlySelectedTypeAction,
  triggerTemplateEditViewAction,
  showUpdateLiveDotAction,
  getTemplateDownloadURLAction,
  setTeamIDFromURLAction,
  highlightSelectedAddonsAction,
  triggerVoidAndDeleteAction,
  toggleVoidAndDeleteModalAction,
  triggerDeleteFullyLoadedICAAction,
  toggleTeamCreateModalAction,
  inviteWithoutSubscriptionPayloadCreationAction,
  toggleInviteWithoutSubscriptionModalAction,
  handleCouponFieldChangeAction,
  updateSubscriptionDateAction,
  defaultPaymentAction,
  editPaymentAction,
  deletePaymentAction,
  fetchInvoiceDataAction,
  setSupportFeeAction,
  setPaymentByLeadAction,
  setPaymentByLeadAmountAction,
  fetchSubStatusAction,
  fetchEnoDataAction,
} from "./actions"

function mapStateToProps(state) {
  const { brokerDashboard } = state
  const allBrokerPlans = getAllPlans(state)
  const {
    selectedDuration,
    planBenefitsResponse,
    selectedPlan,
    selectedAddons,
    couponCodeResponse,
    isBusy,
    saveCardResponse,
    addOnPlanIds,
    addonsResponse,
    checkoutBrokerPlanV2Response,
    updateSubscriptionV2Response,
    cancelSubscriptionResponse,
    isICAModalVisible,
    fetchICAListingsResponse,
    fetchAgentICADetailsResponse,
    triggerSenderViewDocuSignResponse,
    triggerRecieverViewDocuSignResponse,
    updateICADocumentStatusResponse,
    teamAgreementViewType,
    currentAgentIDViewed,
    getBrokerageInfoMetabaseResponse,
    rosterPageAgentsResponse,
    uploadTeamAgreementResponse,
    uploadICAResponse,
    inviteWithoutSubscriptionResponse,
    mergeReviewAndSendDocumentsResponse,
    fetchTeamAgreementsResponse,
    icaUploadModeVisible,
    sendOnboardingEmailResponse,
    copyOnboardingURLResponse,
    fetchFullySignedICAResponse,
    uploadFullySignedICAResponse,
    fullySignedICAUploadVisible,
    disableICAModes,
    saveTeamAgreementResponse,
    agentBrokerageInfoResponse,
    currentTypeICAFlow,
    rarTeamListResponse,
    teamBasedCurrentTeamAgreementStatus,
    triggerTemplateEditViewResponse,
    agentDetailsResponse,
    isLiveLoaderShown,
    getTemplateDownloadURLResponse,
    urlBasedTeamId,
    fetchAgentAsmRecruiterInfoResponse,
    addOnsSelected,
    addOnsRemoved,
    isTriggerVoidAndDeleteModalOpen,
    triggerDeleteFullyLoadedICAResponse,
    toggleTeamModal,
    inviteWithoutSubscriptionPayload,
    isInviteWithoutSubscriptionModalOpen,
    currentCouponText,
    updateSubscriptionDateResponse,
    supportFee,
    paymentByLead,
    paymentByLeadAmount,
  } = brokerDashboard

  const currentPlan = getCurrentPlan(state)
  const selectedPlanList = getSelctedPlansList(state)
  const addons = getAddons(state)
  const currentAPISelectionMap = createSelectedPlanMapFromAPI({
    addons,
    currentPlan,
  })
  return {
    currentPlan,
    selectedPlanList,
    selectedDuration,
    planBenefitsResponse,
    selectedPlan,
    selectedAddons,
    couponCodeResponse,
    isBusy,
    currentAPISelectionMap,
    addons,
    saveCardResponse,
    allBrokerPlans,
    addOnPlanIds,
    addonsResponse,
    checkoutBrokerPlanV2Response,
    updateSubscriptionV2Response,
    cancelSubscriptionResponse,
    isICAModalVisible,
    fetchICAListingsResponse,
    fetchAgentICADetailsResponse,
    triggerSenderViewDocuSignResponse,
    triggerRecieverViewDocuSignResponse,
    updateICADocumentStatusResponse,
    teamAgreementViewType,
    currentAgentIDViewed,
    getBrokerageInfoMetabaseResponse,
    rosterPageAgentsResponse,
    uploadTeamAgreementResponse,
    uploadICAResponse,
    inviteWithoutSubscriptionResponse,
    mergeReviewAndSendDocumentsResponse,
    fetchTeamAgreementsResponse,
    icaUploadModeVisible,
    sendOnboardingEmailResponse,
    copyOnboardingURLResponse,
    fetchFullySignedICAResponse,
    uploadFullySignedICAResponse,
    fullySignedICAUploadVisible,
    disableICAModes,
    saveTeamAgreementResponse,
    agentBrokerageInfoResponse,
    currentTypeICAFlow,
    rarTeamListResponse,
    teamBasedCurrentTeamAgreementStatus,
    triggerTemplateEditViewResponse,
    agentDetailsResponse,
    isLiveLoaderShown,
    getTemplateDownloadURLResponse,
    urlBasedTeamId,
    fetchAgentAsmRecruiterInfoResponse,
    addOnsSelected,
    addOnsRemoved,
    isTriggerVoidAndDeleteModalOpen,
    triggerDeleteFullyLoadedICAResponse,
    toggleTeamModal,
    inviteWithoutSubscriptionPayload,
    isInviteWithoutSubscriptionModalOpen,
    currentCouponText,
    updateSubscriptionDateResponse,
    supportFee,
    paymentByLead,
    paymentByLeadAmount,
  }
}

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      getAgentsSubscription: getAgentsSubscriptionAction.request,
      fetchSubscriptions: fetchSubscriptionsAction.request,
      fetchStripePlans: fetchStripePlansAction.request,
      changePlanDuration: changePlanDurationAction.call,
      getPlanbenefits: getPlanbenefitsAction.request,
      selectPlan: selectPlanAction.call,
      teamSubPlan: setTeamSubDetailsAction.call,
      defaultPayment: defaultPaymentAction.request,
      editPayment: editPaymentAction.request,
      getClientSecret: getClientSecretAction.request,
      deletePayment: deletePaymentAction.request,
      makeDefaultMethod: makeDefaultMethodAction.request,
      fetchInvoiceData: fetchInvoiceDataAction.request,
      fetchSubStatusData: fetchSubStatusAction.request,
      fetchEnoStatusData: fetchEnoDataAction.request,
      selectAddon: selectAddonAction.call,
      highlightSelectedAddon: highlightSelectedAddonsAction.call,
      checkoutBrokerPlan: checkoutBrokerPlanAction.request,
      checkoutBrokerPlanV2: checkoutBrokerPlanV2Action.request,
      updateSubscriptionV2: updateSubscriptionV2Action.request,
      checkCoupon: checkCouponAction.request,
      setSupportFee: setSupportFeeAction.call,
      setPaymentByLead: setPaymentByLeadAction.call,
      setPaymentByLeadAmount: setPaymentByLeadAmountAction.call,
      removeCouponCode: removeCouponCodeAction.call,
      cancelSubscription: cancelSubscriptionAction.request,
      resetSelections: resetSelectionsAction.call,
      getSubsribedAddons: getSubsribedAddonsAction.request,
      clearAddons: clearAddonsAction.call,
      addOnPlanIdsToAdd: addOnPlanIdsAction.call,
      toggleICAModal: toggleICADetailsModalAction.call,
      fetchICAListings: fetchICAListingsAction.request,
      fetchAgentICADetails: fetchAgentICADetailsAction.request,
      triggerSenderViewDocuSign: triggerSenderViewDocuSignAction.request,
      triggerRecieverViewDocuSign: triggerRecieverViewDocuSignAction.request,
      updateICADocumentStatus: updateICADocumentStatusAction.request,
      toggleTeamAgreementType: toggleTeamAgreementTypeAction.call,
      setCurrentAgentViewed: setCurrentAgentViewedAction.call,
      getAgentBrokerageMetadataInfo: getAgentBrokerageInfoMetadataAction.request,
      getAgentForRosterPage: getAgentForRosterPageAction.request,
      uploadICA: uploadICAAction.request,
      uploadTeamAgreement: uploadTeamAgreementAction.request,
      inviteWithoutSubscription: inviteWithoutSubscriptionAction.request,
      mergeReviewAndSendDocuments: mergeReviewAndSendDocumentsAction.request,
      fetchTeamAgreements: fetchTeamAgreementsAction.request,
      toggleUploadModeForICA: toggleUploadModeForICAAction.call,
      uploadICAFile: uploadICAFileAction.request,
      sendOnboardingEmail: sendOnboardingEmailAction.request,
      copyOnboardingURL: copyOnboardingURLAction.request,
      fetchFullySignedICA: fetchFullySignedICAAction.request,
      uploadFullySignedICA: uploadFullySignedICAAction.request,
      toggleFullySignedICAUploadUI: toggleFullySignedICAUploadUIAction.call,
      saveTeamAgreement: saveTeamAgreementAction.request,
      setICAFlowType: currentlySelectedTypeAction.call,
      triggerTemplateEditView: triggerTemplateEditViewAction.request,
      showUpdateLiveDot: showUpdateLiveDotAction.call,
      getTemplateDownloadURL: getTemplateDownloadURLAction.request,
      setTeamIDFromURL: setTeamIDFromURLAction.call,
      toggleVoidAndDeleteModal: toggleVoidAndDeleteModalAction.call,
      triggerVoidAndDelete: triggerVoidAndDeleteAction.request,
      triggerDeleteFullyLoaded: triggerDeleteFullyLoadedICAAction.request,
      toggleTeamCreateModal: toggleTeamCreateModalAction.call,
      inviteWithoutSubscriptionPayloadCreation: inviteWithoutSubscriptionPayloadCreationAction.call,
      toggleInviteWithoutSubscriptionModal: toggleInviteWithoutSubscriptionModalAction.call,
      handleCouponFieldChange: handleCouponFieldChangeAction.call,
      updateSubscriptionDate: updateSubscriptionDateAction.request,
    },
    dispatch
  ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)
