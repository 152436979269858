import { call, put, takeLatest } from "redux-saga/effects"; //takevery , take

import { isSuccess } from "services/Utils";
import { toast } from "react-toastify";
import { message } from "antd";
import {
  fetchAppCustomisationsAction,
  fetchConsumerAction,
  fetchConsumerTeamsAction,
  fetchFamilyMembersAction,
  fetchPropertiesAction,
  fetchSearchBoardAction,
  fetchWaitlistAction,
  joinTheWaitlistAction,
  saveWaitlistStatusAction,
  updateAppCustomisationsAction,
  uploadAppAssetsAction,
} from "./actions";
import {
  fetchAppCustomisationsAPI,
  fetchConsumersAPI,
  fetchConsumerTeamsAPI,
  fetchFamilyMembersAPI,
  fetchPropertiesAPI,
  fetchSearchBoardAPI,
  fetchWaitlistAPI,
  joinTheWaitlistAPI,
  saveWaitlistStatusAPI,
  updateAppCustomisationsAPI,
  uploadAppAssetsAPI,
} from "./api";
const token = process.env.REACT_APP_SECRET_ID;

function* fetchWaitlistSaga(action) {
  try {
    const {
      startDate,
      endDate,
      status,
      searchText,
      limit,
      skip,
      callback,
    } = action.data;
    const data = yield call(
      fetchWaitlistAPI,
      startDate,
      endDate,
      status,
      searchText,
      limit,
      skip
    );
    if (isSuccess(data)) {
      const { response } = data.data;
      yield put(fetchWaitlistAction.success(response));
      if (callback) callback();
    }
  } catch (error) {
    message.error("Something went again.. Try again later");
    yield put(fetchWaitlistAction.failure(error.message));
  }
}

function* fetchConsumerSaga(action) {
  try {
    const { limit, skip, name, location, teamId, callback } = action.data;
    const data = yield call(
      fetchConsumersAPI,
      limit,
      skip,
      name,
      location,
      teamId
    );
    if (isSuccess(data)) {
      const { response } = data.data;
      yield put(fetchConsumerAction.success(response));
      if (callback) callback();
    }
  } catch (error) {
    message.error("Something went again.. Try again later");
    yield put(fetchConsumerAction.failure(error.message));
  }
}

function* fetchSearchBoardSaga(action) {
  try {
    const {
      limit,
      skip,
      consumerUserId,
      email,
      location,
      teamId,
      callback,
    } = action.data;
    const data = yield call(
      fetchSearchBoardAPI,
      limit,
      skip,
      consumerUserId,
      email,
      location,
      teamId
    );
    if (isSuccess(data)) {
      const { response } = data.data;
      yield put(fetchSearchBoardAction.success(response));
      if (callback) callback();
    }
  } catch (error) {
    message.error("Something went again.. Try again later");
    yield put(fetchSearchBoardAction.failure(error.message));
  }
}

function* fetchPropertiesSaga(action) {
  try {
    const { limit, skip, consumerId, like_status, callback } = action.data;
    const data = yield call(
      fetchPropertiesAPI,
      limit,
      skip,
      consumerId,
      like_status
    );
    if (isSuccess(data)) {
      const { response } = data.data;
      yield put(fetchPropertiesAction.success(response));
      if (callback) callback();
    }
  } catch (error) {
    message.error("Something went again.. Try again later");
    yield put(fetchPropertiesAction.failure(error.message));
  }
}

function* fetchFamilyMemberSaga(action) {
  try {
    const { limit, skip, consumerId, callback } = action.data;
    const data = yield call(fetchFamilyMembersAPI, limit, skip, consumerId);
    if (isSuccess(data)) {
      const { response } = data.data;
      yield put(fetchFamilyMembersAction.success(response));
      if (callback) callback();
    }
  } catch (error) {
    message.error("Something went again.. Try again later");
    yield put(fetchFamilyMembersAction.failure(error.message));
  }
}

function* fetchConsumerTeamsSaga() {
  try {
    const data = yield call(fetchConsumerTeamsAPI);
    if (isSuccess(data)) {
      const { response } = data.data;
      yield put(fetchConsumerTeamsAction.success(response));
    }
  } catch (error) {
    message.error("Something went again.. Try again later");
    yield put(fetchConsumerTeamsAction.failure(error.message));
  }
}

function* saveWaitlistStatusSaga(action) {
  try {
    const { payload, callback } = action.data;
    const requestPayload = {
      status: payload.status,
      reason: payload.reason,
    };
    const data = yield call(saveWaitlistStatusAPI, payload.id, requestPayload);
    if (isSuccess(data)) {
      const { response } = data.data;
      message.success("Status updated successfully");
      yield put(saveWaitlistStatusAction.success(response));
      if (callback) callback();
    }
  } catch (error) {
    const { payload, callback } = action.data;
    message.error("Something went again.. Try again later");
    yield put(saveWaitlistStatusAction.failure(error.message));
    if (callback) callback();
  }
}

function* updateAppCustomisationsSaga(action) {
  try {
    const { payload, callback } = action.data;
    const data = yield call(updateAppCustomisationsAPI, payload);
    if (isSuccess(data)) {
      const { response } = data.data;
      yield put(updateAppCustomisationsAction.success(response));
      if (callback) callback();
    }
  } catch (error) {
    message.error("Something went again.. Try again later");
    yield put(updateAppCustomisationsAction.failure(error.message));
  }
}

function* fetchAppCustomisationsSaga(action) {
  try {
    const id = action.data;
    const data = yield call(fetchAppCustomisationsAPI, id);
    if (isSuccess(data)) {
      const { response } = data.data;
      yield put(fetchAppCustomisationsAction.success(response));
    }
  } catch (error) {
    message.error("Something went again.. Try again later");
    yield put(fetchAppCustomisationsAction.failure(error.message));
  }
}

function* uploadAppAssetsSaga(action) {
  try {
    const payload = action.data;
    const data = yield call(uploadAppAssetsAPI, payload);
    if (isSuccess(data)) {
      const { response } = data.data;
      yield put(uploadAppAssetsAction.success(response));
    }
  } catch (error) {
    message.error("Something went again.. Try again later");
    yield put(uploadAppAssetsAction.failure(error.message));
  }
}

function* joinTheWaitlistSaga(action) {
  console.log("hi from here");

  try {
    const payload = action.data;
    const data = yield call(joinTheWaitlistAPI, payload);
    console.log("payload data is ", data);

    if (isSuccess(data)) {
      const { response } = data.data;
      yield put(joinTheWaitlistAction.success(response));
    }
  } catch (error) {
    message.error("Something went again.. Try again later");
    yield put(joinTheWaitlistAction.failure(error.message));
  }
}

function* ConsumerAppSaga() {
  yield takeLatest(fetchWaitlistAction.REQUEST, fetchWaitlistSaga);
  yield takeLatest(saveWaitlistStatusAction.REQUEST, saveWaitlistStatusSaga);
  yield takeLatest(uploadAppAssetsAction.REQUEST, uploadAppAssetsSaga);
  yield takeLatest(
    updateAppCustomisationsAction.REQUEST,
    updateAppCustomisationsSaga
  );
  yield takeLatest(
    fetchAppCustomisationsAction.REQUEST,
    fetchAppCustomisationsSaga
  );
  yield takeLatest(fetchConsumerAction.REQUEST, fetchConsumerSaga);
  yield takeLatest(fetchSearchBoardAction.REQUEST, fetchSearchBoardSaga);
  yield takeLatest(fetchPropertiesAction.REQUEST, fetchPropertiesSaga);
  yield takeLatest(fetchFamilyMembersAction.REQUEST, fetchFamilyMemberSaga);
  yield takeLatest(fetchConsumerTeamsAction.REQUEST, fetchConsumerTeamsSaga);
  yield takeLatest(joinTheWaitlistAction.REQUEST, joinTheWaitlistSaga);
}

export default ConsumerAppSaga;
