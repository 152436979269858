import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Table,
  Tag,
  Button,
  Space,
  Dropdown,
  Menu,
  Modal,
  Input,
  Tabs,
  DatePicker,
  Badge,
  Select,
} from "antd";
import ConsumerApp from "container/ConsumerApp/index";
import agentSearchContainer from "container/BrokerDashboard/agentSearchContainer";
import globalPagesContainer from "container/BrokerDashboard/globalPagesContainer";
import { get } from "lodash";
import NumberFormat from "react-number-format";
import Loader from "dumbComponents/common/UI/Loader";
import {
  DownOutlined,
  EllipsisOutlined,
  RightOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { debounce } from "lodash";
import AppCustomisation from "dumbComponents/AppCustomisation/index";
import SearchBoards from "./SearchBoards";
import Consumers from "./Consumers";
import styled from "styled-components";

const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const statusMapping = {
  request_received: "Request Received",
  dev_in_progress: "Dev In Progress",
  ready_for_devs: "Ready for Devs",
  submitted_to_app_store: "Submitted to App Store",
  app_live: "App Live",
  request_cancelled: "Request Cancelled",
};

const statusColors = {
  request_received: "blue",
  dev_in_progress: "blue",
  ready_for_devs: "purple",
  submitted_to_app_store: "orange",
  app_live: "green",
  request_cancelled: "red",
};

const styles = {
  tableContainer: {
    padding: "16px",
    backgroundColor: "#F9FAFB", // Light gray background for container
  },
  filterWrapper: {
    display: "flex",
  },
  header: {
    fontSize: "20px",
    fontWeight: "bold",
    marginBottom: "16px",
    width: "500px",
  },
  filterButton: {
    borderRadius: "4px",
    backgroundColor: "#FFFFFF", // White background for filter button
    color: "#000000", // Black text color
    border: "1px solid #E0E0E0", // Light border color
  },
  inputSearch: {
    width: "220px",
    marginRight: "12px",
    height: "35px !important",
  },
  modalTitle: {
    fontSize: "18px",
    fontWeight: "600",
  },
  modalButton: {
    borderRadius: "4px",
  },
  actionButton: {
    borderRadius: "4px",
    backgroundColor: "#1890ff", // Primary button color
    borderColor: "#1890ff", // Border matching primary color
    color: "#FFFFFF", // White text color
  },
  dropdownMenu: {
    borderRadius: "4px",
  },
  tag: {
    cursor: "pointer",
  },
};

const WrapAgent = styled.div`
  position: relative;
  width: 300px;
`;

const AgentList = styled.div`
  position: absolute;
  width: 300px;
  top: 100px;
  left: 0px;
  right: 0px;
  background: #fff;
  border-radius: 10px;
  z-index: 1000;
  height: max-content;

  ${(props) =>
    props.hasAgents &&
    `
     border: 1px solid #ccc;
   `}

  ${(props) =>
    props.hideDropdown &&
    `
     display: none;
    `}
`;

const AgentItem = styled.div`
  display: flex;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  &:hover {
    background: #eee;
    transition-duration: 0.3s;
    cursor: pointer;
  }
  > div {
    &:nth-child(1) {
      width: 40px;
      height: 40px;
      border-radius: 20px;
      overflow: hidden;
      background: #eee;
      > img {
        width: 100%;
      }
    }
    &:nth-child(2) {
      margin-left: 20px;
      p,
      h6 {
        padding: 0px;
        margin: 0px;
      }
      h6 {
        font-weight: 400;
        color: #999;
        font-size: 12px;
      }
      > input {
        color: #999;
        border: 10px;
        font-size: 12px;
        pointer-events: none;
        padding: 0px;
        background: transparent;
      }
    }
  }
`;

const ConsumerAppTable = ({
  fetchWaitlistTable,
  fetchWaitlistTableResponse,
  selectedApp,
  saveWaitlistStatus,
  saveWaitlistStatusResponse,

  getAgentForRosterPage,
  agentYearEndWrapResponse,
  rosterPageAgentsResponse,
  agents,
  rosterFetchAgentSearch,
  isAgentListDropdownVisible,
  toggleAgentList,
  uploadW2FormResponse,
  joinTheWaitlistAPI,
  ...props
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [confirmDisabled, setConfirmDisabled] = useState(true);
  const [reason, setReason] = useState("");
  const [dataSource, setDataSource] = useState([]);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [searchText, setSearchText] = useState("");
  const [agentId, setAgentId] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedDates, setSelectedDates] = useState([]);
  const [selectedAppStatus, setSelectedAppStatus] = useState(null);
  const [openCustomiseApp, setOpenCustomiseApp] = useState(false);
  const [openAgentModal, setOpenAgentModal] = useState(false);
  const [isSearchActive, setSearchActive] = useState(false);
  const [churnedAgentCheck, setChurnedAgentCheck] = useState(false);

  const ORDER_TYPE = {
    DESC: "DESC",
    ASC: "ASC",
  };

  const SORT_TYPES = {
    BY_CREATED_AT: "createdAtSortOrder",
    BY_DEALS_COUNT: "dealsSortOrder",
    BY_NAME: "nameSortOrder",
    BY_REQUEST: "requestedProductSortOrder",
    BY_LAST_CLOSED_DEALS: "lastClosedDealSortOrder",
    BY_DEALS_PER_MONTH: "dealsPerMonthSortOrder",
    BY_TOTAL_PRICE: "totalPriceSortOrder",
    BY_TOTAL_GROSS_COMMISION: "totalGrossCommissionSortOrder",
    BY_ANNUAL_GROSS_COMMISION: "annualGrossCommissionSortOrder",
    BY_TOTAL_RADIUS_FEE: "totalRadiusFeesSortOrder",
    BY_TOTAL_INCONTRACT: "totalInContractsSortOrder",
    BY_AGENT_TYPE: "agentTypeSortOrder",
    BY_ENDED_AT: "churnedSortOrder",
  };

  const [isLicenseTransferred, setIsLicenseTransferred] = useState(null);
  const [isIcaSigned, setIsIcaSigned] = useState(null);
  const [primarySaleRepresentative, setPrimarySaleRepresentative] = useState(
    ""
  );
  const [
    secondarySaleRepresentative,
    setSecondarySaleRepresentative,
  ] = useState("");
  const [agentType, setAgentType] = useState("");
  const [city, setCity] = useState("");
  const [minPastBrokerageProduction, setMinPastBrokerageProduction] = useState(
    null
  );
  const [maxPastBrokerageProduction, setMaxPastBrokerageProduction] = useState(
    null
  );
  const [pastBrokerageName, setPastBrokerageName] = useState("");
  const [productionTier, setProductionTier] = useState("");
  const [minCap, setMinCap] = useState(null);
  const [maxCap, setMaxCap] = useState(null);
  const [teamId, setTeamId] = useState(null);

  const [tcAssigned, setTcAssigned] = useState(undefined);
  const [dealsDateRange, setDealsDateRange] = useState({
    from: undefined,
    to: undefined,
  });
  const [churnedDateRange, setChurnedDateRange] = useState({
    from: undefined,
    to: undefined,
  });

  const [planFilter, setPlanFilter] = useState("");
  const [dateRange, setDateRange] = useState({
    from: undefined,
    to: undefined,
  });

  const [sortBy, setSortBy] = useState(SORT_TYPES.BY_NAME);
  const [sortDir, setSortDir] = useState(ORDER_TYPE.ASC);
  const [assistPlan, setAssistPlan] = useState(undefined);
  const [state, setState] = useState(undefined);

  const [maxDeals, setMaxDeals] = useState(undefined);
  const [minDeals, setMinDeals] = useState(undefined);
  const [minDealsPerMonth, setMinDealsPerMonth] = useState(undefined);
  const [maxDealsPerMonth, setMaxDealsPerMonth] = useState(undefined);

  const [churnedColumnVisible, setChurnedColumnVisible] = useState(false);

  const [teamFilter, setTeamFilter] = useState();

  const [isRequestedCheckboxSelected, setCheckBox] = useState(false);

  const SIZE = 50;

  const handleSearchType = (value) => {
    setSearchText(value);
    rosterFetchAgentSearch({ search: value });
  };

  const { isFetching: isFetchingName, data: dataAgent } = agents;
  const agentsLists = get(dataAgent, "agents", []);

  useEffect(() => {
    fetchTableData("", [], "");
  }, [fetchWaitlistTable, pagination]);

  const fetchTableData = (search, date, status) => {
    const { current, pageSize } = pagination;
    const payload = {
      startDate: date[0] ? moment(date[0]).unix() : "",
      endDate: date[1] ? moment(date[1]).unix() : "",
      status: status,
      searchText: search,
      limit: pageSize,
      skip: (current - 1) * pageSize,
    };
    fetchWaitlistTable(payload);
  };

  useEffect(() => {
    if (fetchWaitlistTableResponse?.data) {
      setDataSource(fetchWaitlistTableResponse.data.data);
    }
  }, [fetchWaitlistTableResponse]);

  const handlePageChange = (page, pageSize) => {
    setPagination({ current: page, pageSize });
    fetchTableData(searchText, selectedDates, selectedStatus);
  };

  const showModal = (record) => {
    setSelectedRequest(record);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    const payload = {
      status: selectedAppStatus,
      reason: reason,
      id: selectedRequest.app_id,
    };
    saveWaitlistStatus({
      payload,
      callback: () => {
        fetchTableData("", [], "");
      },
    });
    setIsModalVisible(false);
    setReason("");
    setSelectedAppStatus("");
    setConfirmDisabled(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setReason("");
    setSelectedAppStatus("");
    setConfirmDisabled(true);
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    setReason(value);
    setConfirmDisabled(value.trim() === "");
  };

  const handleStatusSelect = (record, statusKey) => {
    setSelectedRequest(record);
    showModal(record);
    setSelectedAppStatus(statusKey);
  };

  const debouncedSearch = useCallback(
    debounce((value) => {
      setSearchText(value);
      fetchTableData(value, selectedDates, selectedStatus);
    }, 500),
    [fetchTableData]
  );

  const handleSearch = (e) => {
    debouncedSearch(e.target.value);
  };

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const handleFilterApply = () => {
    setIsFilterVisible(false);
    fetchTableData(searchText, selectedDates, selectedStatus);
  };

  const handleFilterReset = () => {
    setSelectedStatus("");
    setSelectedDates([]);
    setIsFilterVisible(false);
    fetchTableData("", [], "");
  };

  const statusMenu = (record) => (
    <Menu style={styles.dropdownMenu}>
      {Object.keys(statusMapping).map((statusKey) => (
        <Menu.Item
          key={statusKey}
          onClick={() => handleStatusSelect(record, statusKey)}
        >
          {statusMapping[statusKey]}
        </Menu.Item>
      ))}
    </Menu>
  );

  // const redirectToCustomise = (record) => {
  //   history.push("/broker/customise");
  //   selectedApp(record);
  // };

  const actionMenu = (record) => (
    <Menu style={styles.dropdownMenu}>
      <Menu.Item
        key="cancel"
        onClick={() => {
          showModal(record);
          setSelectedAppStatus("request_cancelled");
        }}
        style={{ color: "red" }}
      >
        Cancel request
      </Menu.Item>
    </Menu>
  );

  const columns = [
    {
      title: "Name",
      dataIndex: "agent_name",
      key: "agent_name",
    },
    {
      title: "Team Name",
      dataIndex: "team_name",
      key: "team_name",
    },
    {
      title: "Phone Number",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Requested Date/Time",
      dataIndex: "created_at",
      key: "requestedDateTime",
      render: (text, record) => {
        const daysAgo = record.days_passed
          ? `${record.days_passed} days ago`
          : "-";
        const formattedDate = moment(record.created_at).format("MM/DD/YYYY");
        return (
          <>
            <div>{daysAgo}</div>
            <div style={{ color: "gray" }}>{formattedDate}</div>
          </>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status, record) => {
        const statusText = statusMapping[status] || "-";
        const color = statusColors[status] || "default";
        return (
          <Dropdown overlay={statusMenu(record)} trigger={["click"]}>
            <Tag color={color} style={styles.tag}>
              {statusText}
              <DownOutlined style={{ marginLeft: 8 }} />
            </Tag>
          </Dropdown>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space>
          <Button
            style={styles.actionButton}
            onClick={() => {
              setOpenCustomiseApp(true);
              selectedApp(record);
            }}
          >
            Customize App <RightOutlined />
          </Button>
          <Dropdown overlay={actionMenu(record)} trigger={["click"]}>
            <Button icon={<EllipsisOutlined />} />
          </Dropdown>
        </Space>
      ),
    },
  ];

  const createPayload = (extra = {}, clearSkip = false) => {
    const payload = {
      limit: SIZE,
      ...extra,
    };
    const resultCount = get(rosterPageAgentsResponse, "data.result.length");
    if (resultCount && resultCount > 0) {
      payload.skip = resultCount;
    }
    if (searchText) {
      payload.agentEmail = searchText;
    }
    if (planFilter && !churnedAgentCheck) {
      payload.plan = planFilter;
    }
    if (dateRange.from) {
      payload.fromDate = moment(dateRange.from.setHours(0, 0, 0)).unix();
    }
    if (dateRange.to) {
      payload.toDate = moment(dateRange.to.setHours(0, 0, 0))
        .add("1", "day")
        .unix();
    }
    if (churnedDateRange.from) {
      payload.fromChurnedDate = moment(
        churnedDateRange.from.setHours(0, 0, 0)
      ).unix();
    }
    if (churnedDateRange.to) {
      payload.toChurnedDate = moment(churnedDateRange.to.setHours(0, 0, 0))
        .add("1", "day")
        .unix();
    }
    if (isRequestedCheckboxSelected) {
      payload.productRequest = isRequestedCheckboxSelected;
    }
    if (sortBy) {
      payload.sortBy = `${sortBy}=${sortDir}`;
    }
    if (minDealsPerMonth) {
      payload.minDealsPerMonth = minDealsPerMonth;
    }
    if (maxDealsPerMonth) {
      payload.maxDealsPerMonth = maxDealsPerMonth;
    }
    if (minDeals) {
      payload.minDeals = minDeals;
    }
    if (maxDeals) {
      payload.maxDeals = maxDeals;
    }
    if (state) {
      payload.state = state;
    }
    if (city) {
      payload.city = city;
    }
    if (teamFilter) {
      payload.teamId = teamFilter;
    }
    if (clearSkip) {
      payload.skip = 0;
    }
    if (churnedAgentCheck) {
      setChurnedColumnVisible(true);
      payload.churnedFilter = 1;
    }
    if (!churnedAgentCheck) {
      setChurnedColumnVisible(false);
      payload.churnedFilter = 0;
    }
    if (assistPlan) {
      payload.assistPlan = assistPlan;
    }
    if (tcAssigned) {
      payload.tc_id = tcAssigned;
    }
    if (dealsDateRange.from) {
      payload.dealsDateFrom = moment(
        dealsDateRange.from.setHours(0, 0, 0)
      ).unix();
    }
    if (dealsDateRange.to) {
      payload.dealsDateTo = moment(dealsDateRange.to.setHours(0, 0, 0))
        .add("1", "day")
        .unix();
    }
    if (isLicenseTransferred === 0 || isLicenseTransferred) {
      payload.isLicenseTransferred = isLicenseTransferred;
    }
    if (isIcaSigned === 0 || isIcaSigned) {
      payload.isIcaSigned = isIcaSigned;
    }
    if (pastBrokerageName) {
      payload.pastBrokerageName = pastBrokerageName;
    }
    if (productionTier) {
      payload.productionTier = productionTier;
    }
    if (primarySaleRepresentative) {
      payload.primarySaleRepresentative = primarySaleRepresentative;
    }
    if (secondarySaleRepresentative) {
      payload.secondarySaleRepresentative = secondarySaleRepresentative;
    }
    if (agentType) {
      payload.agentType = agentType;
    }
    if (minCap) {
      payload.minCap = minCap;
    }
    if (maxCap) {
      payload.maxCap = maxCap;
    }
    if (teamId) {
      payload.teamId = teamId;
    }
    if (minPastBrokerageProduction) {
      payload.minPastBrokerageProduction = minPastBrokerageProduction;
    }
    if (maxPastBrokerageProduction) {
      payload.maxPastBrokerageProduction = maxPastBrokerageProduction;
    }
    return payload;
  };

  const handleAgentModalCancel = () => {
    setOpenAgentModal(false);
    setSearchText("");
  };

  const handleFetchAgentCall = () => {
    setSearchText("");
    const payload = { app_type: "Individual", agentId: agentId };
    joinTheWaitlistAPI(payload);
    handleFilterReset();
    setOpenAgentModal(false);
  };

  const listRef = useRef(null);

  return (
    <>
      {!openCustomiseApp ? (
        <>
          <div style={{ padding: "30px" }}>
            <Tabs defaultActiveKey="1">
              <TabPane tab="App Requests" key="1">
                <div style={styles.tableContainer}>
                  <div style={styles.filterWrapper}>
                    <h1 style={styles.header}>Join Waitlist Requests</h1>
                    <div
                      style={{
                        marginBottom: 16,
                        width: "100%",
                        display: "flex",
                        justifyContent: "end",
                      }}
                    >
                      <Button
                        onClick={() => setOpenAgentModal(true)}
                        style={{ marginRight: 10, ...styles.modalButton }}
                      >
                        Add agent
                      </Button>

                      <Modal
                        bodyStyle={{ height: "300px", overflowY: "auto" }}
                        title="Add agent"
                        visible={openAgentModal}
                        onCancel={handleAgentModalCancel}
                        footer={
                          <Button
                            onClick={handleFetchAgentCall}
                            disabled={!searchText}
                          >
                            Add agent
                          </Button>
                        }
                      >
                        <WrapAgent>
                          <h4>Agent name or email</h4>
                          <Input
                            style={{ width: 300 }}
                            value={searchText}
                            placeholder="Search agent"
                            onChange={(e) => {
                              toggleAgentList(true);
                              handleSearchType(e.target.value);
                            }}
                          />
                          <div ref={listRef}>
                            <AgentList
                              hideDropdown={!isAgentListDropdownVisible}
                              hasAgents={agentsLists && agentsLists.length > 0}
                            >
                              {isFetchingName ? (
                                <Loader width="25px" top={0} bottom={0} />
                              ) : (
                                <React.Fragment>
                                  {searchText &&
                                    agentsLists &&
                                    agentsLists.length > 0 &&
                                    agentsLists.slice(0, 6).map((agent) => (
                                      <AgentItem
                                        key={agent.id}
                                        onClick={() => {
                                          setSearchText(
                                            agent.firstname +
                                              " " +
                                              agent.lastname
                                          );
                                          setAgentId(agent.id);
                                          getAgentForRosterPage({
                                            ...createPayload(),
                                            agentEmail: agent.email,
                                            skip: 0,
                                            limit: 1,
                                            churnedFilter:
                                              churnedAgentCheck == true ? 1 : 0,
                                          });
                                          toggleAgentList(false);
                                          setSearchActive(true);
                                        }}
                                      >
                                        <div style={{ marginTop: "10px" }}>
                                          <img src={agent.agent_image} alt="" />
                                        </div>
                                        <div>
                                          <p>
                                            {`${agent.firstname} ${agent.lastname}`}
                                          </p>
                                          <h6>{agent.email}</h6>
                                          <NumberFormat
                                            format="+1 (###) ###-####"
                                            mask="_"
                                            value={agent.phone}
                                          />
                                        </div>
                                      </AgentItem>
                                    ))}
                                </React.Fragment>
                              )}
                            </AgentList>
                          </div>
                        </WrapAgent>
                      </Modal>

                      <Input
                        placeholder="Team or Agent name"
                        // prefix={<SearchOutlined />}
                        onChange={(e) => handleSearch(e)}
                        style={styles.inputSearch}
                      />
                      <Badge
                        dot={selectedDates?.length > 0 || selectedStatus}
                        offset={[0, 0]}
                      >
                        <Button
                          style={styles.filterButton}
                          onClick={() => setIsFilterVisible(true)}
                        >
                          Filter
                        </Button>
                      </Badge>
                    </div>
                  </div>
                  <Table
                    columns={columns}
                    dataSource={dataSource}
                    loading={fetchWaitlistTableResponse?.isFetching}
                    pagination={{
                      current: pagination.current,
                      pageSize: pagination.pageSize,
                      onChange: handlePageChange,
                      total: fetchWaitlistTableResponse?.data?.total_count || 0,
                    }}
                  />
                </div>
              </TabPane>
              <TabPane tab="Consumer" key="2">
                <Consumers />
              </TabPane>
              <TabPane tab="Search boards" key="3">
                <SearchBoards />
              </TabPane>
            </Tabs>
          </div>
          <Modal
            visible={isModalVisible}
            onOk={handleOk}
            okText="Confirm"
            onCancel={handleCancel}
            okButtonProps={{ disabled: confirmDisabled }}
          >
            <div>
              <h3 style={styles.modalTitle}>
                Change App status to {statusMapping[selectedAppStatus]}
              </h3>
              <Input.TextArea
                placeholder="Enter reason for status change"
                value={reason}
                onChange={handleInputChange}
              />
            </div>
          </Modal>

          {/* Filter Modal */}
          <Modal
            title="Filter"
            visible={isFilterVisible}
            onCancel={() => setIsFilterVisible(false)}
            footer={null}
          >
            <div>
              <div style={{ marginBottom: 16 }}>
                <span style={{ marginRight: 8 }}>Status:</span>
                <Dropdown
                  overlay={
                    <Menu onClick={({ key }) => setSelectedStatus(key)}>
                      {Object.keys(statusMapping).map((statusKey) => (
                        <Menu.Item key={statusKey}>
                          {statusMapping[statusKey]}
                        </Menu.Item>
                      ))}
                    </Menu>
                  }
                >
                  <Button>
                    {statusMapping[selectedStatus] || "Select Status"}{" "}
                    <DownOutlined />
                  </Button>
                </Dropdown>
              </div>
              <div>
                <span style={{ marginRight: 8, marginBottom: 10 }}>
                  Date Range:
                </span>
                <RangePicker
                  value={selectedDates}
                  onChange={(dates) => setSelectedDates(dates)}
                />
              </div>
              <div style={{ marginTop: 16, textAlign: "right" }}>
                <Button style={{ marginRight: 8 }} onClick={handleFilterReset}>
                  Reset
                </Button>
                <Button type="primary" onClick={handleFilterApply}>
                  Apply
                </Button>
              </div>
            </div>
          </Modal>
        </>
      ) : (
        <AppCustomisation
          setOpenCustomiseApp={setOpenCustomiseApp}
          statusMapping={statusMapping}
        />
      )}
    </>
  );
};

export default globalPagesContainer(
  agentSearchContainer(ConsumerApp(ConsumerAppTable))
);
