/* eslint-disable max-len */
import React from "react"
import styled from "styled-components"
import Select from "react-select"
import cookie from "react-cookies"
import {
  get,
  isObject,
  uniqBy,
  isEqual,
  isArray,
  isEmpty,
} from "lodash"
import moment from "moment"
import { ToastContainer, toast } from "react-toastify"
import { Link, withRouter } from "react-router-dom"
import {
  addCommas,
  USCurrencyFormat,
  deformatCurrency,
} from "shared/currencyUtils"
import { List, Skeleton, Button as AntdButton, Radio, InputNumber } from "antd"
import Loader from "dumbComponents/common/UI/Loader"
import container from "container/BrokerDashboard/globalPagesContainer"
import Button from "dumbComponents/common/UI/Button"
import AgentSearch from "dumbComponents/BrokerDashboard/Components/AgentSearch"
import { setTimeout } from "window-or-global"
import ListValues from "./List.json"
import TextInput from "../../Comp/TextInput"
import GoogleLocation from "../../../../components/GoogleAutoComplete"
import ClientSelector from "./ClientSelector"
import ClientSearch from "./ClientSearch"
import {
  TRANSACTION_TYPE,
  COMMISSION_TYPE,
  US_OBJ,
  FILE_TYPES,
  FILE_TYPES_LISTING,
  RADIUS_TRANSACTION_TYPE,
} from "../FieldData"
import { getSource } from "../Util"
import EscrowDrawer from "./EscrowDrawer"
import Tick from "../Images/Tick.svg"
import { capitalizeFirstLetter } from "../../../../services/Utils"

const Wrap = styled.div`
  margin: 20px auto;
  max-width: 1000px;
  padding: 20px 20px 200px 20px;
`

const Row = styled.div`
  display: flex;
  margin-top: 30px;
  flex-wrap: wrap;
  gap: 14px;

  ${props => props.noMargin && `
    margin-top: 0px;
  `}

  ${props => props.isEscrow && `
    flex-direction: column;
  `}
`

const Col = styled.div`
  flex: 1;
  margin-right: 20px;
  margin-bottom: 0px;
  &:last-child {
    margin-right: 0px;
  }
`

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  > div {
    &:nth-child(1) {
      flex: 1;
    }
    &:nth-child(2) {
    }
  }
`

const Label = styled.div`
  color: #A3A3A3;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 14px;
  width: 100%;
  margin-bottom: 10px;
  height: 16px;
  ${props => props.error && `
    font-size: 11px;
    color: red;
    margin-top: 7px;
    letter-spacing: 1px;
    width: 110%;
  `}
  ${props => props.skyslope && `
    margin-bottom: 3px;
  
  `}
`
const VendorLabel = styled.div`
  font-size: 12px;
  letter-spacing: 2px;
  color: black;
`

const SectionWrap = styled.div`
  padding: 40px;
  border-radius: 20px;
  border: 1px solid #ccc;
  position: relative;
  margin-bottom: 40px;

  @keyframes breath-animation {
    0% { background: transparent; border: 1px solid #ccc; }
    50% { background: #ffcecb; border: 1px solid #fd6459; }
  }

  ${props => props.isAnimate && `
    animation-name: breath-animation;
    animation-duration: 1.5s;
    animation-iteration-count: 3;
  `}

  > h6 {
    top: -16px;
    left: 40px;
    position: absolute;
    background: #fff;
    padding: 5px 16px;
    margin: 0px;
    font-size: 16px;
    font-weight: 700;
    color: #999;
  }

`
const TCDetails = styled.div`
  display: flex;
  margin-top: 8px;
`

const UploadWrap = styled.div`

`
const EscrowWrap = styled.div`
  display: flex;
  flex-direction: column;
`
const TitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
`
const Title = styled.div`
  font-size: 18px;
`
const InfoWrap = styled.div`
  display: flex;
  flex-direction: column;
`
const CalculateButton = styled(Button)`
  height: 42px;
  align-self: flex-end;
`

const SelectedButton = styled(AntdButton)`
  width: 100px;
`

const SelectedAgentView = styled.div`

`

class AddNewDeal extends React.PureComponent {
  state = {
    files: [],
    form: {
      // RAW
      active_listing_status: null,
      agent_commission: null,
      agent_email: "",
      agent_id: null,
      city: "",
      client_email: "",
      client_name: null,
      client_type: null,
      clients: [],
      closed_on: null,
      confirmed_closed_on: null,
      co_listing_agents: null,
      commision_percentage: null,
      closing_agent_commision_percentage: null,
      commission_type: null,
      contract_on: null,
      created_at: moment(new Date()).unix(),
      date_of_listing_submission: moment().format("YYYY-MM-DD HH:mm:ss"),
      escrow_officer_email: null,
      escrow_officer_phone: null,
      tc_name: null,
      tc_email: null,
      tc_phone: null,
      file_type: null,
      fixed_fee: null,
      gross_commission: null,
      hoa_guidelines: null,
      is_all_cash_offer: null,
      //is_co_listed_deal: null,
      is_radius_lead: null,
      is_radius_tc_to_open_escrow: null,
      lease_commencement_on: null,
      lender_email_address: null,
      rent_amount_term: null,
      rent_amount: null,
      other_rent_amount_term: null,
      list_price: null,
      listing_address: null,
      price: null,
      radius_transaction_fee: null,
      row_created_at: moment().format("YYYY-MM-DD HH:mm:ss"),
      //row_updated_at: null,
      schedule_sign_post_installation: null,
      radius_transaction_type: "",
      sign_post_installation_location: null,
      sign_post_installation_on: null,
      sign_post_installation_riders: null,
      source: null,
      state: null,
      team_split_percentage: null,
      transaction_type: null,
      zipcode: null,
      skyslope_unique_id: "",
      listing_date: null,
      listing_expiration_date: null,
      is_using_deal_tc: null,
      is_coupon_applied: 0,
      brokerage_fee: null,
      agent_payout_fee: null,
      tc_fee: null,
      audit_fee: null,
      pending_subscription_fee: null,
      e_and_o_fee: null,
      referral_fee: null,
    },
    errorFields: {},
    isSelected: null,
    isLoading: false,
  }
  
  componentDidMount() {
    const {
      match,
      location,
      getSecureIdForTC,
      getAllDealsListing,
      getVendorDetails,
      getVendorDetailsResponse,
      setAllVendors,
      selectedId,
      isCreatingDeal,
    } = this.props
    const { form } = this.state
    const { dealId, agentId } = match.params
    let dealIdFinal = dealId
    if (dealId && dealId.includes("?")) {
      dealIdFinal = dealId.split("?")
      dealIdFinal  = dealIdFinal[0]
    }
    //eher
    if (!isEmpty(location?.state)) {
      const { deal: finalDeal } = location && location.state || {}
      const deal = finalDeal[0] || finalDeal || null
      if (dealId && deal) {
        getAllDealsListing({
          type: "agent",
          type_deal: "deal",
          dealId: dealIdFinal,
          agentId,
          transactionType: "Contract",
        })
        getSecureIdForTC({ agentId })
        getVendorDetails({ agentId, dealId: dealIdFinal })

        this.setState({
          currentCoListedAgentName: deal.co_listing_agents
            && deal.co_listing_agents.length > 0
            && `${deal.co_listing_agents[0].firstname} ${deal.co_listing_agents[0].lastname || ""}` || "",
          currentCoListedAgentEmail: deal.co_listing_agents
            && deal.co_listing_agents.length > 0
            && deal.co_listing_agents[0].email || "",
          form: {
            ...form,
            ...deal,
            schedule_sign_post_installation: {
              label: deal.schedule_sign_post_installation && deal.schedule_sign_post_installation === 0 ? "No" : "Yes",
              value: deal.schedule_sign_post_installation,
            },
            client_type: {
              label: this.getLabel(deal.client_type && deal.client_type.replaceAll("_", " ")),
              value: deal.client_type,
            },
            source: {
              label: getSource(deal.source),
              value: deal.source,
            },
            transaction_type: {
              label: this.getLabelFromObj(deal.transaction_type, TRANSACTION_TYPE),
              value: deal.transaction_type,
            },
            file_type: {
              label: this.getLabel(deal.file_type && deal.file_type.replaceAll("_", " ")),
              value: deal.file_type,
            },
            is_radius_lead: {
              label: deal.is_radius_lead && deal.is_radius_lead === 0 ? "No" : "Yes",
              value: deal.is_radius_lead,
            },
            radius_transaction_type: {
              label: deal.radius_transaction_type && deal.radius_transaction_type,
              value: deal.radius_transaction_type,
            },
            commission_type: {
              label: this.getLabel(deal.commission_type && deal.commission_type.replaceAll("_", " ")),
              value: deal.commission_type,
            },
            active_listing_status: {
              label: this.getLabel(deal.active_listing_status && deal.active_listing_status.replaceAll("_", " ")),
              value: deal.active_listing_status,
            },
            is_all_cash_offer: {
              label: deal.is_all_cash_offer === 0 ? "No" : "Yes",
              value: deal.is_all_cash_offer,
            },
            is_using_deal_tc: {
              label: deal.is_using_deal_tc === 1 ? "Yes" : "No",
              value: deal.is_using_deal_tc,
            },
            is_radius_tc_to_open_escrow: {
              label: deal.is_radius_tc_to_open_escrow !== null && deal.is_radius_tc_to_open_escrow === 1 ? "Yes" : "No",
              value: deal.is_radius_tc_to_open_escrow !== null && deal.is_radius_tc_to_open_escrow === 1 ? 1 : 0,
            },
            rent_amount_term: {
              label: this.getLabel(deal.rent_amount_term && deal.rent_amount_term.replaceAll("_", " ")),
              value: deal.rent_amount_term,
            },
            clients: this.depricateClient(deal),
          },
          isEdit: !!deal,
        })
      }
    }
    
    //here
    if (location.search && location.search.includes("checkPricing=true")) {
      setTimeout(() => {
        this.setState({
          animatePricing: true,
        })
      }, 500)
      setTimeout(() => {
        const $ele = document.getElementById("tc-pricing-section")
        if ($ele) {
          $ele.scrollIntoView(false)
        }
      }, 1000)
    }
  }

  getLabel = (value) => {
    if (value === "created_at") {
      return "Acceptance Date"
    }
    return value && value.replace(value[0], value[0]
      .toUpperCase())
      .replaceAll("_", " ")
  }

  getLabelFromObj = (value, list) => {
    const selectedItem = list.filter(x => x.value === value)
    return selectedItem.length > 0 ? selectedItem[0].label : value
  }

  depricateClient = (deal = {}) => {
    const newClient = deal.clients || []
    if (
      deal.client_email
      && deal.client_name
    ) {
      return [{
        email: deal.client_email,
        name: deal.client_name,
        client_id: null,
        phone: null,
        is_primary: null,
      },
      ...newClient,
      ]
    }
    return deal.clients || []
  }

  handleChange = (event, formateFn) => {
    const { form } = this.state
    const { name, value } = event.target
    const amountToRoundOff = ["price", "list_price", "rent_amount", "fixed_fee", "gross_commission", "agent_commission"];
    if (amountToRoundOff.includes(name)){
      const check_value = value.toString().replace(/(\.\d{2}).*/, '$1')
      this.setState({
        form: {
          ...form,
          [name]: formateFn ? formateFn(check_value) : check_value,
        },
      })
    } else{
      this.setState({
        form: {
          ...form,
          [name]: formateFn ? formateFn(value) : value,
        },
      })
    }
      
    
  }

  handleChangeNumber = (value, name) => {
    const { form } = this.state
    const check_value = Math.round(value * 100) / 100;
    this.setState({
      form: {
        ...form,
        [name]: check_value || null,
      },
    },
    () => {
      const { form: newFormState } = this.state
      
      const radiusTransactionFee = parseFloat(([
        newFormState.brokerage_fee,
        // newFormState.agent_payout_fee,
        newFormState.tc_fee,
        newFormState.audit_fee,
        newFormState.pending_subscription_fee,
        newFormState.e_and_o_fee,
        newFormState.referral_fee
      ].reduce((acc, curr) => acc + (curr ? Number(curr) : Number(0)), 0)).toFixed(2));
      this.setState({
        form: {
          ...newFormState,
          radius_transaction_fee: radiusTransactionFee
        },
      })
      
    })
  }

  formatter = (value) => {
    const check_value = Number(value)
    if (value) {
      return `${parseFloat(check_value).toFixed(2)}`;
    }
    return 
  };

  boolCheck = (field) => {
    if (isObject(field)) {
      return field.value
    }
    return field
  }

  createDeals = () => {
    const {
      createDeals,
      history,
      match,
      createVendorDetails,
      allVendors,
      updateVendorDetails,
      selectVendorDetails,
      selectedId,
      setIsCreatingDeal,
      isCreatingDeal,
    } = this.props
    const { form, selectedAgent, isEdit, isSelected } = this.state
    setIsCreatingDeal(true)
    const dealId = get(match, "params.dealId")
    if (!(selectedAgent || get(match, "params.agentId"))) {
      this.setState({
        isAgentNotSelected: true,
      })
      setIsCreatingDeal(false)
      return
    }
    const agentId = get(match, "params.agentId")

    const formkeys = Object.keys(form)
    const ignoredFields = [
      "client_email",
      "client_name",
      "agent_commission",
      "gross_commission",
      "is_radius_lead",
      "contract_on",
      "co_listing_agents",
      "lender_email_address",
      "schedule_sign_post_installation",
      "hoa_guidelines",
      "agent_id",
      "other_source",
      "other_transaction_type",
      "rent_amount_term",
      "rent_amount",
      "other_rent_amount_term",
      "is_using_radius_tc",
      "is_using_deal_tc",
      "is_coupon_applied",
      "is_radius_tc_to_open_escrow",
      "agent_type",
      "confirmed_closed_on",
      "agent_phone",
      "agent_email",
      "agent_lastname",
      "active_listing_status",
      "commission_demand_url",
      "tc_calendly_links",
      "tc_email",
      "tc_firstname",
      "tc_lastname",
      "tc_phone",
      "tc_profile_image",
      "assigned_tc_id",
      "escrow_officer_email",
      "escrow_officer_phone",
      "active_state_served",
      "radius_transaction_type",
      "deal_status",
      "skyslope_unique_id",
    ]

    const replaceFieldErrorField = {
      closed_on: "est._closing_on",
    }

    const user = cookie.load("user")

    const removeListForComplexDataTypes = [
      "completed_steps",
    ]

    if (dealId) {
      const completedStepIndex = formkeys.indexOf("completed_steps")
      formkeys.splice(completedStepIndex, 1)
    }

    const fileType = get(form, "file_type.value")

    if (fileType === "new_listing") {
      ignoredFields.push("price")
      ignoredFields.push("created_at")
      ignoredFields.push("lease_commencement_on")
      ignoredFields.push("escrow_officer_email")
      ignoredFields.push("closed_on")
      ignoredFields.push("row_created_at")
      ignoredFields.push("is_radius_tc_to_open_escrow")
      ignoredFields.push("vendor_id")
    } else {
      // other than new listing
      ignoredFields.push("sign_post_installation_on")
      ignoredFields.push("sign_post_installation_riders")
      ignoredFields.push("sign_post_installation_location")
      ignoredFields.push("listing_date")
      ignoredFields.push("listing_expiration_date")
      ignoredFields.push("date_of_listing_submission")
    }

    if (form.is_using_deal_tc !== null && form.is_using_deal_tc.value === 1) {
      ignoredFields.push("tc_name")
      ignoredFields.push("tc_email")
      ignoredFields.push("tc_phone")
    }

    if (fileType === "pending_contract"
      || fileType === "lease_listing"
      || fileType === "pending_lease"
      || fileType === "external_referral_agreement"
    ) {
      ignoredFields.push("list_price")
    }

    if (form.is_all_cash_offer === 1) {
      ignoredFields.push("lender_email_address")
    }

    if (form.schedule_sign_post_installation && form.schedule_sign_post_installation.value === 0) {
      ignoredFields.push("sign_post_installation_on")
      ignoredFields.push("sign_post_installation_riders")
      ignoredFields.push("sign_post_installation_location")
    }

    if (fileType === "lease_listing" || fileType === "pending_lease") {
      ignoredFields.push("price")
    }

    if (fileType === "new_listing"
      || fileType === "listing_with_accepted_offer"
      || fileType === "pending_lease"
      || fileType === "lease_listing"
    ) {
      ignoredFields.push("escrow_officer_email")
      ignoredFields.push("escrow_officer_phone")
      ignoredFields.push("is_radius_tc_to_open_escrow")
      ignoredFields.push("lease_commencement_on")
    }

    if (fileType === "new_listing"
      || fileType === "listing_with_accepted_offer"
      || fileType === "dual"
      || fileType === "external_referral_agreement"
      || fileType === "pending_contract"
    ) {
      ignoredFields.push("lease_commencement_on")
    }

    if (fileType === "") {
      ignoredFields.push("escrow_officer_phone")
    }

    if ((form.file_type
      && form.file_type.value !== "external_referral_agreement")
      || (form.commission_type && form.commission_type.value === "fixed_fee")
    ) {
      ignoredFields.push("closing_agent_commision_percentage")
    }

    // if (form.commission_type && form.commission_type.value === "fixed_fee") {
    //   ignoredFields.push("closing_agent_commision_percentage")
    // }

    if ((form.is_radius_tc_to_open_escrow && form.is_radius_tc_to_open_escrow.value === 0) || (form.is_using_deal_tc !== null && form.is_using_deal_tc.value === 0)) {
      ignoredFields.push("escrow_officer_phone")
      ignoredFields.push("escrow_officer_email")
      ignoredFields.push("is_radius_tc_to_open_escrow")
      ignoredFields.push("vendor_id")
    }

    if (form.commission_type && form.commission_type.value === "fixed_fee") {
      ignoredFields.push("commision_percentage")
    }

    if (form.commission_type && form.commission_type.value === "percentage") {
      ignoredFields.push("fixed_fee")
    }

    // Check for Clients data
    if (!form.clients || (form.clients && form.clients.length === 0)) {
      setIsCreatingDeal(false)
      toast.error(
        "Please fill missing Client Details", {
          position: "top-center",
          hideProgressBar: true,
        }
      )
      return
    }

    if (form.co_listing_agents && form.co_listing_agents.length > 0) {
      const agentType = isObject(form.client_type) ? form.client_type.value : form.client_type
      for (let i = 0; i < form.co_listing_agents.length; i++) {
        form.co_listing_agents[i].agent_type = agentType
      }
    }

    if (form.clients && form.clients.length > 0) {
      let isClientError = false
      for (const client of form.clients) {
        if (!client.name || !client.email || !client.phone || !form.source) {
          isClientError = true
        }
      }
      if (isClientError) {
        setIsCreatingDeal(false)
        toast.error(
          "Please fill missing Client Details", {
            position: "top-center",
            hideProgressBar: true,
          }
        )
        return
      }
      for (let i = 0; i < form.clients.length; i++) {
        // form.clients[i].is_primary = (i === 0) ? "Y" : "N"
        form.clients[i].is_primary = (i === 0) ? 1 : 0
        form.clients[i].source = form.source.value
        // if (i === 0) {
        //   form.clients[0].is_primary = "Y"
        // } else {
        //   form.clients[i].is_primary = "N"
        // }
        if (!(form.clients[i].email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/))) {
          form.clients[i].email = "na"
        }
      }
      if (isClientError) {
        setIsCreatingDeal(false)
        toast.error(
          "Please fill missing Client Details", {
            position: "top-center",
            hideProgressBar: true,
          }
        )
        return
      }
    }
    const errorKeys = formkeys.filter((key) => {
      // Only check for keys which are Not ignored
      if (ignoredFields.indexOf(key) === -1) {
        if (isArray(form[key]) && form[key].length === 0) {
          return true
        } if (form[key] === "") {
          return true
        } if (form[key] === null) {
          return true
        }
        return false
      }
    })

    if (form.closed_on === null) {
      setIsCreatingDeal(false)
      toast.error(
        "Please fill the Estimated Closing Date", {
          position: "top-center",
          hideProgressBar: true,
        }
      )
      return
    }
    const payload = {
      ...form,
      client_email: "",
      client_name: "",
      commision_percentage: Number(form.commision_percentage),
      closing_agent_commision_percentage: Number(form.closing_agent_commision_percentage),
      gross_commission: Number(form.gross_commission),
      agent_commission: Number(form.agent_commission),
      rent_amount: Number(form.rent_amount),
      radius_transaction_fee: Number(form.radius_transaction_fee),
      brokerage_fee: Number(form.brokerage_fee),
      agent_payout_fee: Number(form.agent_payout_fee),
      tc_fee: Number(form.tc_fee),
      audit_fee: Number(form.audit_fee),
      pending_subscription_fee: Number(form.pending_subscription_fee),
      e_and_o_fee: Number(form.e_and_o_fee),
      referral_fee: Number(form.referral_fee),
      price: form.price && Number(form.price) || null,
      agent_id: (selectedAgent && selectedAgent.id) || Number(get(match, "params.agentId")),
      client_type: isObject(form.client_type) ? form.client_type.value : form.client_type,
      source: isObject(form.source) ? form.source.value : form.source,
      list_price: Number(form.list_price),
      file_type: isObject(form.file_type) ? form.file_type.value : form.file_type,
      is_all_cash_offer: isObject(form.is_all_cash_offer) ? form.is_all_cash_offer.value : form.is_all_cash_offer,
      is_using_deal_tc: isObject(form.is_using_deal_tc) ? form.is_using_deal_tc.value : form.is_using_deal_tc,
      transaction_type: isObject(form.transaction_type) ? form.transaction_type.value : form.transaction_type,
      // zipcode: Math.floor(form.zipcode),
      zipcode: form.zipcode,
      commission_type: isObject(form.commission_type) ? form.commission_type.value : form.commission_type,
      team_split_percentage: Number(form.team_split_percentage),
      is_radius_lead: isObject(form.is_radius_lead) ? form.is_radius_lead.value : form.is_radius_lead,
      fixed_fee: Number(form.fixed_fee),
      active_listing_status: isObject(form.active_listing_status) ? form.active_listing_status.value : form.active_listing_status || "active_listing",
      is_radius_tc_to_open_escrow: isObject(form.is_radius_tc_to_open_escrow) ? form.is_radius_tc_to_open_escrow.value : form.is_radius_tc_to_open_escrow,
      schedule_sign_post_installation: isObject(form.schedule_sign_post_installation) ? form.schedule_sign_post_installation.value : form.schedule_sign_post_installation,
      rent_amount_term: isObject(form.rent_amount_term) ? form.rent_amount_term.value : form.rent_amount_term,
      confirmed_closed_on: form.confirmed_closed_on ? form.confirmed_closed_on : (form.closed_on ? form.closed_on : null),
      admin_name: user.twilio_client_name,
      vendor_id: selectedId,
    }
    if (form.radius_transaction_type.value === null) {
      delete payload.radius_transaction_type
    } else {
      payload.radius_transaction_type = form.radius_transaction_type.value
    }
    if (!isEdit && form.file_type !== null && form.file_type.value === "new_listing") {
      payload.created_at = ""
      delete payload.row_created_at
      if (form.date_of_listing_submission === null) {
        payload.date_of_listing_submission = moment().format("YYYY-MM-DD HH:mm:ss")
      } else {
        payload.date_of_listing_submission = form.date_of_listing_submission
      }
    } 
    else if (isEdit && form.file_type !== null && form.file_type.value !== "new_listing") {
      if (form.row_created_at === null) {
        payload.row_created_at = moment().format("YYYY-MM-DD HH:mm:ss")
      } else {
        payload.row_created_at = form.row_created_at
      }
      if (form.created_at === 0 || form.created_at === null) {
        payload.created_at = moment(new Date()).unix()
      } else {
        payload.created_at = form.created_at
      }
    }
    else if (!isEdit && form.file_type !== null && form.file_type.value !== "new_listing") {
      delete payload.date_of_listing_submission
      if (form.row_created_at === null) {
        payload.row_created_at = moment().format("YYYY-MM-DD HH:mm:ss")
      } else {
        payload.row_created_at = form.row_created_at
      }
      if (form.created_at === 0 || form.created_at === null) {
        payload.created_at = moment(new Date()).unix()
      } else {
        payload.created_at = form.created_at
      }
    }

    if ((form.is_radius_tc_to_open_escrow && form.is_radius_tc_to_open_escrow.value === 1) && (fileType !== "new_listing"
    || fileType !== "listing_with_accepted_offer"
    || fileType !== "pending_lease"
    || fileType !== "lease_listing")) {
      if (isEmpty(allVendors)) {
        setIsCreatingDeal(false)
        toast.error(
          "Please fill Escrow Details", {
            position: "top-center",
            hideProgressBar: true,
          }
        )
        return
      }
      const mappedVendors = allVendors.map((vendors) => {
        if (vendors.id) {
          updateVendorDetails({
            vendors,
            agentId: payload.agent_id,
            vendorId: vendors.id,
          })
        }})
    }

    if (form.is_using_deal_tc !== null && form.is_using_deal_tc.value !== 0) {
      if (form.is_radius_tc_to_open_escrow !== null && form.is_radius_tc_to_open_escrow.value !== 0) {
        if (selectedId === null && isSelected === null) {
          setIsCreatingDeal(false)
          toast.error(
            "Please select a Vendor", {
              position: "top-center",
              hideProgressBar: true,
            }
          )
          return
        }
      }
    }

    if (isEdit && fileType === "new_listing" && form.client_type.value !== "seller") {
      setIsCreatingDeal(false)
      toast.error(
        "Please change the Client Type to Seller", {
          position: "top-center",
          hideProgressBar: true,
        }
      )
      return
    }
    if (dealId && selectedId) {
      selectVendorDetails({
        payload: {
          current_listing_step: "select_vendor",
          vendor_id: selectedId,
        },
        dealId,
        agentId: payload.agent_id,
      })
    }

    if (form.file_type && form.file_type.value === "pending_contract"
    || form.file_type && form.file_type.value === "pending_lease"
    || form.file_type && form.client_type && (form.file_type.value === "external_referral_agreement" && (form.client_type.value !== "landlord"))) {
      payload.list_price = ""
    }

    if (form.file_type && form.file_type.value === "listing_with_accepted_offer"
    || form.file_type && form.file_type.value === "pending_contract"
    || form.file_type && form.file_type.value === "dual") {
      payload.rent_amount = ""
    }

    if (form.file_type && form.file_type.value === "lease_listing"
    || form.file_type && form.file_type.value === "pending_lease"
    || form.file_type && form.client_type && (form.file_type.value === "external_referral_agreement" && (form.client_type.value === "landlord" || form.client_type.value === "tenant"))) {
      payload.price = ""
    }

    if (form.source?.label !== "Others") {
      payload.other_source = null
    }
    createDeals({
      payload,
      history,
      isEdit: dealId,
      callbackVendors: (deal_id) => {
        setIsCreatingDeal(false)
        history.push("/broker/tc/list")
        allVendors.map((vendors) => {
          if (!(vendors.id)) {
            vendors.agent_id = payload.agent_id
            createVendorDetails({
              agentId: payload.agent_id,
              dealId: deal_id,
              vendors,
            })
          }
        })
      },
    })
  }

  selectAgent = (agent) => {
    const { getSecureIdForTC, getVendorDetails } = this.props
    getSecureIdForTC({
      agentId: agent.id,
    })
    getVendorDetails({
      agentId: agent.id,
      dealId: 0,
    })
    this.setState({
      selectedAgent: agent,
    })
  }

  selectCoListingAgent = (agent) => {
    const {
      form,
    } = this.state
    this.setState({
      currentCoListedAgentName: `${agent.firstname} ${agent.lastname}`,
      currentCoListedAgentEmail: agent.primary_email,
      form: {
        ...form,
        co_listing_agents: [{
          email: agent.email,
          phone: agent.phone,
          agent_id: agent.id,
          lastname: agent.lastname,
          firstname: agent.firstname,
        }],
        is_co_listed_deal: agent ? 1 : 0,
      },
    })
  }

  addClient = (data = {}) => {
    const { form } = this.state
    if (form.clients && typeof form.clients === "object") {
      form.clients.push({
        name: "",
        email: "",
        phone: "",
        client_id: null,
        referral_id: null,
        is_primary: null,
        ...data,
      })
      this.setState({
        ...form,
      })
      this.forceUpdate()
    }
  }

  removeClient = (index) => {
    const { form } = this.state
    form.clients = form.clients.filter((x, i) => i !== index)
    this.setState({
      ...form,
    })
    this.forceUpdate()
  }

  handleFileUploader = (file, id) => {
    const {
      uploadTCFiles,
    } = this.props

    const {
      files,
    } = this.state
    const fileArr = files && files.length > 0 ? uniqBy([...files], "name") : []
    Object.values(file).forEach((item) => {
      fileArr.push(item)
    })

    this.setState({
      files: [...fileArr],
    })

    fileArr.forEach((fi) => {
      uploadTCFiles({
        file: fi,
        id,
      })
    })
  }

  updateRowCreatedAt = (form) => {
    if (form.row_created_at === null) {
      form.row_created_at = moment().format("YYYY-MM-DD HH:mm:ss")
    }
    return moment(form.row_created_at).format("YYYY-MM-DD")
  }

  updateDateOfListingSub = (form) => {
    if (form.date_of_listing_submission === null) {
      form.date_of_listing_submission = moment().format("YYYY-MM-DD HH:mm:ss")
    }
    return moment(form.date_of_listing_submission).format("YYYY-MM-DD")
  }

  handleDecimalPrice = (data) => {
    if (!data) {
      return ""
    }
    const stringData = String(data)
    if (stringData && stringData.includes(".")) {
      return `$${addCommas(stringData)}`
    }
    return USCurrencyFormat(stringData)
  }

  formatTitle = (data) => {
    if (data) {
      if (data.includes("_")) {
        const temp1 = data.replaceAll("_", " ")
        const temp2 = temp1.split(" ")
        for (let i = 0; i < temp2.length; i++) {
          temp2[i] = temp2[i].charAt(0).toUpperCase() + temp2[i].slice(1)
        }
        const final = temp2.join(" ")
        return final
      }
      return capitalizeFirstLetter(data)
    }
  }

  addRadiusFee = () => {
    const { form } = this.state
    const {
      brokerage_fee,
      agent_payout_fee,
      tc_fee,
      audit_fee,
      pending_subscription_fee,
      e_and_o_fee,
      referral_fee,
    } = form
    const total = `${Number(brokerage_fee) - Number(agent_payout_fee) + Number(tc_fee) + Number(audit_fee) + Number(pending_subscription_fee) + Number(e_and_o_fee) + Number(referral_fee)}`
    this.setState({
      form: {
        ...form,
        radius_transaction_fee: total,
      },
    })
  }

  render() {
    const {
      form,
      errorFields,
      selectedAgent,
      isAgentNotSelected,
      currentCoListedAgentName,
      currentCoListedAgentEmail,
      animatePricing,
      isEdit,
      isSelected,
      isLoading,
    } = this.state
    const {
      match,
      isShowAgentSearch,
      searchClientByText,
      secureIdForTC,
      preferences,
      dealsListingResponse,
      toggleEscrowDrawer,
      getVendorDetailsResponse,
      setEscrowEdit,
      isEditEscrow,
      setAllVendors,
      allVendors,
      selectedId,
      setSelectedId,
      isCreatingDeal,
      setIsCreatingDeal,
    } = this.props
    const dealID = get(match, "params.dealId")
    const agentId = get(match, "params.agentId")
    const isRadiusTC = get(preferences, "data.is_using_radius_transaction_coordination")
    const nowTime = (new Date().getTime() / 1000)
    const { data, isFetching } = dealsListingResponse
    const dealTCOptions = [
      { label: "Yes", value: 1 },
      { label: "No", value: 0 },
    ]
    const { data: vendorDetails } = getVendorDetailsResponse
    setAllVendors(vendorDetails && vendorDetails.vendors && vendorDetails.vendors)

    return (
     <>{isFetching ? (
       <div style={{ paddingTop: 200 }}>
         <Loader top="0px" bottom="0px" />
       </div>
     ) : (
       <Wrap>
         <Header>
           <div>
             <h1>
               {isEdit ? "Edit" : "Add"}
               {" "}
Deal 🤝
             </h1>
           </div>
           <div>
             <Button success onClick={this.createDeals} disabled={isCreatingDeal}>
               Save Deal
             </Button>
           </div>
         </Header>

         {isShowAgentSearch && (
           <Row style={{ margin: "20px 0px" }}>
             <Col>
               {selectedAgent ? (
                 <SelectedAgentView>
                   <div>
                     <p>
                       {selectedAgent.firstname}
                       {" "}
                       {selectedAgent.lastname}
                     </p>
                     <Label style={{ textTransform: "lowercase" }}>{selectedAgent.email}</Label>
                   </div>
                   <div>
                     <Button onClick={() => { this.setState({ selectedAgent: null }) }}>
                       Remove
                     </Button>
                   </div>
                 </SelectedAgentView>
               ) : (
                 <AgentSearch
                   onAgentSelect={this.selectAgent}
                   isAgentNotSelected={isAgentNotSelected}
                 />
               )}
             </Col>
           </Row>
         )}
         <SectionWrap>
           <h6>
             Basic Info
           </h6>
           <React.Fragment>
             <Label>
               Property Details
             </Label>
             <Row noMargin>
               <Col>
                 <TextInput
                   name="listing_address"
                   placeholder="Address"
                   value={form.listing_address}
                   onChange={this.handleChange}
                   isError={errorFields.listing_address}
                   isHideLabel
                 />
               </Col>
             </Row>
             <Row style={{ marginTop: 0 }}>
               <Col>
                 <Label></Label>
                 <GoogleLocation
                   type="cities"
                   value={form.city}
                   restrict={US_OBJ}
                   placeholder={form.city || "City"}
                   location={(data) => {
                     const city = get(data, "locality.long_name") || get(data, "address")
                     const state = get(data, "administrative_area_level_1.long_name")
                     this.setState({
                       form: {
                         ...form,
                         city,
                         state,
                       },
                     })
                   }}
                 />
               </Col>
               <Col>
                 <TextInput
                   name="state"
                   placeholder="State"
                   value={form.state}
                   onChange={this.handleChange}
                   isError={errorFields.state}
                 />
               </Col>
               <Col>
                 <TextInput
                   name="zipcode"
                   placeholder="Zip Code"
                   value={form.zipcode}
                   onChange={this.handleChange}
                   isError={errorFields.zipcode}
                 />
               </Col>
             </Row>
             <Row style={{ margin: "20px 0px" }}>
               <Col>
                 <Label>Co Listing Agent</Label>
                 {form.co_listing_agents ? (
                   <SelectedAgentView>
                     <div>
                       <p>{currentCoListedAgentName}</p>
                       {currentCoListedAgentEmail && (
                         <Label style={{ textTransform: "lowercase" }}>{currentCoListedAgentEmail}</Label>
                       )}
                     </div>
                     <div>
                       <Button
                         onClick={() => {
                           this.setState({
                             form: {
                               ...form,
                               co_listing_agents: null,
                             },
                           })
                         }}
                       >
                         {currentCoListedAgentName ? "Remove" : "Modify/Change"}
                       </Button>
                     </div>
                   </SelectedAgentView>
                 ) : (
                   <AgentSearch
                     onAgentSelect={this.selectCoListingAgent}
                   />
                 )}
               </Col>
             </Row>
             <Row>
               <Col>
                 <Label>Client Details</Label>
                 <ClientSearch
                   searchClientByText={searchClientByText}
                   secureIdForTC={secureIdForTC}
                   agentId={(selectedAgent && selectedAgent.id) || agentId}
                   addClient={this.addClient}
                   clients={form.clients}
                   handleInput={(newClient) => {
                     this.setState({
                       ...form,
                       clients: newClient,
                     })
                   }}
                 />
               </Col>
             </Row>
             <Row style={{ marginTop: 10 }}>
               <Col>
                 <ClientSelector
                   clients={form.clients}
                   agentId={(selectedAgent && selectedAgent.id) || agentId}
                   secureIdForTC={secureIdForTC}
                   handleInput={(newClient) => {
                     this.setState({
                       ...form,
                       clients: newClient,
                     })
                   }}
                   errorFields={errorFields}
                   addClient={this.addClient}
                   searchClientByText={searchClientByText}
                   removeClient={this.removeClient}
                 />
               </Col>
             </Row>
             <Row>
               <Col>
                 <Label>Representing</Label>
                 <Select
                   name="client_type"
                   options={[
                     { label: "Buyer", value: "buyer" },
                     { label: "Seller", value: "seller" },
                     { label: "Landlord", value: "landlord" },
                     { label: "Tenant", value: "tenant" },
                     { label: "Dual", value: "dual" },
                     { label: "Referral", value: "referral" },
                   ]}
                   value={form.client_type}
                   onChange={(selectedItem) => {
                     this.setState({
                       form: {
                         ...form,
                         client_type: selectedItem,
                       },
                     })
                   }}
                 />
               </Col>
               <Col>
                 {/* <Label skyslope>SkySlope ID</Label> */}
                 <TextInput
                   label="Skyslope Unique ID"
                   name="skyslope_unique_id"
                   placeholder="Enter ID"
                   value={form.skyslope_unique_id}
                   onChange={this.handleChange}
                   isError={errorFields.skyslope_unique_id}
                 />
               </Col>
               <Col></Col>
             </Row>
             {/* File Upload */}
             <Row>
               {isEdit && form.file_creation_flow !== "offer" ? (
                 <Col>
                   <Label>File Type</Label>
                   <Select
                     name="file_type"
                     options={FILE_TYPES_LISTING}
                     value={form.file_type}
                     onChange={(selectedItem) => {
                       this.setState({
                         form: {
                           ...form,
                           file_type: selectedItem,
                         },
                       })
                     }}
                   />
                   {form.file_type.value === "new_listing" && isEdit ? (
                     <Label error>* Please fill the additional listing related details in the "TC Listing Details" tab once converted to listings</Label>
                   ) : null }
                 </Col>
               ) : (
                 <Col>
                   <Label>File Type</Label>
                   <Select
                     name="file_type"
                     options={FILE_TYPES}
                     value={form.file_type}
                     onChange={(selectedItem) => {
                       this.setState({
                         form: {
                           ...form,
                           file_type: selectedItem,
                         },
                       })
                     }}
                   />
                 </Col>
               )}
               <Col>
                 <Label>Source</Label>
                 <Select
                   name="source"
                   options={[{
                     label: "Radius Marketplace",
                     value: "radius_marketplace",
                   }, {
                     label: "Zillow (Agent's Personal Account)",
                     value: "zillow_agent_personal_account",
                   }, {
                     label: "Zillow (Radius Provided)",
                     value: "zillow_radius_provided",
                   }, {
                     label: "Zillow Flex",
                     value: "zillow_flex",
                   }, {
                     label: "OpCity/Realtor.com",
                     value: "opcity_realtor_com",
                   }, {
                     label: "Team/Mentor Lead",
                     value: "team_mentor_lead",
                   }, {
                     label: "Self",
                     value: "self",
                   }, {
                     label: "Referrals (Agent/Lender)",
                     value: "referrals_agent_Lender",
                   }, {
                     label: "Sphere of Influence/Personal",
                     value: "sphere_of_influence",
                   },
                   {
                     label: "Others",
                     value: "other",
                   }, {
                     label: "FSBO",
                     value: "fsbo",
                   }, {
                     label: "Mail",
                     value: "mail",
                   }, {
                     label: "Networking",
                     value: "networking",
                   }, {
                     label: "New Construction",
                     value: "new_construction",
                   }, {
                     label: "Open House",
                     value: "open_house",
                   }, {
                     label: "Other - Agency",
                     value: "other_agency",
                   }, {
                     label: "Other - Builder",
                     value: "other_builder",
                   }, {
                     label: "Paid Marketing - Farming/Direct",
                     value: "paid_marketing_farming_direct",
                   }, {
                     label: "Paid Marketing - Other",
                     value: "paid_marketing_other",
                   }, {
                     label: "Paid Marketing - Zillow",
                     value: "paid_marketing_zillow",
                   }, {
                     label: "Personal Transaction",
                     value: "personal_transaction",
                   }, {
                     label: "Property Management",
                     value: "property_management",
                   }, {
                     label: "Floor Call",
                     value: "floor_call",
                   }, {
                     label: "Referral - Attorney",
                     value: "referral_attorney",
                   }, {
                     label: "Referral - From Past Client",
                     value: "referral_from_past_client",
                   }, {
                     label: "Referral - Lendor",
                     value: "referral_lendor",
                   }, {
                     label: "Referral - Other",
                     value: "referral_other",
                   }, {
                     label: "Referral - Real Estate Agent(External)",
                     value: "referral_real_estate_agent_external",
                   }, {
                     label: "Referral - Sphere of Influence",
                     value: "referral_sphere_of_influence",
                   }, {
                     label: "Referral - Title",
                     value: "referral_title",
                   }, {
                     label: "Sign Call",
                     value: "sign_call",
                   }, {
                     label: "Social Profile - Facebook",
                     value: "social_profile_facebook",
                   }, {
                     label: "Social Profile - Instagram",
                     value: "social_profile_instagram",
                   }, {
                     label: "Social Profile - Other",
                     value: "social_profile_other",
                   }, {
                     label: "Events - Other",
                     value: "events_other",
                   }]}
                   value={form.source}
                   onChange={(selectedItem) => {
                     this.setState({
                       form: {
                         ...form,
                         source: selectedItem,
                       },
                     })
                   }}
                 />
               </Col>
               <Col>
                 {(form.source && form.source.value === "other") && (
                   <TextInput
                     fullWidth
                     name="other_source"
                     label="OTHER SOURCE TYPE"
                     placeholder="Other Transaction Type"
                     value={form.other_source}
                     onChange={this.handleChange}
                     isError={errorFields.other_source}
                   />
                 )}
               </Col>
             </Row>
           </React.Fragment>
         </SectionWrap>

         <SectionWrap isAnimate={animatePricing} id="tc-pricing-section">
           <h6>
             Transaction Details
           </h6>
           <Row noMargin>
             <Col>
               <Label>
                Radius Transaction Type
               </Label>
               <Select
                 name="radius_transaction_type"
                 placeholder="Radius Transaction Type"
                 options={RADIUS_TRANSACTION_TYPE}
                 value={form.radius_transaction_type}
                 onChange={(selectedItem) => {
                   this.setState({
                     form: {
                       ...form,
                       radius_transaction_type: selectedItem,
                     },
                   })
                 }}
               />
             </Col>
           </Row>
           <Row style={{ marginTop: 20 }}>
             {(form.file_type
              && (
                form.file_type.value === "pending_contract"
                || form.file_type && form.file_type.value === "listing_with_accepted_offer"
                || form.file_type.value === "dual"
                || form.client_type && (form.file_type.value === "external_referral_agreement" && (form.client_type.value !== "landlord" && form.client_type.value !== "tenant"))
              )) && (
               <Col>
                 <TextInput
                   name="price"
                   label="Sale Price"
                   placeholder="$1,000,000"
                   value={this.handleDecimalPrice(form.price)}
                   onChange={(e) => {
                     this.handleChange(e, deformatCurrency)
                   }}
                   isError={errorFields.price}
                 />
               </Col>
             )}
             <Col>
               {(
                 form.file_type && form.file_type.value === "listing_with_accepted_offer"
                 || form.file_type && form.file_type.value === "dual"
                 || form.file_type && form.file_type.value === "lease_listing"
                 || form.file_type && form.client_type && (form.file_type.value === "external_referral_agreement" && (form.client_type.value === "landlord"))
               ) && (
                 <TextInput
                   name="list_price"
                   label="List Price"
                   placeholder="$900,000"
                   value={this.handleDecimalPrice(form.list_price)}
                   onChange={(e) => {
                     this.handleChange(e, deformatCurrency)
                   }}
                   isError={errorFields.list_price}
                 />
               )}
               {form.file_type && (
                 form.file_type.value === "pending_lease"
                || form.file_type.value === "lease_listing"
                || form.client_type && (form.file_type.value === "external_referral_agreement" && (form.client_type.value === "tenant" || form.client_type.value === "landlord"))
               ) && (
                 <TextInput
                   name="rent_amount"
                   label="Rent Amout"
                   placeholder="$1234"
                   value={this.handleDecimalPrice(form.rent_amount)}
                   onChange={(e) => {
                     this.handleChange(e, deformatCurrency)
                   }}
                   isError={errorFields.rent_amount}
                 />
               )}
             </Col>
             {form.file_type && (
               form.file_type.value === "pending_lease"
               || form.file_type.value === "lease_listing"
               || form.client_type && (form.file_type.value === "external_referral_agreement" && (form.client_type.value === "tenant" || form.client_type.value === "landlord"))
             ) && (
               <Col>
                 <Label>
                  Rent Amount Term
                 </Label>
                 <Select
                   name="rent_amount_term"
                   placeholder="Rent Amount Term"
                   options={[
                     { label: "Monthly", value: "monthly" },
                     { label: "Annually", value: "annually" },
                     { label: "other", value: "other" },
                   ]}
                   value={form.rent_amount_term}
                   onChange={(selectedItem) => {
                     this.setState({
                       form: {
                         ...form,
                         rent_amount_term: selectedItem,
                         other_rent_amount_term: "",
                       },
                     })
                   }}
                 />
               </Col>
             )}
             {form.rent_amount_term
              && (
                form.rent_amount_term.value === "other"
              ) && (
               <Col>
                 <TextInput
                   name="other_rent_amount_term"
                   label="Other Rent Amount Term"
                   placeholder="Enter other term"
                   value={form.other_rent_amount_term}
                   onChange={this.handleChange}
                   isError={errorFields.rent_amount}
                 />
               </Col>
             )}

           </Row>

           <Row style={{ marginTop: 30 }}>
             <Col>
               <Label
                 style={{
                   width: "159px",
                 }}
               >
                Commission Type
               </Label>
               <Select
                 name="commission_type"
                 value={form.commission_type}
                 options={COMMISSION_TYPE}
                 onChange={(selectedItem) => {
                   this.setState({
                     form: {
                       ...form,
                       commission_type: selectedItem,
                     },
                   })
                 }}
               />
             </Col>
             {(
               (form.commission_type && form.commission_type.value === "percentage")
                || (form.commission_type && form.commission_type.value === "both")
             ) && (
               <Col>
                 <TextInput
                   name="commision_percentage"
                   label="Commission Percentage"
                   typeSymbol="%"
                   placeholder="4%"
                   value={form.commision_percentage}
                   onChange={this.handleChange}
                   isError={errorFields.commision_percentage}
                 />
               </Col>
             )}

             {(form.commission_type
              && (form.commission_type.value === "fixed_fee" || form.commission_type.value === "both"))
              && (
                <Col>
                  <TextInput
                    name="fixed_fee"
                    label="Fixed Fee"
                    placeholder="$2,000"
                    isError={errorFields.fixed_fee}
                    value={this.handleDecimalPrice(form.fixed_fee)}
                    onChange={(e) => {
                      this.handleChange(e, deformatCurrency)
                    }}
                  />
                </Col>
              )}
             {(
               (form.commission_type && form.commission_type.value === "percentage")
                 || (form.commission_type && form.commission_type.value === "both")
                 || true
             ) && (
               <Col>
                 <TextInput
                   name="team_split_percentage"
                   label="Split with Team %"
                   typeSymbol="%"
                   placeholder="4%"
                   value={form.team_split_percentage}
                   onChange={this.handleChange}
                   isError={errorFields.team_split_percentage}
                 />
               </Col>
             )}
             {(form.file_type
                && form.file_type.value === "lease_listing"
                && form.file_type.value === "pending_lease"
                || true
             ) && (
               <Col>
                 <TextInput
                   name="gross_commission"
                   label="Gross Commission"
                   placeholder="$2,000"
                   isError={errorFields.gross_commission}
                   value={this.handleDecimalPrice(form.gross_commission)}
                   onChange={(e) => {
                     this.handleChange(e, deformatCurrency)
                   }}
                 />
               </Col>
             )}
             <Col>
               <TextInput
                 name="agent_commission"
                 label="Agent Commission"
                 placeholder="$2,000"
                 isError={errorFields.agent_commission}
                 value={this.handleDecimalPrice(form.agent_commission)}
                 onChange={(e) => {
                   this.handleChange(e, deformatCurrency)
                 }}
               />
             </Col>
           </Row>
           <Row>
             {(
               (form.file_type && form.file_type.value === "external_referral_agreement")
                && (form.commission_type && form.commission_type.value !== "fixed_fee")
             ) && (
               <Col>
                 <TextInput
                   name="closing_agent_commision_percentage"
                   label="Closing Agent Comm. %"
                   typeSymbol="%"
                   placeholder="2.5%"
                   value={form.closing_agent_commision_percentage}
                   onChange={this.handleChange}
                   isError={errorFields.closing_agent_commision_percentage}
                 />
               </Col>
             )}
             <Col>
               <Label>TRANSACTION TYPE</Label>
               <Select
                 name="transaction_type"
                 options={TRANSACTION_TYPE}
                 value={form.transaction_type}
                 onChange={(selectedItem) => {
                   this.setState({
                     form: {
                       ...form,
                       transaction_type: selectedItem,
                     },
                   })
                 }}
               />
             </Col>
             <Col>
               {(form.transaction_type && form.transaction_type.value === "other") && (
                 <TextInput
                   fullWidth
                   fullHeight
                   name="other_transaction_type"
                   label="OTHER TRANSACTION TYPE"
                   placeholder="Other Transaction Type"
                   value={form.other_transaction_type}
                   onChange={this.handleChange}
                   isError={errorFields.other_transaction_type}
                 />
               )}
             </Col>
           </Row>
           <Row>
             {form.file_type && form.file_type.value !== "new_listing" && (
               <Col>
                 <TextInput
                   name="created_at"
                   label="Acceptance Date (Date of Contract)"
                   fullWidth
                   fullHeight
                   placeholder="Contract On"
                   type="date"
                   max={moment().format("YYYY-MM-DD")}
                   value={form.created_at ? moment.unix(form.created_at).format("YYYY-MM-DD") : moment(new Date()).format("YYYY-MM-DD")}
                   onChange={(e) => {
                     this.setState({
                       form: {
                         ...form,
                         created_at: moment(e.target.value).unix(),
                       },
                     })
                   }}
                 />
               </Col>
             )}
             <Col>
               <TextInput
                 name="closed_on"
                 min={moment(nowTime).format("YYYY-MM-DD")}
                 label="Est. Closing Date (Escrow)"
                 placeholder="Close On"
                 fullWidth
                 fullHeight
                 type="date"
                 value={form.closed_on ? moment.unix(form.closed_on).format("YYYY-MM-DD") : null}
                 isError={errorFields.closed_on}
                 onChange={(e) => {
                   this.setState({
                     form: {
                       ...form,
                       closed_on: e.target.value ? moment(e.target.value).unix() : null,
                       confirmed_closed_on: e.target.value ? moment(e.target.value).unix() : null,
                     },
                   })
                 }}
               />
             </Col>
             <Col>
               <TextInput
                 name="confirmed_closed_on"
                 min={moment(nowTime).format("YYYY-MM-DD")}
                 label="Confirmed/Tentative Closed Date (Escrow)"
                 placeholder="Confirmed Closed On"
                 type="date"
                 fullWidth
                 fullHeight
                 value={form.confirmed_closed_on !== null ? moment.unix(form.confirmed_closed_on).format("YYYY-MM-DD") : (form.closed_on ? moment.unix(form.closed_on).format("YYYY-MM-DD") : null)}
                 isError={errorFields.confirmed_closed_on}
                 onChange={(e) => {
                   this.setState({
                     form: {
                       ...form,
                       confirmed_closed_on: e.target.value ? moment(e.target.value).unix() : null,
                     },
                   })
                 }}
               />
             </Col>
             {(form.file_type && form.file_type.value === "lease_listing"
                 || form.file_type && form.file_type.value === "pending_lease"
             ) && (
               <Col>
                 <TextInput
                   name="lease_commencement_on"
                   label="Lease Commencement Date"
                   placeholder="Lease Commencement Date"
                   fullHeight
                   type="date"
                   value={moment.unix(form.lease_commencement_on || nowTime).format("YYYY-MM-DD")}
                   isError={errorFields.lease_commencement_on}
                   onChange={(e) => {
                     this.setState({
                       form: {
                         ...form,
                         lease_commencement_on: moment(e.target.value).unix(),
                       },
                     })
                   }}
                 />
               </Col>
             )}
           </Row>

           <Row>
             {form.file_type && form.file_type.value !== "new_listing" && (
               <Col>
                 <TextInput
                   name="row_created_at"
                   label="Date of Contract Submission"
                   placeholder="Submission Date"
                   fullHeight
                   type="date"
                   value={this.updateRowCreatedAt(form)}
                   isError={errorFields.row_created_at}
                   onChange={(e) => {
                     this.setState({
                       form: {
                         ...form,
                         row_created_at: moment(e.target.value).format("YYYY-MM-DD 00:00:00"),
                       },
                     })
                   }}
                 />
               </Col>
             )}
           </Row>
         </SectionWrap>
         <SectionWrap isAnimate={animatePricing} id="tc-pricing-section">
           <h6>
              Radius Fee Details
           </h6>
           <Row noMargin>
             <Col>
               <Label>
                  Brokerage Fee
               </Label>
               <InputNumber
                 name="brokerage_fee"
                 label="Brokerage Fee"
                 placeholder="$2,000"
                 isError={errorFields.brokerage_fee}
                 value={form.brokerage_fee}
                 onChange={(value) => {
                   this.handleChangeNumber(value,"brokerage_fee")
                 }}
               />
             </Col>
           </Row>
           <Row>
             <Col>
               <Label>
                  Agent Payout Fee
               </Label>
               <InputNumber
                 name="agent_payout_fee"
                 label="Agent Payout Fee"
                 placeholder="$2,000"
                 isError={errorFields.agent_payout_fee}
                 value={form.agent_payout_fee}
                 onChange={(value) => {
                   this.handleChangeNumber(value, "agent_payout_fee")
                 }}
               />
             </Col>
             <Col>
               <Label>
                  TC Fee
               </Label>
               <InputNumber
                 name="tc_fee"
                 label="TC Fee"
                 placeholder="$2,000"
                 isError={errorFields.tc_fee}
                 value={form.tc_fee}
                 onChange={(value) => {
                   this.handleChangeNumber(value, "tc_fee")
                 }}
               />
             </Col>
           </Row>
           <Row>
             <Col>
               <Label>
                  Audit Fee
               </Label>
               <InputNumber
                 name="audit_fee"
                 label="Audit Fee"
                 placeholder="$2,000"
                 isError={errorFields.audit_fee}
                 value={form.audit_fee}
                 onChange={(value) => {
                   this.handleChangeNumber(value, "audit_fee")
                 }}
               />
             </Col>
             <Col>
               <Label>
                  Pending Subscription Fee
               </Label>
               <InputNumber
                 name="pending_subscription_fee"
                 label="Pending Subscription Fee"
                 placeholder="$2,000"
                 isError={errorFields.pending_subscription_fee}
                 value={form.pending_subscription_fee}
                 onChange={(value) => {
                   this.handleChangeNumber(value, "pending_subscription_fee")
                 }}
               />
             </Col>
           </Row>
           <Row>
             <Col>
               <Label>
                 E&O Fee
               </Label>
               <InputNumber
                 name="e_and_o_fee"
                 label="E&O Fee"
                 placeholder="$2,000"
                 isError={errorFields.e_and_o_fee}
                 value={form.e_and_o_fee}
                 onChange={(value) => {
                   this.handleChangeNumber(value, "e_and_o_fee")
                 }}
               />
             </Col>
             <Col>
               <Label>
                  Referral Fee
               </Label>
               <InputNumber
                 name="referral_fee"
                 label="Referral Fee"
                 placeholder="$2,000"
                 isError={errorFields.referral_fee}
                 value={form.referral_fee === 0 ? this.handleDecimalPrice("0") : this.handleDecimalPrice(form.referral_fee)}
                 onChange={(value) => {
                   this.handleChangeNumber(value, "referral_fee")
                 }}
               />
             </Col>
           </Row>
           <Row>
             <Col>
               <Label>
                  Radius Fee
               </Label>
               <InputNumber
                 name="radius_transaction_fee"
                 label="Radius Fee"
                 placeholder="$2,000"
                 isError={errorFields.radius_transaction_fee}
                 //defaultValue={form.radius_transaction_fee || null}
                 value={form.radius_transaction_fee}
               />
             </Col>
           </Row>
         </SectionWrap>
         <SectionWrap>
           <h6>
             Listing/Deal Details
           </h6>
           {/* {form.file_type && form.file_type.value === "new_listing" && (
             <Row noMargin>
               <Col>
                 <Label>
                  Active Listing Status
                 </Label>
                 <Select
                   name="active_listing_status"
                   placeholder="Active Listing Status"
                   options={[
                     { label: "Active Listing", value: "active_listing" },
                     { label: "In-Contract", value: "in_contract" },
                     { label: "Closed", value: "closed" },
                     { label: "Pending", value: "pending" },
                     { label: "Cancelled", value: "cancelled" },
                   ]}
                   value={form.active_listing_status}
                   onChange={(selectedItem) => {
                     this.setState({
                       form: {
                         ...form,
                         active_listing_status: selectedItem,
                       },
                     })
                   }}
                 />
               </Col>
             </Row>
           )} */}
           <Row>
             <Col>
               <Label>
                Would you like to use our Radius in-house TC for this transaction?
               </Label>
               <Select
                 name="is_using_deal_tc"
                 placeholder={form.is_using_deal_tc === null ? " Yes/No " : form.is_using_deal_tc === 1 ? "Yes" : "No"}
                 defaultValue={form.is_using_deal_tc === null ? " - " : form.is_using_deal_tc === 1 ? "Yes" : "No"}
                 required
                 options={dealTCOptions}
                 onChange={(selectedItem) => {
                   this.setState({
                     form: {
                       ...form,
                       is_using_deal_tc: selectedItem,
                     },
                   })
                 }}
                 value={form.is_using_deal_tc}
               />
               {(
                 form.is_using_deal_tc && form.is_using_deal_tc.value === 0
                 || form.is_using_deal_tc && form.is_using_deal_tc === undefined
               ) && (
                 <TCDetails>
                   <Col>
                     <TextInput
                       label="External TC Name"
                       name="tc_name"
                       placeholder="Name"
                       value={form.tc_name}
                       onChange={this.handleChange}
                       isError={errorFields.tc_name}
                     />
                   </Col>
                   <Col>
                     <TextInput
                       label="External TC Email"
                       name="tc_email"
                       placeholder="Email"
                       value={form.tc_email}
                       onChange={this.handleChange}
                       isError={errorFields.tc_email}
                     />
                   </Col>
                   <Col>
                     <TextInput
                       label="External TC Phone"
                       name="tc_phone"
                       placeholder="+1 1234566789"
                       value={form.tc_phone}
                       onChange={this.handleChange}
                       isError={errorFields.tc_phone}
                     />
                   </Col>
                 </TCDetails>
               )}
             </Col>
           </Row>
           {isEdit && (
             <Row>
                  <>
                    <Col>
                      <Label>
                Would you like to apply the offer coupon for this transaction?
                      </Label>
                      <Select
                        name="is_coupon_applied"
                        placeholder="No"
                        defaultValue={0}
                        required
                        options={dealTCOptions}
                        onChange={(selectedItem) => {
                          this.setState({
                            form: {
                              ...form,
                              is_coupon_applied: selectedItem,
                            },
                          })
                        }}
                        value={form.is_coupon_applied}
                      />
                    </Col>
                  </>
             </Row>
           )}
           <Row>
             <Col>
               <Label>
                 Is this transaction an all cash offer?
               </Label>
               <Select
                 name="is_all_cash_offer"
                 placeholder="Yes/No"
                 required
                 options={[
                   { label: "Yes", value: 1 },
                   { label: "No", value: 0 },
                 ]}
                 value={form.is_all_cash_offer}
                 onChange={(selectedItem) => {
                   this.setState({
                     form: {
                       ...form,
                       is_all_cash_offer: selectedItem,
                     },
                   })
                 }}
               />
             </Col>
           </Row>
           {this.boolCheck(form.is_all_cash_offer) === 0 && (
             <Row>
               <Col>
                 <TextInput
                   label="Lender Email Address"
                   name="lender_email_address"
                   placeholder="biju@ad.com"
                   value={form.lender_email_address}
                   onChange={this.handleChange}
                   isError={errorFields.lender_email_address}
                 />
               </Col>
             </Row>
           )}
         </SectionWrap>

         {(form.file_type
         && (
           form.file_type.value === "pending_contract"
           || form.file_type.value === "external_referral_agreement"
           || form.file_type.value === "dual"
         ) && (
           form.is_using_deal_tc
          && (
            form.is_using_deal_tc.value === 1
          )
         )
         ) && (
           <SectionWrap>
             <h6>
             Escrow/Pre-Escrow
             </h6>
             <Row isEscrow noMargin>
               <Col>
                 <Label>
                 Radius TC to Open Escrow?
                 </Label>
                 <Select
                   name="is_radius_tc_to_open_escrow"
                   placeholder="Yes/No"
                   defaultValue={{ label: "Yes", value: 1 }}
                   options={[
                     { label: "Yes", value: 1 },
                     { label: "No", value: 0 },
                   ]}
                   value={form.is_radius_tc_to_open_escrow}
                   onChange={(selectedItem) => {
                     this.setState({
                       form: {
                         ...form,
                         is_radius_tc_to_open_escrow: selectedItem,
                       },
                     })
                   }}
                 />
               </Col>
               {(
                 form.is_radius_tc_to_open_escrow && form.is_radius_tc_to_open_escrow.value === 1
              || form.is_radius_tc_to_open_escrow && form.is_radius_tc_to_open_escrow === undefined
               ) && (
                <>
                  {ListValues.ListValues.length === 0 ? (
                    <>
                      <AntdButton
                        type="primary"
                        onClick={() => {
                          setEscrowEdit(null)
                          toggleEscrowDrawer(true)
                        }}
                        style={{
                          marginBottom: "10px",
                          width: "250px",
                        }}
                      >
                    Add new Escrow Details
                      </AntdButton>
                      <EscrowDrawer agentId={agentId} />
                    </>
                  ) : (
                    <>
                      <EscrowWrap>
                        <TitleWrap>
                          <Title>
                            Escrow and Title Information
                          </Title>
                          <AntdButton
                            type="primary"
                            onClick={() => {
                              setEscrowEdit(null)
                              toggleEscrowDrawer(true)
                            }}
                            style={{
                              marginBottom: "10px",
                              width: "250px",
                            }}
                          >
                    Add new Escrow Details
                          </AntdButton>
                        </TitleWrap>
                        {allVendors && (
                          <List
                            dataSource={allVendors}
                            itemLayout="horizontal"
                            renderItem={item => (
                              <List.Item
                                actions={[
                                  <AntdButton
                                    type="primary"
                                    onClick={() => {
                                      toggleEscrowDrawer(true)
                                      setEscrowEdit(item.id)
                                    }}
                                  >
                                  Edit
                                  </AntdButton>,
                                  <>
                                    {(selectedId === item.id || allVendors.indexOf(item) === isSelected) ? (
                                      <SelectedButton
                                        type="primary"
                                      >
                                    Selected
                                      </SelectedButton>
                                    ) : (
                                      <SelectedButton
                                        type="default"
                                        onClick={() => {
                                          if (item.id) {
                                            setSelectedId(item.id)
                                            this.setState({
                                              isSelected: null,
                                            })
                                          } else {
                                            item.is_selected = true
                                            const index = allVendors.indexOf(item)
                                            const a = allVendors.map((x) => {
                                              if (x.is_selected && x !== item) {
                                                delete x.is_selected
                                              } else if (x.is_selected && x === item) {
                                                this.setState({
                                                  isSelected: index,
                                                })
                                                setSelectedId(null)
                                              }
                                            })
                                          }
                                        }}
                                      >
                                      Select
                                      </SelectedButton>
                                    )}
                                  </>,
                                ]}
                              >
                                <List.Item.Meta
                                  title={<h4>{this.formatTitle(item.vendor_type)}</h4>}
                                  description={(
                                    <InfoWrap>
                                      <VendorLabel>{item.vendor_name}</VendorLabel>
                                      <VendorLabel>{item.vendor_number}</VendorLabel>
                                      <VendorLabel>
                                        {item.vendor_officer_first_name}
                                        {" "}
                                        {item.vendor_officer_last_name}
                                      </VendorLabel>
                                      <VendorLabel>
                                        {item.vendor_address}
                                        {", "}
                                        {item.vendor_city}
                                        {", "}
                                        {item.vendor_state}
                                        {"  "}
                                        {item.vendor_zipcode}
                                      </VendorLabel>
                                      <VendorLabel>{item.vendor_email}</VendorLabel>
                                    </InfoWrap>
                                  )}
                                />
                              </List.Item>
                            )}
                          />
                        )}
                      </EscrowWrap>
                    </>
                  ) }
                  <EscrowDrawer agentId={agentId} />
                </>
               )}
             </Row>
           </SectionWrap>
         )}
         {/* {form.file_type && form.file_type.value === "new_listing" && (
           <SectionWrap>
             <h6>
             Sign and Post Installation
             </h6>
             <Row noMargin>
               <Col>
                 <Label>
                  Are you requesting sign post installation?
                 </Label>
                 <Select
                   name="schedule_sign_post_installation"
                   placeholder="Yes/No"
                   options={[
                     { label: "Yes", value: 1 },
                     { label: "No", value: 0 },
                   ]}
                   value={form.schedule_sign_post_installation}
                   onChange={(selectedItem) => {
                     this.setState({
                       form: {
                         ...form,
                         schedule_sign_post_installation: selectedItem,
                       },
                     })
                   }}
                 />
               </Col>
             </Row>
             {(form.schedule_sign_post_installation
              && form.schedule_sign_post_installation.value === 1) ? (
              <>
                <Row>
                  <Col>
                    <TextInput
                      fullWidth
                      name="sign_post_installation_on"
                      label="Sign Post Installation On"
                      placeholder={moment(new Date()).format("D MMM, YYYY")}
                      type="date"
                      value={moment.unix(form.sign_post_installation_on || nowTime).format("YYYY-MM-DD")}
                      isError={errorFields.sign_post_installation_on}
                      onChange={(e) => {
                        this.setState({
                          form: {
                            ...form,
                            sign_post_installation_on: moment(e.target.value).unix(),
                          },
                        })
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <TextInput
                      fullWidth
                      label="If riders are necessary, please specify"
                      name="sign_post_installation_riders"
                      placeholder="Rider info"
                      value={form.sign_post_installation_riders}
                      onChange={this.handleChange}
                      isError={errorFields.sign_post_installation_riders}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <TextInput
                      fullWidth
                      label="What is the specific location where the sign should be installed?"
                      name="sign_post_installation_location"
                      placeholder="Front yard, backback yard, etc."
                      value={form.sign_post_installation_location}
                      onChange={this.handleChange}
                      isError={errorFields.sign_post_installation_location}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <TextInput
                      fullWidth
                      label="Are there any specific HOA guidelines?"
                      name="hoa_guidelines"
                      placeholder="The sign must be near the middle of the yard."
                      value={form.hoa_guidelines}
                      onChange={this.handleChange}
                      isError={errorFields.hoa_guidelines}
                    />
                  </Col>
                </Row>
              </>
               ) : null}
           </SectionWrap>
         )} */}
         {false && dealID && (
           <SectionWrap>
             <h6>Upload Files</h6>
             <Row noMargin>
               <Col>
                 <p>
                 Please upload fully executed contract documents for your TC to begin your transaction.
                   <br />
                   <br />
                 If your TC does not have your CAR, MLS, and/or DocuSign logins,
                 please email those to your assigned TC directly:
                 </p>
                 <UploadWrap>
                   <input type="file" onChange={() => { }} />
                 </UploadWrap>
               </Col>
             </Row>
           </SectionWrap>
         )}
         <ToastContainer />
       </Wrap>
     )}
     </>
    )
  }
}

export default container(withRouter(AddNewDeal))
