import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { SearchOutlined } from "@ant-design/icons";
import { Input, Space, Dropdown, Menu, DatePicker, Divider } from "antd";
import { Table } from "antd";
import container from "container/BrokerDashboard/globalPagesContainer";
import agentSearchContainer from "container/BrokerDashboard/agentSearchContainer";
import teamContainer from "container/BrokerDashboard/teamContainer.js";
import InfiniteScroll from "react-infinite-scroller";
import moment from "moment-timezone";
import { throttle } from "lodash";

const FilterWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 20px 0px 20px;
  gap: 24px;
`;

const StyledInput = styled(Input)`
  width: ${(props) => props.width || "200px"} !important;
  height: 41px !important;
  border-radius: 8px !important;
  font-size: 16px;

  .ant-input-prefix {
    margin-right: 8px;
  }
`;

const SearchDropdownContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  padding: 8px 12px;
  width: ${(props) => props.width || "200px"} !important;
  cursor: pointer;
  height: 41px;
`;

const StyledDatePicker = styled(DatePicker)`
  border-radius: 8px !important;
  padding: 8px 12px;
  font-size: 16px;

  &:hover,
  &:focus {
    box-shadow: none !important;
  }

  .ant-picker-input > input {
    color: #9ca3af;
    text-align: left;
  }

  .ant-picker-suffix {
    color: #9ca3af;
  }
`;
const DatePickerContainer = styled.div`
  width: ${(props) => props.width || "200px"} !important;
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-size: 14px;
  font-weight: 500;
`;

const InputGroup = styled.div`
  display: flex;
  width: ${(props) => props.width || "200px"} !important;
  flex-direction: column;
  gap: 6px;
`;

const InputRow = styled.div`
  display: flex;
  gap: 12px;
  padding: 0;
`;

const StyledCurrencyInput = styled(Input)`
  height: 41px;
  border-radius: 8px !important;
  border: 1px solid #e5e7eb !important;
  font-size: 16px;
  color: #9ca3af;
  display: flex;
  align-items: center;
  padding: 0px !important;

  .ant-input {
    border: none !important;
    box-shadow: none !important;
    padding-left: 8px !important;
    margin: 0px !important;
  }

  .ant-input-prefix {
    background: #e5e7eb;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-right: 1px solid #e5e7eb;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 40px;
    height: 100%;
    font-size: 16px;
    margin: 0px !important;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 18px;
  margin-top: 10px;
`;

const SuggestionsList = styled.ul`
  position: absolute;
  top: 130px; /* Ensures it appears just below the input */
  width: 32.6%;
  background: white;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  list-style: none;
  padding: 8px 0;
  margin: 0;
  max-height: 200px;
  overflow-y: auto;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
`;

const SuggestionItem = styled.li`
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  &:hover {
    background: #f5f5f5;
  }
`;

const Avatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
  background: #ddd;
`;

const AgentDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const AgentName = styled.div`
  font-weight: bold;
`;

const AgentEmail = styled.div`
  font-size: 14px;
  color: gray;
`;

const AgentPhone = styled.div`
  font-size: 14px;
  color: gray;
`;

const FilterBoard = ({
  getAllAgentsForRosterPage,
  getAllAccountsForRosterPage,
  getSaasAccountsForRosterPage,
  activeTab,
  data,
  isFetchingMore,
  isFetching,
  downloadAllAccountsRosterPage,
  downloadAllSaasAccountsRosterPage,
  downloadAllAgentsRosterPage,
  ...props
}) => {

  const handlesearchByAccount = (value) => {
    setSearchByAccount(value);
  };

  const sortFunctionKeysForAgents = (key) => {
    if (key == "createdDate") return "createdAtSortOrder";
    if (key == "brokerageDate") return "brokerageJoinDateSortOrder";
    if (key == "churnedDate") return "churnedSortOrder";
    if (key == "dealsClosed") return "dealsClosedSortOrder";
    if (key == "dealsInContract") return "dealsInContractsSortOrder";
    if (key == "listingsActive") return "listingsSortOrder";
    if (key == "totalProduction") return "totalProductionSortOrder";
    if (key == "last12MonthsProduction")
      return "last12MonthsProductionSortOrder";
    if (key == "totalGrossCommission") return "totalGrossCommissionSortOrder";
    if (key == "totalAgentCommission") return "totalAgentCommissionSortOrder";
    if (key == "cap") return "capSortOrder";
    if (key == "totalRadiusTransactionRevenue")
      return "totalRadiusFeesSortOrder";
    // if(key=="additionARCollected")
    //   return "brokerageJoinDateSortOrder";
    // if(key=="accountsReceivable")
    //   return "brokerageJoinDateSortOrder";
  };

  const sortFunctionKeysForAccounts = (key) => {
    if (key == "createdDate") return "createdAtSortOrder";
    if (key == "brokerageDate") return "brokerageJoinDateSortOrder";
    if (key == "churnedDate") return "churnedSortOrder";
    if (key == "dealsClosed") return "dealsClosedSortOrder";
    if (key == "teamSize") return "teamSizeSortOrder";
    if (key == "dealsInContract") return "dealsInContractsSortOrder";
    if (key == "listingsActive") return "listingsSortOrder";
    if (key == "totalProduction") return "totalProductionSortOrder";
    if (key == "last12MonthsProduction")
      return "last12MonthsProductionSortOrder";
    if (key == "totalGrossCommission") return "totalGrossCommissionSortOrder";
    if (key == "totalAgentCommission") return "totalAgentCommissionSortOrder";
    if (key == "totalRadiusTransactionRevenue")
      return "totalRadiusFeesSortOrder";
    // if(key=="additionARCollected")
    //   return "brokerageJoinDateSortOrder";
    // if(key=="accountsReceivable")
    //   return "brokerageJoinDateSortOrder";
  };

  const sortFunctionKeysForSaasAccounts = (key) => {
    if (key == "createdDate") return "createdAtSortOrder";
    if (key == "subsDate") return "subsDateSortOrder";
    if (key == "churnedDate") return "churnedSortOrder";
    if (key == "aiCreditsPaid") return "aiCreditsPaidSortOrder";
    if (key == "aiCreditsConsumed") return "aiCreditsConsumedSortOrder";
    if (key == "aiCreditsAvailable") return "aiCreditsAvailableSortOrder";
    if (key == "clientAppLogins") return "clientAppLoginsSortOrder";
  };

  const [searchByEmail, setSearchByEmail] = useState("");
  const [search, setSearch] = useState(true);
  // const [searchAccounts,setSearchAccounts]=useState(true);
  const [searchByAccount, setSearchByAccount] = useState("");
  const [downloadRequest, setDownloadRequest] = useState("");
  const [selectedRole, setSelectedRole] = useState(null);
  const [brokerageJoinDate, setBrokerageJoinDate] = useState(null);
  const [churnedDate, setChurnedDate] = useState(null);
  const [createdDate, setCreatedDate] = useState(null);
  const [dealsClosedRange, setDealsClosedRange] = useState(["", ""]);
  const [transactionRevenueRange, setTransactionRevenueRange] = useState([
    "",
    "",
  ]);
  const [selectedSplit, setSelectedSplit] = useState(null);
  const [selectedCap, setSelectedCap] = useState(null);
  const [selectedTeamsize, setSelectedTeamsize] = useState(null);
  const [minTotalProductionRange, setMinTotalProductionRange] = useState("");
  const [maxTotalProductionRange, setMaxTotalProductionRange] = useState("");
  const [
    last12MonthsProductionRange,
    setLast12MonthsProductionRange,
  ] = useState(["", ""]);
  const [creditAvailable, setCreditsAvailable] = useState(["", ""]);
  const [creditPaid, setCreditsPaid] = useState(["", ""]);
  const [creditConsumed, setCreditsConsumed] = useState(["", ""]);
  const [capRange, setCapRange] = useState(null);
  const [seeFilters, setSeeFilters] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [limit] = useState(50);
  const [skip, setSkip] = useState(0);
  const [sortConfig, setSortConfig] = useState({ key: null, order: null });
  const [sortAccountsConfig, setAccountsSortConfig] = useState({
    key: null,
    order: null,
  });
  const [sortSaasAccountsConfig, setSaasAccountsSortConfig] = useState({
    key: null,
    order: null,
  });

  const createPayloadForAgents = (extra = {}, clearSkip = false) => {
    const payload = {
      limit: 50,
      ...extra,
    };

    if (brokerageJoinDate) {
      const dateObj = new Date(brokerageJoinDate);
      dateObj.setHours(0, 0, 0, 0);
      payload.brokerageJoinDate = moment(dateObj).unix();
    }
    if (dealsClosedRange[0]) {
      payload.dealsClosedMin = dealsClosedRange[0];
    }
    if (dealsClosedRange[1]) {
      payload.dealsClosedMax = dealsClosedRange[1];
    }
    if (churnedDate) {
      const dateObj = new Date(churnedDate);
      dateObj.setHours(0, 0, 0, 0);
      payload.churnedDate = moment(dateObj).unix();
    }
    if (transactionRevenueRange[0]) {
      payload.totalTransactionRevenueMin = transactionRevenueRange[0];
    }
    if (transactionRevenueRange[1]) {
      payload.totalTransactionRevenueMax = transactionRevenueRange[1];
    }
    if (minTotalProductionRange) {
      payload.totalProductionMin = minTotalProductionRange;
    }
    if (maxTotalProductionRange) {
      payload.totalProductionMax = maxTotalProductionRange;
    }
    if (last12MonthsProductionRange[0]) {
      payload.lastYearProductionMin = last12MonthsProductionRange[0];
    }
    if (last12MonthsProductionRange[1]) {
      payload.lastYearProductionMax = last12MonthsProductionRange[1];
    }
    if (searchByAccount) {
      payload.accountName = searchByAccount;
    }
    if (selectedSplit) {
      payload.split = selectedSplit;
    }
    if (searchByEmail) {
      payload.searchByEmail = searchByEmail;
    }
    if (createdDate) {
      const dateObj = new Date(createdDate);
      dateObj.setHours(0, 0, 0, 0);
      payload.createDate = moment(dateObj).unix();
    }
    if (selectedRole) {
      payload.agentType = selectedRole;
    }
    if (capRange) {
      payload.cap = capRange;
    }

    return payload;
  };

  const createPayloadForAccounts = (extra = {}, clearSkip = false) => {
    const payload = {
      limit: limit,
      ...extra,
    };
    if (selectedTeamsize) {
      payload.teamSize = selectedTeamsize;
    }
    if (brokerageJoinDate) {
      const dateObj = new Date(brokerageJoinDate);
      dateObj.setHours(0, 0, 0, 0);
      payload.brokerageJoinDate = moment(dateObj).unix();
    }
    if (createdDate) {
      const dateObj = new Date(createdDate);
      dateObj.setHours(0, 0, 0, 0);
      payload.createDate = moment(dateObj).unix();
    }
    if (churnedDate) {
      const dateObj = new Date(churnedDate);
      dateObj.setHours(0, 0, 0, 0);
      payload.churnedDate = moment(dateObj).unix();
    }
    if (dealsClosedRange[0]) {
      payload.minDealsClosed = dealsClosedRange[0];
    }
    if (dealsClosedRange[1]) {
      payload.maxDealsClosed = dealsClosedRange[1];
    }
    if (transactionRevenueRange[0]) {
      payload.minTransactionRevenue = transactionRevenueRange[0];
    }
    if (transactionRevenueRange[1]) {
      payload.maxTransactionRevenue = transactionRevenueRange[1];
    }
    if (minTotalProductionRange) {
      payload.minTotalProduction = minTotalProductionRange;
    }
    if (maxTotalProductionRange) {
      payload.maxTotalProduction = maxTotalProductionRange;
    }
    if (last12MonthsProductionRange[0]) {
      payload.minLastYearProductionMin = last12MonthsProductionRange[0];
    }
    if (last12MonthsProductionRange[1]) {
      payload.maxLastYearProductionMax = last12MonthsProductionRange[1];
    }
    if (selectedSplit) {
      payload.split = selectedSplit;
    }

    return payload;
  };

  const createPayloadForSaasAccounts = (extra = {}, clearSkip = false) => {
    const payload = {
      limit: limit,
      ...extra,
    };
    if (searchByAccount) {
      payload.name = searchByAccount;
    }
    if (brokerageJoinDate) {
      const dateObj = new Date(brokerageJoinDate);
      dateObj.setHours(0, 0, 0, 0);
      payload.brokerageJoinDate = moment(dateObj).unix();
    }
    if (churnedDate) {
      const dateObj = new Date(churnedDate);
      dateObj.setHours(0, 0, 0, 0);
      payload.churnedDate = moment(dateObj).unix();
    }
    if (createdDate) {
      const dateObj = new Date(createdDate);
      dateObj.setHours(0, 0, 0, 0);
      payload.createDate = moment(dateObj).unix();
    }
    if (creditPaid[0]) {
      payload.aiCreditsPaidMin = creditPaid[0];
    }
    if (creditPaid[1]) {
      payload.aiCreditsPaidMax = creditPaid[1];
    }
    if (creditConsumed[0]) {
      payload.aiCreditsConsumedMin = creditConsumed[0];
    }
    if (creditConsumed[1]) {
      payload.aiCreditsConsumedMax = creditConsumed[1];
    }
    if (creditAvailable[0]) {
      payload.aiCreditsAvailableMin = creditAvailable[0];
    }
    if (creditAvailable[1]) {
      payload.aiCreditsAvailableMax = creditAvailable[1];
    }

    return payload;
  };

  const handleDownload = () => {
    // setDownloadRequest(true);
    if (activeTab == "1") {
      downloadAllAccountsRosterPage(createPayloadForAccounts({}, true));
    }
    if (activeTab == "2") {
      downloadAllAgentsRosterPage(createPayloadForAgents({}, true));
    }
    if (activeTab == "3") {
      downloadAllSaasAccountsRosterPage(createPayloadForSaasAccounts({}, true));
    }
  };

  useEffect(() => {
    if (activeTab == "1") {
      const accountPayload = createPayloadForAccounts();
      getAllAccountsForRosterPage(accountPayload);
    }
    if (activeTab == "2") {
      const agentPayload = createPayloadForAgents();
      getAllAgentsForRosterPage(agentPayload);
    }
    if (activeTab == "3") {
      const saasAccountPayload = createPayloadForSaasAccounts();
      getSaasAccountsForRosterPage(saasAccountPayload);
    }
  }, [search]);

  const handleClearFilter = () => {
    if (activeTab == "1") getAllAccountsForRosterPage({ limit: limit });
    if (activeTab == "2") getAllAgentsForRosterPage({ limit: limit });
    if (activeTab == "3") getSaasAccountsForRosterPage({ limit: limit });
    setMinTotalProductionRange("");
    setMaxTotalProductionRange("");
    setLast12MonthsProductionRange(["", ""]);
    setCreditsAvailable(["", ""]);
    setCreditsPaid(["", ""]);
    setCreditsConsumed(["", ""]);
    setCapRange(["", ""]);
    setSortConfig({ key: null, order: null });
    setAccountsSortConfig({ key: null, order: null });
    setSaasAccountsSortConfig({ key: null, order: null });
    setSearchByAccount("");
    setSelectedRole(null);
    setBrokerageJoinDate(null);
    setChurnedDate(null);
    setCreatedDate(null);
    setDealsClosedRange(["", ""]);
    setTransactionRevenueRange(["", ""]);
  };

  // useEffect(() => {

  // },[searchAccounts])

  const handleSortForAgents = (columnKey, order) => {
    setSortConfig({ key: columnKey, order });
    const payload = {
      ...createPayloadForAgents(),
      skip: 0,
    };

    if (order) {
      payload.sortBy = `${columnKey}=${order}`;
    }

    getAllAgentsForRosterPage(payload);
  };

  const handleSortForAccounts = (columnKey, order) => {
    setAccountsSortConfig({ key: columnKey, order });
    const payload = {
      ...createPayloadForAccounts(),
      skip: 0,
    };

    if (order) {
      payload.sortBy = `${columnKey}=${order}`;
    }

    getAllAccountsForRosterPage(payload);
  };

  const handleSortForSaasAccounts = (columnKey, order) => {
    setSaasAccountsSortConfig({ key: columnKey, order });
    const payload = {
      ...createPayloadForSaasAccounts(),
      skip: 0,
    };

    if (order) {
      payload.sortBy = `${columnKey}=${order}`;
    }

    getSaasAccountsForRosterPage(payload);
  };

  const handleRoleSelect = (e) => {
    setSelectedRole(e.key === "All" ? null : e.key);
  };

  const handleSplitSelect = (e) => {
    setSelectedSplit(e.key === "All" ? null : e.key)
  }

  const handleTeamSelect = (e) => {
    setSelectedTeamsize(e.key);
  }

  const roleitems = (
    <Menu onClick={handleRoleSelect}>
      <Menu.Item key="team_lead">Team Lead</Menu.Item>
      <Menu.Item key="team_member">Team Member</Menu.Item>
      <Menu.Item key="individual_agent">Individual Agent</Menu.Item>
      <Menu.Item key="All">All</Menu.Item>
    </Menu>
  );

  const teamSizeOptions = (
    <Menu onClick={handleTeamSelect}>
      <Menu.Item key="lessThan10">{" < 10"}</Menu.Item>
      <Menu.Item key="greaterThan10">{"10 + "}</Menu.Item>
      <Menu.Item key="greaterThan20">{"20 + "}</Menu.Item>
      <Menu.Item key="greaterThan50">{"50 + "}</Menu.Item>
      <Menu.Item key="All">{"All"}</Menu.Item>
    </Menu>
  );

  const splitItems = (
    <Menu onClick={handleSplitSelect}>
      <Menu.Item key="Flagship partner plan (95/5 until $500K, 97/3 after)">Flagship partner plan (95/5 until $500K, 97/3 after)</Menu.Item>
      <Menu.Item key="Partner plan (90/10 with cap)">Partner plan (90/10 with cap)</Menu.Item>
      <Menu.Item key="No Split">No Split</Menu.Item>
      <Menu.Item key="80/20">80/20</Menu.Item>
      <Menu.Item key="90/10">90/10</Menu.Item>
      <Menu.Item key="95/5">95/5</Menu.Item>
      <Menu.Item key="92/8">92/8</Menu.Item>
      <Menu.Item key="Flat Fee">Flat Fee</Menu.Item>
      <Menu.Item key="All">All</Menu.Item>
    </Menu>
  );

  // const capItems = (
  //   <Menu onClick={handleCapSelect}>
  //     <Menu.Item key="team_lead">Flagship partner plan (95/5 until $500K, 97/3 after)</Menu.Item>
  //     <Menu.Item key="team_member">Partner plan (90/10 with cap)</Menu.Item>
  //     <Menu.Item key="individual_agent">No Split</Menu.Item>
  //     <Menu.Item key="All">80/20</Menu.Item>
  //     {/* <Menu.Item key="All">90/10</Menu.Item>
  //     <Menu.Item key="All">95/5</Menu.Item>
  //     <Menu.Item key="All">92/8</Menu.Item>
  //     <Menu.Item key="All">Flat Fee</Menu.Item> */}
  //   </Menu>
  // );

  

  // const getAddOnName = (key) => {
  //   if (key === "kvcore_and_agent_website") return "CRM";
  //   if (key === "moxi_or_active_pipe") return "Moxi/Active Pipe";
  //   if (key === "custom_logo") return "Custom logo";
  // };

  const allAccountColumns = [
    {
      title: "Account name",
      dataIndex: "accountName",
      key: "accountName",
      width: 220,
    },
    { title: "Team Size", dataIndex: "teamSize", key: "teamSize", width: 120 },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      key: "createdDate",
      align: "center",
      width: 180,
      sorter: true,
      sortDirections: ["ascend", "descend"],
      onHeaderCell: (column) => ({
        onClick: () => {
          setAccountsSortConfig((prevConfig) => {
            const isCurrentlySorted = prevConfig.key === "createdDate";
            const newOrder =
              isCurrentlySorted && prevConfig.order === "ASC"
                ? "DESC"
                : prevConfig.order === "DESC"
                ? null
                : "ASC";
            handleSortForAccounts(
              sortFunctionKeysForAccounts(column.key),
              newOrder
            );
            return { key: "createdDate", order: newOrder };
          });
        },
      }),
    },
    {
      title: "Brokerage Join Date",
      dataIndex: "brokerageDate",
      key: "brokerageDate",
      align: "center",
      width: 180,
      sorter: true,
      sortDirections: ["ascend", "descend"],
      onHeaderCell: (column) => ({
        onClick: () => {
          setAccountsSortConfig((prevConfig) => {
            const isCurrentlySorted = prevConfig.key === column.key;
            const newOrder =
              isCurrentlySorted && prevConfig.order === "ASC"
                ? "DESC"
                : prevConfig.order === "DESC"
                ? null
                : "ASC";
            handleSortForAccounts(
              sortFunctionKeysForAccounts(column.key),
              newOrder
            );
            return { key: column.key, order: newOrder };
          });
        },
      }),
    },
    {
      title: "Churned Date",
      dataIndex: "churnedDate",
      key: "churnedDate",
      align: "center",
      width: 180,
      sorter: true,
      sortDirections: ["ascend", "descend"],
      onHeaderCell: (column) => ({
        onClick: () => {
          setAccountsSortConfig((prevConfig) => {
            const isCurrentlySorted = prevConfig.key === column.key;
            const newOrder =
              isCurrentlySorted && prevConfig.order === "ASC"
                ? "DESC"
                : prevConfig.order === "DESC"
                ? null
                : "ASC";
            handleSortForAccounts(
              sortFunctionKeysForAccounts(column.key),
              newOrder
            );
            return { key: column.key, order: newOrder };
          });
        },
      }),
    },
    {
      title: "Commission Split",
      dataIndex: "commissionSplit",
      key: "commissionSplit",
      align: "center",
      sorter: true,
      sortDirections: ["ascend", "descend"],
      onHeaderCell: (column) => ({
        onClick: () => {
          setAccountsSortConfig((prevConfig) => {
            const isCurrentlySorted = prevConfig.key === column.key;
            const newOrder =
              isCurrentlySorted && prevConfig.order === "ASC"
                ? "DESC"
                : prevConfig.order === "DESC"
                ? null
                : "ASC";
            handleSortForAccounts(
              sortFunctionKeysForAccounts(column.key),
              newOrder
            );
            return { key: column.key, order: newOrder };
          });
        },
      }),
    },
    {
      title: "Deals closed",
      dataIndex: "dealsClosed",
      key: "dealsClosed",
      align: "center",
      sorter: true,
      sortDirections: ["ascend", "descend"],
      onHeaderCell: (column) => ({
        onClick: () => {
          setAccountsSortConfig((prevConfig) => {
            const isCurrentlySorted = prevConfig.key === column.key;
            const newOrder =
              isCurrentlySorted && prevConfig.order === "ASC"
                ? "DESC"
                : prevConfig.order === "DESC"
                ? null
                : "ASC";
            handleSortForAccounts(
              sortFunctionKeysForAccounts(column.key),
              newOrder
            );
            return { key: column.key, order: newOrder };
          });
        },
      }),
    },
    {
      title: "Deals in contract",
      dataIndex: "dealsInContract",
      key: "dealsInContract",
      align: "center",
      sorter: true,
      sortDirections: ["ascend", "descend"],
      onHeaderCell: (column) => ({
        onClick: () => {
          setAccountsSortConfig((prevConfig) => {
            const isCurrentlySorted = prevConfig.key === column.key;
            const newOrder =
              isCurrentlySorted && prevConfig.order === "ASC"
                ? "DESC"
                : prevConfig.order === "DESC"
                ? null
                : "ASC";
            handleSortForAccounts(
              sortFunctionKeysForAccounts(column.key),
              newOrder
            );
            return { key: column.key, order: newOrder };
          });
        },
      }),
    },
    {
      title: "Listings Active",
      dataIndex: "listingsActive",
      key: "listingsActive",
      align: "center",
      sorter: true,
      sortDirections: ["ascend", "descend"],
      onHeaderCell: (column) => ({
        onClick: () => {
          setAccountsSortConfig((prevConfig) => {
            const isCurrentlySorted = prevConfig.key === column.key;
            const newOrder =
              isCurrentlySorted && prevConfig.order === "ASC"
                ? "DESC"
                : prevConfig.order === "DESC"
                ? null
                : "ASC";
            handleSortForAccounts(
              sortFunctionKeysForAccounts(column.key),
              newOrder
            );
            return { key: column.key, order: newOrder };
          });
        },
      }),
    },
    {
      title: "Total Production",
      dataIndex: "totalProduction",
      key: "totalProduction",
      align: "center",
      sorter: true,
      sortDirections: ["ascend", "descend"],
      onHeaderCell: (column) => ({
        onClick: () => {
          setAccountsSortConfig((prevConfig) => {
            const isCurrentlySorted = prevConfig.key === column.key;
            const newOrder =
              isCurrentlySorted && prevConfig.order === "ASC"
                ? "DESC"
                : prevConfig.order === "DESC"
                ? null
                : "ASC";
            handleSortForAccounts(
              sortFunctionKeysForAccounts(column.key),
              newOrder
            );
            return { key: column.key, order: newOrder };
          });
        },
      }),
    },
    {
      title: "Last 12 months production",
      dataIndex: "last12MonthsProduction",
      key: "last12MonthsProduction",
      align: "center",
      sorter: true,
      sortDirections: ["ascend", "descend"],
      onHeaderCell: (column) => ({
        onClick: () => {
          setAccountsSortConfig((prevConfig) => {
            const isCurrentlySorted = prevConfig.key === column.key;
            const newOrder =
              isCurrentlySorted && prevConfig.order === "ASC"
                ? "DESC"
                : prevConfig.order === "DESC"
                ? null
                : "ASC";
            handleSortForAccounts(
              sortFunctionKeysForAccounts(column.key),
              newOrder
            );
            return { key: column.key, order: newOrder };
          });
        },
      }),
    },
    {
      title: "Total Gross Commission",
      dataIndex: "totalGrossCommission",
      key: "totalGrossCommission",
      align: "center",
      sorter: true,
      sortDirections: ["ascend", "descend"],
      onHeaderCell: (column) => ({
        onClick: () => {
          setAccountsSortConfig((prevConfig) => {
            const isCurrentlySorted = prevConfig.key === column.key;
            const newOrder =
              isCurrentlySorted && prevConfig.order === "ASC"
                ? "DESC"
                : prevConfig.order === "DESC"
                ? null
                : "ASC";
            handleSortForAccounts(
              sortFunctionKeysForAccounts(column.key),
              newOrder
            );
            return { key: column.key, order: newOrder };
          });
        },
      }),
    },
    {
      title: "Total Agent Commission",
      dataIndex: "totalAgentCommission",
      key: "totalAgentCommission",
      align: "center",
      sorter: true,
      sortDirections: ["ascend", "descend"],
      onHeaderCell: (column) => ({
        onClick: () => {
          setAccountsSortConfig((prevConfig) => {
            const isCurrentlySorted = prevConfig.key === column.key;
            const newOrder =
              isCurrentlySorted && prevConfig.order === "ASC"
                ? "DESC"
                : prevConfig.order === "DESC"
                ? null
                : "ASC";
            handleSortForAccounts(
              sortFunctionKeysForAccounts(column.key),
              newOrder
            );
            return { key: column.key, order: newOrder };
          });
        },
      }),
    },
    {
      title: "Total Radius Transaction Revenue",
      dataIndex: "totalRadiusTransactionRevenue",
      key: "totalRadiusTransactionRevenue",
      align: "center",
      sorter: true,
      sortDirections: ["ascend", "descend"],
      onHeaderCell: (column) => ({
        onClick: () => {
          setAccountsSortConfig((prevConfig) => {
            const isCurrentlySorted = prevConfig.key === column.key;
            const newOrder =
              isCurrentlySorted && prevConfig.order === "ASC"
                ? "DESC"
                : prevConfig.order === "DESC"
                ? null
                : "ASC";
            handleSortForAccounts(
              sortFunctionKeysForAccounts(column.key),
              newOrder
            );
            return { key: column.key, order: newOrder };
          });
        },
      }),
    },
  ];

  const allAgentColumns = [
    {
      title: "Agent name",
      dataIndex: "agentName",
      key: "agentName",
      width: 250,
    },
    {
      title: "Account name",
      dataIndex: "accountName",
      key: "accountName",
      width: 220,
    },
    { title: "Role", dataIndex: "role", key: "role", width: 180 },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      key: "createdDate",
      align: "center",
      width: 180,
      sorter: true,
      sortDirections: ["ascend", "descend"],
      onHeaderCell: (column) => ({
        onClick: () => {
          setSortConfig((prevConfig) => {
            const isCurrentlySorted = prevConfig.key === "createdDate";
            const newOrder =
              isCurrentlySorted && prevConfig.order === "ASC"
                ? "DESC"
                : prevConfig.order === "DESC"
                ? null
                : "ASC";
            handleSortForAgents(
              sortFunctionKeysForAgents(column.key),
              newOrder
            );
            return { key: "createdDate", order: newOrder };
          });
        },
      }),
    },
    {
      title: "Brokerage Join Date",
      dataIndex: "brokerageDate",
      key: "brokerageDate",
      align: "center",
      width: 180,
      sorter: true,
      sortDirections: ["ascend", "descend"],
      onHeaderCell: (column) => ({
        onClick: () => {
          setSortConfig((prevConfig) => {
            const isCurrentlySorted = prevConfig.key === column.key;
            const newOrder =
              isCurrentlySorted && prevConfig.order === "ASC"
                ? "DESC"
                : prevConfig.order === "DESC"
                ? null
                : "ASC";
            handleSortForAgents(
              sortFunctionKeysForAgents(column.key),
              newOrder
            );
            return { key: column.key, order: newOrder };
          });
        },
      }),
    },
    {
      title: "Churned Date",
      dataIndex: "churnedDate",
      key: "churnedDate",
      align: "center",
      width: 180,
      sorter: true,
      sortDirections: ["ascend", "descend"],
      onHeaderCell: (column) => ({
        onClick: () => {
          setSortConfig((prevConfig) => {
            const isCurrentlySorted = prevConfig.key === column.key;
            const newOrder =
              isCurrentlySorted && prevConfig.order === "ASC"
                ? "DESC"
                : prevConfig.order === "DESC"
                ? null
                : "ASC";
            handleSortForAgents(
              sortFunctionKeysForAgents(column.key),
              newOrder
            );
            return { key: column.key, order: newOrder };
          });
        },
      }),
    },
    {
      title: "Deals closed",
      dataIndex: "dealsClosed",
      key: "dealsClosed",
      align: "center",
      sorter: true,
      sortDirections: ["ascend", "descend"],
      onHeaderCell: (column) => ({
        onClick: () => {
          setSortConfig((prevConfig) => {
            const isCurrentlySorted = prevConfig.key === column.key;
            const newOrder =
              isCurrentlySorted && prevConfig.order === "ASC"
                ? "DESC"
                : prevConfig.order === "DESC"
                ? null
                : "ASC";
            handleSortForAgents(
              sortFunctionKeysForAgents(column.key),
              newOrder
            );
            return { key: column.key, order: newOrder };
          });
        },
      }),
    },
    {
      title: "Deals in contract",
      dataIndex: "dealsInContract",
      key: "dealsInContract",
      align: "center",
      sorter: true,
      sortDirections: ["ascend", "descend"],
      onHeaderCell: (column) => ({
        onClick: () => {
          setSortConfig((prevConfig) => {
            const isCurrentlySorted = prevConfig.key === column.key;
            const newOrder =
              isCurrentlySorted && prevConfig.order === "ASC"
                ? "DESC"
                : prevConfig.order === "DESC"
                ? null
                : "ASC";
            handleSortForAgents(
              sortFunctionKeysForAgents(column.key),
              newOrder
            );
            return { key: column.key, order: newOrder };
          });
        },
      }),
    },
    {
      title: "Listings Active",
      dataIndex: "listingsActive",
      key: "listingsActive",
      align: "center",
      sorter: true,
      sortDirections: ["ascend", "descend"],
      onHeaderCell: (column) => ({
        onClick: () => {
          setSortConfig((prevConfig) => {
            const isCurrentlySorted = prevConfig.key === column.key;
            const newOrder =
              isCurrentlySorted && prevConfig.order === "ASC"
                ? "DESC"
                : prevConfig.order === "DESC"
                ? null
                : "ASC";
            handleSortForAgents(
              sortFunctionKeysForAgents(column.key),
              newOrder
            );
            return { key: column.key, order: newOrder };
          });
        },
      }),
    },
    {
      title: "Cap",
      dataIndex: "cap",
      key: "cap",
      align: "center",
      sorter: true,
      sortDirections: ["ascend", "descend"],
      onHeaderCell: (column) => ({
        onClick: () => {
          setSortConfig((prevConfig) => {
            const isCurrentlySorted = prevConfig.key === column.key;
            const newOrder =
              isCurrentlySorted && prevConfig.order === "ASC"
                ? "DESC"
                : prevConfig.order === "DESC"
                ? null
                : "ASC";
            handleSortForAgents(
              sortFunctionKeysForAgents(column.key),
              newOrder
            );
            return { key: column.key, order: newOrder };
          });
        },
      }),
    },
    {
      title: "Total Production",
      dataIndex: "totalProduction",
      key: "totalProduction",
      align: "center",
      sorter: true,
      sortDirections: ["ascend", "descend"],
      onHeaderCell: (column) => ({
        onClick: () => {
          setSortConfig((prevConfig) => {
            const isCurrentlySorted = prevConfig.key === column.key;
            const newOrder =
              isCurrentlySorted && prevConfig.order === "ASC"
                ? "DESC"
                : prevConfig.order === "DESC"
                ? null
                : "ASC";
            handleSortForAgents(
              sortFunctionKeysForAgents(column.key),
              newOrder
            );
            return { key: column.key, order: newOrder };
          });
        },
      }),
    },
    {
      title: "Last 12 months production",
      dataIndex: "last12MonthsProduction",
      key: "last12MonthsProduction",
      align: "center",
      sorter: true,
      sortDirections: ["ascend", "descend"],
      onHeaderCell: (column) => ({
        onClick: () => {
          setSortConfig((prevConfig) => {
            const isCurrentlySorted = prevConfig.key === column.key;
            const newOrder =
              isCurrentlySorted && prevConfig.order === "ASC"
                ? "DESC"
                : prevConfig.order === "DESC"
                ? null
                : "ASC";
            handleSortForAgents(
              sortFunctionKeysForAgents(column.key),
              newOrder
            );
            return { key: column.key, order: newOrder };
          });
        },
      }),
    },
    {
      title: "Total Gross Commission",
      dataIndex: "totalGrossCommission",
      key: "totalGrossCommission",
      align: "center",
      sorter: true,
      sortDirections: ["ascend", "descend"],
      onHeaderCell: (column) => ({
        onClick: () => {
          setSortConfig((prevConfig) => {
            const isCurrentlySorted = prevConfig.key === column.key;
            const newOrder =
              isCurrentlySorted && prevConfig.order === "ASC"
                ? "DESC"
                : prevConfig.order === "DESC"
                ? null
                : "ASC";
            handleSortForAgents(
              sortFunctionKeysForAgents(column.key),
              newOrder
            );
            return { key: column.key, order: newOrder };
          });
        },
      }),
    },
    {
      title: "Total Agent Commission",
      dataIndex: "totalAgentCommission",
      key: "totalAgentCommission",
      align: "center",
      sorter: true,
      sortDirections: ["ascend", "descend"],
      onHeaderCell: (column) => ({
        onClick: () => {
          setSortConfig((prevConfig) => {
            const isCurrentlySorted = prevConfig.key === column.key;
            const newOrder =
              isCurrentlySorted && prevConfig.order === "ASC"
                ? "DESC"
                : prevConfig.order === "DESC"
                ? null
                : "ASC";
            handleSortForAgents(
              sortFunctionKeysForAgents(column.key),
              newOrder
            );
            return { key: column.key, order: newOrder };
          });
        },
      }),
    },
    {
      title: "Total Radius Transaction Revenue",
      dataIndex: "totalRadiusTransactionRevenue",
      key: "totalRadiusTransactionRevenue",
      align: "center",
      sorter: true,
      sortDirections: ["ascend", "descend"],
      onHeaderCell: (column) => ({
        onClick: () => {
          setSortConfig((prevConfig) => {
            const isCurrentlySorted = prevConfig.key === column.key;
            const newOrder =
              isCurrentlySorted && prevConfig.order === "ASC"
                ? "DESC"
                : prevConfig.order === "DESC"
                ? null
                : "ASC";
            handleSortForAgents(
              sortFunctionKeysForAgents(column.key),
              newOrder
            );
            return { key: column.key, order: newOrder };
          });
        },
      }),
    },
    {
      title: "Addition AR collected",
      dataIndex: "additionARCollected",
      key: "additionARCollected",
      align: "center",
      sorter: true,
      sortDirections: ["ascend", "descend"],
      onHeaderCell: (column) => ({
        onClick: () => {
          setSortConfig((prevConfig) => {
            const isCurrentlySorted = prevConfig.key === column.key;
            const newOrder =
              isCurrentlySorted && prevConfig.order === "ASC"
                ? "DESC"
                : prevConfig.order === "DESC"
                ? null
                : "ASC";
            handleSortForAgents(
              sortFunctionKeysForAgents(column.key),
              newOrder
            );
            return { key: column.key, order: newOrder };
          });
        },
      }),
    },
    {
      title: "Accounts Receivable",
      dataIndex: "accountsReceivable",
      key: "accountsReceivable",
      align: "center",
      sorter: true,
      width: 200,
      sortDirections: ["ascend", "descend"],
      onHeaderCell: (column) => ({
        onClick: () => {
          setSortConfig((prevConfig) => {
            const isCurrentlySorted = prevConfig.key === column.key;
            const newOrder =
              isCurrentlySorted && prevConfig.order === "ASC"
                ? "DESC"
                : prevConfig.order === "DESC"
                ? null
                : "ASC";
            handleSortForAgents(
              sortFunctionKeysForAgents(column.key),
              newOrder
            );
            return { key: column.key, order: newOrder };
          });
        },
      }),
    },
    {
      title: "Total Monthly Subscription",
      dataIndex: "totalMonthlyCost",
      key: "totalMonthlyCost",
      align: "center",
      width: 220,
    },
    {
      title: "Brokerage Plans",
      dataIndex: "brokeragePlans",
      key: "brokeragePlans",
      align: "center",
      width: 220,
    },
    { title: "E&O", dataIndex: "eo", key: "eo", align: "center", width: 220 },
    {
      title: "Add ons",
      dataIndex: "addOns",
      key: "addOns",
      align: "center",
      width: 220,
    },
    {
      title: "W9 link",
      dataIndex: "w9Link",
      key: "w9Link",
      align: "center",
      width: 160,
    },
    // { title: "AI credits paid", dataIndex: "aiCreditsPaid", key: "aiCreditsPaid", align: "center",width:180 },
    // { title: "AI credits available", dataIndex: "aiCreditsAvailable", key: "aiCreditsAvailable", align: "center" ,width:180},
    // { title: "AI credits consumed", dataIndex: "aiCreditsConsumed", key: "aiCreditsConsumed", align: "center",width:180 },
    // { title: "Client app downloads", dataIndex: "clientAppDownloads", key: "clientAppDownloads", align: "center",width:180 },
    // { title: "Office app downloads", dataIndex: "officeAppDownloads", key: "officeAppDownloads", align: "center",width:180 },
  ];

  const allSaasAccountColumns = [
    {
      title: "Account name",
      dataIndex: "accountName",
      key: "accountName",
      width: 220,
    },
    { title: "Team Size", dataIndex: "teamSize", key: "teamSize", width: 120 },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      key: "createdDate",
      align: "center",
      width: 180,
      sorter: true,
      sortDirections: ["ascend", "descend"],
      onHeaderCell: (column) => ({
        onClick: () => {
          setSaasAccountsSortConfig((prevConfig) => {
            const isCurrentlySorted = prevConfig.key === "createdDate";
            const newOrder =
              isCurrentlySorted && prevConfig.order === "ASC"
                ? "DESC"
                : prevConfig.order === "DESC"
                ? null
                : "ASC";
            handleSortForSaasAccounts(
              sortFunctionKeysForSaasAccounts(column.key),
              newOrder
            );
            return { key: "createdDate", order: newOrder };
          });
        },
      }),
    },
    {
      title: "Susbscription Start Date",
      dataIndex: "subsDate",
      key: "subsDate",
      align: "center",
      width: 280,
      sorter: true,
      sortDirections: ["ascend", "descend"],
      onHeaderCell: (column) => ({
        onClick: () => {
          setSaasAccountsSortConfig((prevConfig) => {
            const isCurrentlySorted = prevConfig.key === column.key;
            const newOrder =
              isCurrentlySorted && prevConfig.order === "ASC"
                ? "DESC"
                : prevConfig.order === "DESC"
                ? null
                : "ASC";
            handleSortForSaasAccounts(
              sortFunctionKeysForSaasAccounts(column.key),
              newOrder
            );
            return { key: column.key, order: newOrder };
          });
        },
      }),
    },
    {
      title: "Churned Date",
      dataIndex: "churnedDate",
      key: "churnedDate",
      align: "center",
      width: 180,
      sorter: true,
      sortDirections: ["ascend", "descend"],
      onHeaderCell: (column) => ({
        onClick: () => {
          setSaasAccountsSortConfig((prevConfig) => {
            const isCurrentlySorted = prevConfig.key === column.key;
            const newOrder =
              isCurrentlySorted && prevConfig.order === "ASC"
                ? "DESC"
                : prevConfig.order === "DESC"
                ? null
                : "ASC";
            handleSortForSaasAccounts(
              sortFunctionKeysForSaasAccounts(column.key),
              newOrder
            );
            return { key: column.key, order: newOrder };
          });
        },
      }),
    },
    {
      title: "AI Credits Paid",
      dataIndex: "aiCreditsPaid",
      key: "aiCreditsPaid",
      align: "center",
      width: 220,
      sorter: true,
      sortDirections: ["ascend", "descend"],
      onHeaderCell: (column) => ({
        onClick: () => {
          setSaasAccountsSortConfig((prevConfig) => {
            const isCurrentlySorted = prevConfig.key === column.key;
            const newOrder =
              isCurrentlySorted && prevConfig.order === "ASC"
                ? "DESC"
                : prevConfig.order === "DESC"
                ? null
                : "ASC";
            handleSortForSaasAccounts(
              sortFunctionKeysForSaasAccounts(column.key),
              newOrder
            );
            return { key: column.key, order: newOrder };
          });
        },
      }),
    },
    {
      title: "AI Credits Available",
      dataIndex: "aiCreditsAvailable",
      key: "aiCreditsAvailable",
      align: "center",
      width: 220,
      sorter: true,
      sortDirections: ["ascend", "descend"],
      onHeaderCell: (column) => ({
        onClick: () => {
          setSaasAccountsSortConfig((prevConfig) => {
            const isCurrentlySorted = prevConfig.key === column.key;
            const newOrder =
              isCurrentlySorted && prevConfig.order === "ASC"
                ? "DESC"
                : prevConfig.order === "DESC"
                ? null
                : "ASC";
            handleSortForSaasAccounts(
              sortFunctionKeysForSaasAccounts(column.key),
              newOrder
            );
            return { key: column.key, order: newOrder };
          });
        },
      }),
    },

    {
      title: "AI Credits Consumed",
      dataIndex: "aiCreditsConsumed",
      key: "aiCreditsConsumed",
      align: "center",
      width: 220,
      sorter: true,
      sortDirections: ["ascend", "descend"],
      onHeaderCell: (column) => ({
        onClick: () => {
          setSaasAccountsSortConfig((prevConfig) => {
            const isCurrentlySorted = prevConfig.key === column.key;
            const newOrder =
              isCurrentlySorted && prevConfig.order === "ASC"
                ? "DESC"
                : prevConfig.order === "DESC"
                ? null
                : "ASC";
            handleSortForSaasAccounts(
              sortFunctionKeysForSaasAccounts(column.key),
              newOrder
            );
            return { key: column.key, order: newOrder };
          });
        },
      }),
    },
    {
      title: "Client App Logins",
      dataIndex: "clientAppLogins",
      key: "clientAppLogins",
      align: "center",
      sorter: true,
      sortDirections: ["ascend", "descend"],
      onHeaderCell: (column) => ({
        onClick: () => {
          setSaasAccountsSortConfig((prevConfig) => {
            const isCurrentlySorted = prevConfig.key === column.key;
            const newOrder =
              isCurrentlySorted && prevConfig.order === "ASC"
                ? "DESC"
                : prevConfig.order === "DESC"
                ? null
                : "ASC";
            handleSortForSaasAccounts(
              sortFunctionKeysForSaasAccounts(column.key),
              newOrder
            );
            return { key: column.key, order: newOrder };
          });
        },
      }),
    },
  ];

  function formatDate(dateTimeString) {
    if (dateTimeString) {
      return String(dateTimeString).split(' ')[0];
    }
    return "";
  }

  function agentType(agent){
    if(agent==="individual_agent")
      return "Individual Agent";
    if(agent==="associate")
      return "Associate";
    if(agent==="admin")
      return "Team Lead";
    if(agent==="co_team_lead")
      return "Co Team Lead";
    if(agent==="team_tc")
      return "Individual Agent";
    if(agent==="assistant")
      return "Assistant";
  }
  const transformedAgentData =
    data?.result?.map((item, index) => ({
      key: item.id || index,
      agentName: `${item.firstname} ${item.lastname}` || "--",
      accountName: item.team_name || "--",
      role: agentType(item.agent_type),
      createdDate: formatDate(item.created_at) || "--",
      brokerageDate: item.ica_signed_date || "--",
      churnedDate: formatDate(item.churned_date) || "--",
      dealsClosed: item.deals_closed,
      dealsInContract: item.deals_in_contract,
      listingsActive: item.active_listings,
      cap: item.cap,
      addOns: (() => {
        try {
          const parsedAddOns = JSON.parse(item.subscribed_add_ons);

          let totalSum = 0;
          const addOnElements = parsedAddOns
            .map((obj, idx) => {
              const key = Object.keys(obj)[0];
              const value = obj[key];

              if (typeof value === "number") {
                totalSum += value;
              }

              return <div key={idx}>{key}</div>;
            })
            .filter(Boolean);

          if (addOnElements.length > 0) {
            addOnElements.push(
              <div key="total">
                <strong>{`$${totalSum} per month`}</strong>
              </div>
            );
          }

          return addOnElements.length > 0 ? addOnElements : "-"; // Return "-" if empty
        } catch (error) {
          return "-";
        }
      })(),
      brokeragePlans: (() => {
        try {
          const parsedBrokeragePlans = JSON.parse(item.brokerage_plan);     

          const parsedBrokeragePlanElements = parsedBrokeragePlans.map((arr) => 
          {
            const {unit_price, billing_cycle}=arr;

              return <div>{`$${unit_price} per ${billing_cycle}`}</div>;
            })
            .filter(Boolean);

          return parsedBrokeragePlanElements.length > 0 ? parsedBrokeragePlanElements : "-"; // Return "-" if empty
        } catch (error) {
          return "-";
        }
      })(),

      eo: (() => {
        try {
          const parsedeo = JSON.parse(item.eo);

          let totalSum = 0;
          const eoElements = parsedeo
            .map((obj, idx) => {
              const key = Object.keys(obj)[0];
              const value = obj[key];

              if (typeof value === "number") {
                totalSum += value;
              }

              return <div key={idx}>{`$${value} per month`}</div>;
            })
            .filter(Boolean);
          return eoElements.length > 0 ? eoElements : "-"; 
        } catch (error) {
          return "-";
        }
      })(),
      totalMonthlyCost: (() => {
        try {
          // Parse add-ons
          const parsedAddOns = JSON.parse(item.subscribed_add_ons) || [];
          const addOnTotal = parsedAddOns.reduce((sum, obj) => {
            const key = Object.keys(obj)[0];
            const value = obj[key];
            return typeof value === "number" ? sum + value : sum;
          }, 0);
      
          // Parse brokerage plans
          const parsedBrokeragePlans = JSON.parse(item.brokerage_plan) || [];
          const brokerageTotal = parsedBrokeragePlans.reduce((sum, plan) => {
            const { unit_price, billing_cycle } = plan;
            return billing_cycle === "MONTHLY" && typeof unit_price === "number" ? sum + unit_price : sum;
          }, 0);
      
          // Parse EO
          const parsedEo = JSON.parse(item.eo);
          // const eoTotal = parsedEo.reduce((sum, obj) => {
          //   const key = Object.keys(obj)[0];
          //   const value = obj[key];
          //   return typeof value === "number" ? sum + value : sum;
          // }, 0);
      
          const totalMonthly = (addOnTotal || 0) + brokerageTotal;
      
          return <strong>{`$${totalMonthly} per month`}</strong>;
        } catch (error) {
          return "-";
        }
      })(),
      totalProduction: item.total_production,
      last12MonthsProduction: item.last_12_months_production,
      totalGrossCommission: item.total_gross_commission,
      totalAgentCommission: item.total_agent_commission,
      totalRadiusTransactionRevenue: item.total_radius_fees,
      // w9Link: item.wd_url || "--",
      w9Link: item.wd_url ? (
        <>
          <a href={item.wd_url} target="_blank">
            Link
          </a>
          <div>
            Uploaded on {new Date(item.wd_updated).toLocaleDateString()}
          </div>
        </>
      ) : (
        "-"
      ),
    })) || [];

    

  const transformedAccountData =
    data?.result?.map((item, index) => ({
      key: item.id || index,
      accountName: item.name || `${item.firstname} ${item.lastname}`,
      teamSize: item.team_size,
      createdDate: formatDate(item?.created_at) || "--",
      brokerageDate: formatDate(item.ica_signed_date)|| "--",
      churnedDate: formatDate(item.churned_date) || "--",
      commissionSplit: item.commission_split,
      dealsClosed: item.deals_closed,
      dealsInContract: item.total_in_contracts,
      listingsActive: item.active_listings,
      totalProduction: item.total_production,
      last12MonthsProduction: item.last_12_months_production,
      totalGrossCommission: item.total_gross_commission,
      totalAgentCommission: item.total_agent_commission,
      totalRadiusTransactionRevenue: item.total_radius_fees,
    })) || [];

  const transformedSaasData =
    data?.result?.map((item, index) => ({
      key: item.user_id || index,
      accountName: item.name,
      teamSize: item.team_size,
      createdDate: formatDate(item?.created_at) || "--",
      subsStartDate: formatDate(item?.created_at) || "--",
      churnedDate: formatDate(item?.churned_at) || "--",
      clientAppLogins: item?.client_app_logins,
      aiCreditsPaid: "--",
      aiCreditsAvailable: "--",
      aiCreditsConsumed: "--",
    })) || [];

  // const fetchMore = () => {
  //   if (activeTab === "1")
  //     getAllAccountsForRosterPage(
  //       createPayloadForAccounts({
  //         isFetchingMore: true,
  //       })
  //     );
  //   if (activeTab === "2")
  //     getAllAgentsForRosterPage(
  //       createPayloadForAgents({
  //         isFetchingMore: true,
  //       })
  //     );
  //   if (activeTab === "3")
  //     getSaasAccountsForRosterPage(
  //       createPayloadForSaasAccounts({
  //         isFetchingMore: true,
  //       })
  //     );
  // };

  useEffect(() => {
    // if (data?.total !== undefined) {
      setTotalCount("500");
    // }
  }, [data]);
  
  useEffect(() => {
    fetchData();
  }, [skip]);
  
  // const fetchData = () => {
  //   const payload = {
  //     ...extra,
  //     limit,
  //     skip,
  //   };
  
  //   if (activeTab === "1") {
  //     getAllAccountsForRosterPage(payload);
  //   } else if (activeTab === "2") {
  //     getAllAgentsForRosterPage(payload);
  //   } else if (activeTab === "3") {
  //     getSaasAccountsForRosterPage(payload);
  //   }
  // };

  const fetchData = () => {
    const pagination = { limit, skip };
  
    if (activeTab === "1") {
      const payload = {
        ...createPayloadForAccounts(),
        limit,skip
      };
      getAllAccountsForRosterPage(payload);
    } else if (activeTab === "2") {
      const payload = {
        ...createPayloadForAgents(),
        limit,skip
      };
      getAllAgentsForRosterPage(payload);
    } else if (activeTab === "3") {
      const payload = {
        ...createPayloadForSaasAccounts(),
        limit,skip
      };
      getSaasAccountsForRosterPage(payload);
    }
  };

  return (
    <FilterWrap>
      <p style={{ fontSize: "28px", fontWeight: "700" }}>
        All Agents DashBoard
      </p>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          gap: "12px",
        }}
      >
        {seeFilters === true && (
          <>
            {activeTab === "2" && (
              <StyledInput
                list="agent-list"
                placeholder="Search by client"
                prefix={<SearchOutlined />}
                width="33.6%"
                value={searchByEmail}
                onChange={(e) => setSearchByEmail(e.target.value)}
              />
            )}

            {searchByEmail && data?.result?.length > 0 && (
              <SuggestionsList>
                {data?.result?.map((agent) => (
                  <SuggestionItem key={agent.id} onClick={{}}>
                    <Avatar
                      src={agent.avatar || "default-avatar.png"}
                      alt={agent.name}
                    />
                    <AgentDetails>
                      <AgentName>{agent.name}</AgentName>
                      <AgentEmail>{agent.email}</AgentEmail>
                    </AgentDetails>
                  </SuggestionItem>
                ))}
              </SuggestionsList>
            )}

            {activeTab === "2" && (
              <StyledInput
                placeholder="Search by Account"
                onChange={(e) => {
                  handlesearchByAccount(e.target.value);
                }}
                value={searchByAccount}
                prefix={<SearchOutlined />}
                width="33.6%"
              />
            )}
            {activeTab === "1" && (
              <StyledInput
                placeholder="Search by Account"
                onChange={(e) => {
                  handlesearchByAccount(e.target.value);
                }}
                prefix={<SearchOutlined />}
                width="68%"
              />
            )}
            {activeTab === "3" && (
              <StyledInput
                placeholder="Search by Account"
                onChange={(e) => {
                  handlesearchByAccount(e.target.value);
                }}
                prefix={<SearchOutlined />}
                width="89%"
              />
            )}
            {activeTab === "2" && (
              <Dropdown overlay={roleitems} trigger={["click"]}>
                <SearchDropdownContainer width="20.4%">
                  <span>{selectedRole || "Select Role"}</span>
                  <DownOutlined />
                </SearchDropdownContainer>
              </Dropdown>
            )}
            {activeTab === "1" && (
              <Dropdown overlay={teamSizeOptions} trigger={["click"]}>
                <SearchDropdownContainer width="20.4%">
                  <span>{selectedTeamsize || "Team Size"}</span>
                  <DownOutlined />
                </SearchDropdownContainer>
              </Dropdown>
            )}
          </>
        )}
        <SearchDropdownContainer
          width="8.8%"
          onClick={() => setSeeFilters((prev) => !prev)}
        >
          <span>Filters</span>
          <DownOutlined />
        </SearchDropdownContainer>

        {seeFilters === true && (
          <>
            <DatePickerContainer width={activeTab === "3" ? "32%" : "24%"}>
              <Label>Brokerage join date</Label>
              <StyledDatePicker
                format="DD / MM / YYYY"
                placeholder="00 / 00 / 0000"
                value={brokerageJoinDate}
                onChange={(value) => setBrokerageJoinDate(value)}
              />
            </DatePickerContainer>
            <DatePickerContainer width={activeTab === "3" ? "32%" : "24%"}>
              <Label>Churned date</Label>
              <StyledDatePicker
                format="DD / MM / YYYY"
                placeholder="00 / 00 / 0000"
                value={churnedDate}
                onChange={setChurnedDate}
              />
            </DatePickerContainer>
            <DatePickerContainer width={activeTab === "3" ? "33%" : "24%"}>
              <Label>Create date</Label>
              <StyledDatePicker
                format="DD / MM / YYYY"
                placeholder="00 / 00 / 0000"
                value={createdDate}
                onChange={setCreatedDate}
              />
            </DatePickerContainer>
            {activeTab !== "3" && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "24.5%",
                  gap: "6px",
                }}
              >
                <Label>Cap</Label>
                <Dropdown overlay={splitItems} trigger={["click"]}>
                  <SearchDropdownContainer width="100%">
                    <span>{selectedSplit || "Commission Split"}</span>
                    <DownOutlined />
                  </SearchDropdownContainer>
                </Dropdown>
                
              </div>
            )}
            {/* {activeTab === "2" && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "24.5%",
                  gap: "6px",
                }}
              >
                <Label>Cap</Label>
                <Dropdown overlay={capItems} trigger={["click"]}>
                  <SearchDropdownContainer width="100%">
                    <span>{capRange || "Select Cap Range"}</span>
                    <DownOutlined />
                  </SearchDropdownContainer>
                </Dropdown>
              </div>
            )} */}
            {activeTab !== "3" && (
              <InputGroup width="24%">
                <Label>Deals closed</Label>
                <InputRow>
                  <StyledCurrencyInput
                    placeholder="Min"
                    value={dealsClosedRange[0]}
                    onChange={(e) =>
                      setDealsClosedRange([e.target.value, dealsClosedRange[1]])
                    }
                    prefix="$"
                  />
                  <StyledCurrencyInput
                    placeholder="Max"
                    value={dealsClosedRange[1]}
                    onChange={(e) =>
                      setDealsClosedRange([dealsClosedRange[0], e.target.value])
                    }
                    prefix="$"
                  />
                </InputRow>
              </InputGroup>
            )}
            {activeTab !== "3" && (
              <InputGroup width="24%">
                <Label>Total Transaction Revenue</Label>
                <InputRow>
                  <StyledCurrencyInput
                    placeholder="Min"
                    value={transactionRevenueRange[0]}
                    onChange={(e) =>
                      setTransactionRevenueRange([
                        e.target.value,
                        transactionRevenueRange[1],
                      ])
                    }
                    prefix="$"
                  />
                  <StyledCurrencyInput
                    placeholder="Max"
                    value={transactionRevenueRange[1]}
                    onChange={(e) =>
                      setTransactionRevenueRange([
                        transactionRevenueRange[0],
                        e.target.value,
                      ])
                    }
                    prefix="$"
                  />
                </InputRow>
              </InputGroup>
            )}
            {activeTab !== "3" && (
              <InputGroup width="24%">
                <Label>Total Production</Label>
                <InputRow>
                  <StyledCurrencyInput
                    placeholder="Min"
                    prefix="$"
                    value={minTotalProductionRange}
                    onChange={(e) => {
                      setMinTotalProductionRange(e.target.value);
                    }}
                  />

                  <StyledCurrencyInput
                    placeholder="Max"
                    prefix="$"
                    value={maxTotalProductionRange}
                    onChange={(e) => {
                      setMaxTotalProductionRange(e.target.value);
                    }}
                  />
                </InputRow>
              </InputGroup>
            )}
            {activeTab !== "3" && (
              <InputGroup width="24.5%">
                <Label>Last 12 months Production</Label>
                <InputRow>
                  <StyledCurrencyInput
                    placeholder="Min"
                    prefix="$"
                    value={last12MonthsProductionRange[0]}
                    onChange={(e) =>
                      setLast12MonthsProductionRange([
                        e.target.value,
                        last12MonthsProductionRange[1],
                      ])
                    }
                  />
                  <StyledCurrencyInput
                    placeholder="Max"
                    prefix="$"
                    value={last12MonthsProductionRange[1]}
                    onChange={(e) =>
                      setLast12MonthsProductionRange([
                        last12MonthsProductionRange[0],
                        e.target.value,
                      ])
                    }
                  />
                </InputRow>
              </InputGroup>
            )}

            {activeTab === "3" && (
              <InputGroup width="32%">
                <Label>All Credits Paid</Label>
                <InputRow>
                  <StyledCurrencyInput
                    placeholder="Min"
                    prefix="$"
                    value={creditPaid[0]}
                    onChange={(e) =>
                      setCreditsPaid([e.target.value, creditPaid[1]])
                    }
                  />
                  <StyledCurrencyInput
                    placeholder="Max"
                    prefix="$"
                    value={creditPaid[1]}
                    onChange={(e) =>
                      setCreditsPaid([creditPaid[0], e.target.value])
                    }
                  />
                </InputRow>
              </InputGroup>
            )}
            {activeTab === "3" && (
              <InputGroup width="32%">
                <Label>All Credits Available</Label>
                <InputRow>
                  <StyledCurrencyInput
                    placeholder="Min"
                    prefix="$"
                    value={creditAvailable[0]}
                    onChange={(e) =>
                      setCreditsAvailable([e.target.value, creditAvailable[1]])
                    }
                  />
                  <StyledCurrencyInput
                    placeholder="Max"
                    prefix="$"
                    value={creditAvailable[1]}
                    onChange={(e) =>
                      setCreditsAvailable([creditAvailable[0], e.target.value])
                    }
                  />
                </InputRow>
              </InputGroup>
            )}
            {activeTab === "3" && (
              <InputGroup width="33%">
                <Label>All Credits Consumed</Label>
                <InputRow>
                  <StyledCurrencyInput
                    placeholder="Min"
                    prefix="$"
                    value={creditConsumed[0]}
                    onChange={(e) =>
                      setCreditsConsumed([e.target.value, creditConsumed[1]])
                    }
                  />
                  <StyledCurrencyInput
                    placeholder="Max"
                    prefix="$"
                    value={creditConsumed[1]}
                    onChange={(e) =>
                      setCreditsConsumed([creditConsumed[0], e.target.value])
                    }
                  />
                </InputRow>
              </InputGroup>
            )}
          </>
        )}
      </div>
      {seeFilters && (
        <ButtonContainer>
          <Button
            type="primary"
            size={"large"}
            style={{
              border: "1px solid #6366F1",
              borderRadius: "8px",
              color: "white",
              backgroundColor: "#6366F1",
            }}
            onClick={() => setSearch((prev) => !prev)}
          >
            Apply Filters
          </Button>
          <Button
            size={"large"}
            style={{
              border: "1px solid red",
              borderRadius: "8px",
              color: "red",
            }}
            onClick={handleClearFilter}
          >
            Clear Filters
          </Button>
          <Button
            size={"large"}
            style={{ border: "1px solid #E5E7EB", borderRadius: "8px" }}
            onClick={handleDownload}
          >
            Export to CSV
          </Button>
        </ButtonContainer>
      )}
      {seeFilters && (
        <Divider
          type="horizontal"
          style={{ padding: "0px", marginTop: "0px" }}
        />
      )}
{/* 
      <InfiniteScroll
        hasMore={!isFetchingMore && data && !data.isDone}
        loadMore={throttle(() => {
          fetchMore();
        }, 4000)}
        threshold={250}
      > */}
        {activeTab === "2" && (
          <Table
            columns={allAgentColumns}
            dataSource={transformedAgentData}
            pagination={false}
            bordered
            scroll={{ x: "max-content" }}
          />
        )}
        {activeTab === "1" && (
          <Table
            columns={allAccountColumns}
            dataSource={transformedAccountData}
            pagination={false}
            bordered
            scroll={{ x: "max-content" }}
          />
        )}
        {activeTab === "3" && (
          <Table
            columns={allSaasAccountColumns}
            dataSource={transformedSaasData}
            pagination={false}
            bordered
            scroll={{ x: "max-content" }}
          />
        )}
      {/* </InfiniteScroll> */}
      <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-end",gap:"15px", marginTop: "1rem", marginBottom:"10px"}}>
      <p style={{marginTop: "3px"}}>
  Showing {skip + 1} – {Math.min(skip + limit, totalCount)} of {totalCount}
</p><div style={{ display: "flex", justifyContent: "flex-end" }}>
  <Button
    disabled={skip === 0}
    onClick={() => setSkip(skip - limit)}
    style={{ marginRight: 8 }}
  >
    Previous
  </Button>
  <Button
    disabled={skip + limit >= totalCount}
    onClick={() => setSkip(skip + limit)}
  >
    Next
  </Button>
</div>
</div>

    </FilterWrap>
  );
};

// export default FilterBoard;
export default teamContainer(agentSearchContainer(container(FilterBoard)));
