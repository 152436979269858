import React from "react";
import styled from "styled-components";
import { Table, Divider } from "antd";






const CustomTable = ({data}) => {
  
  const handleSort = (order) => {
    console.log(order,"lll")
    if (order === "ascend") {
      console.log("high");
    } else if (order === "descend") {
      console.log("low");
    }
    // setSortOrder(order);
  };

  const columns = [
    { title: "Agent name", dataIndex: "agentName", key: "agentName", width:250},
    { title: "Account name", dataIndex: "accountName", key: "accountName", width:220},
    { title: "Role", dataIndex: "role", key: "role", width:120},
    { title: "Created Date", dataIndex: "createdDate", key: "createdDate", align: "center", width:180, sorter: (order) => {handleSort(order)}, sortDirections: ['ascend','descend'], onHeaderCell: (column) => ({
      onClick: () => {
        const newOrder = column.sortOrder === "ascend" ? "descend" : "ascend";
        handleSort(newOrder);
      }
    })
  },
    { title: "Brokerage Join Date", dataIndex: "brokerageDate", key: "brokerageDate", align: "center", width:180, sorter: () => {}, sortDirections: ['ascend','descend']},
    { title: "Churned Date", dataIndex: "churnedDate", key: "churnedDate", align: "center",width:180, sorter: () => {}, sortDirections: ['ascend','descend']},
    { title: "Deals closed", dataIndex: "dealsClosed", key: "dealsClosed", align: "center", sorter: () => {}, sortDirections: ['ascend','descend']},
    { title: "Deals in contract", dataIndex: "dealsInContract", key: "dealsInContract", align: "center", sorter: () => {}, sortDirections: ['ascend','descend']},
    { title: "Listings Active", dataIndex: "listingsActive", key: "listingsActive", align: "center", sorter: () => {}, sortDirections: ['ascend','descend']},
    { title: "Cap", dataIndex: "cap", key: "cap", align: "center", sorter: () => {}, sortDirections: ['ascend','descend']},
    { title: "Total Production", dataIndex: "totalProduction", key: "totalProduction", align: "center", sorter: () => {}, sortDirections: ['ascend','descend']},
    { title: "Last 12 months production", dataIndex: "last12MonthsProduction", key: "last12MonthsProduction", align: "center", sorter: () => {}, sortDirections: ['ascend','descend']},
    { title: "Total Gross Commission", dataIndex: "totalGrossCommission", key: "totalGrossCommission", align: "center", sorter: () => {}, sortDirections: ['ascend','descend']},
    { title: "Total Agent Commission", dataIndex: "totalAgentCommission", key: "totalAgentCommission", align: "center", sorter: () => {}, sortDirections: ['ascend','descend']},
    { title:"Total Radius Transaction Revenue", dataIndex: "totalRadiusTransactionRevenue", key: "totalRadiusTransactionRevenue", align: "center", sorter: () => {}, sortDirections: ['ascend','descend']},
    { title:"Addition AR collected", dataIndex: "additionARCollected", key: "additionARCollected", align: "center", sorter: () => {}, sortDirections: ['ascend','descend']},
    { title:"Accounts Receivable", dataIndex: "accountsReceivable", key: "accountsReceivable", align: "center", sorter: () => {}, sortDirections: ['ascend','descend']},
    { title:"Total Monthly Subscription", dataIndex: "totalMonthlySubscription", key: "totalMonthlySubscription", align: "center" },
    { title:"Brokerage Plans", dataIndex: "brokeragePlans", key: "brokeragePlans", align: "center" },
    { title:"E&O", dataIndex: "e&o", key: "e&o", align: "center" },
    { title:"Add ons", dataIndex: "addOns", key: "addOns", align: "center" },
    { title:"W9 link", dataIndex: "w9Link", key: "w9Link", align: "center" },
    // { title: "AI credits paid", dataIndex: "aiCreditsPaid", key: "aiCreditsPaid", align: "center",width:180 },
    // { title: "AI credits available", dataIndex: "aiCreditsAvailable", key: "aiCreditsAvailable", align: "center" ,width:180},
    // { title: "AI credits consumed", dataIndex: "aiCreditsConsumed", key: "aiCreditsConsumed", align: "center",width:180 },
    // { title: "Client app downloads", dataIndex: "clientAppDownloads", key: "clientAppDownloads", align: "center",width:180 },
    // { title: "Office app downloads", dataIndex: "officeAppDownloads", key: "officeAppDownloads", align: "center",width:180 },
  ];

  const transformedData = data?.map((item, index) => ({
    key: item.id || index, // Unique key for each row
    agentName: `${item.firstname} ${item.lastname}` || "N/A",
    accountName: item.team_name, 
    role: item.agent_type, 
    createdDate: item.created_at || "N/A",
    brokerageDate: item.ica_signed_date || "N/A",
    churnedDate: item.churned_date || "N/A",
    dealsClosed: item.deals_closed,
    dealsInContract: item.deals_in_contract,
    listingsActive: item.active_listings,
    cap: item.cap,
    totalProduction: item.total_production,
    last12MonthsProduction: item.last_12_months_production,
    totalGrossCommission : item.total_gross_commission,
    totalAgentCommission : item.total_agent_commission,
    totalRadiusTransactionRevenue : item.total_radius_fees,
    w9Link: item.wd_url || "N/A",
    teamSize: item.teamSize || "N/A",

    
    // aiCreditsPaid: item.aiCreditsPaid || "N/A",
    // aiCreditsAvailable: item.aiCreditsAvailable || "N/A",
    // aiCreditsConsumed: item.aiCreditsConsumed || "N/A",
    // clientAppDownloads: item.clientAppDownloads || "N/A",
    // officeAppDownloads: item.officeAppDownloads || "N/A",
    // dealsClosed: item.dealsClosed || "N/A",
  })) || [];
  return (
      <Table columns={columns} dataSource={transformedData} pagination={false} bordered scroll={{ x: "max-content" }} />
  );
};

export default CustomTable;
