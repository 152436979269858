import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import {
  fetchUserReferralSettingsAction,
} from "container/Agent/actions"
import { getTCListMapped } from "container/BrokerDashboard/selector"
import {
  getAllDealsListingActions,
  createDealAction,
  tcOverviewAction,
  tcDataDownloadAction,
  getAssetsDashboardAction,
  createNewGlobalAssetAction,
  getAgentForRosterPageAction,
  getAgentYearEndWrapAction,
  getAgentPipelinePageAction,
  getReferralSourcesAction,
  updateAssetsFormLinksAction,
  deleteGlobalAssetAction,
  searchClientByText,
  getSecureIdForTCAction,
  getFileForTCAction,
  saveStepsForProductAction,
  updateUserSettingsAction,
  sortTCListAction,
  updateCommsionDemandAction,
  deleteCommissionDemandActions,
  getTCListAction,
  updateTCAgentAction,
  updateAgentOptedTCAction,
  getTCAgentAssignedAction,
  rosterPageDataDownloadAction,
  allAgentsDataDownloadAction,
  allAccountsDataDownloadAction,
  allSaasAccountsDataDownloadAction,
  sendSlackDealStatusChangeAction,
  showTCLoaderActions,
  getDealStatusTimelineAction,
  toggleTimelineDrawerAction,
  toggleModalViewAction,
  getListingCoverSheet,
  getContractsDetails,
  createListingCoverSheet,
  updateListingCoverSheet,
  uploadSellerProfileImageAction,
  setDealToView,
  setTotalListingCount,
  setLoaderOnAgentInfo,
  setEscrowIdToEdit,
  setDealAgentId,
  toggleEscrowDrawerAction,
  toggleUploadImageDrawerAction,
  setDealIdToUploadAction,
  uploadDocsForListingAction,
  resetListingImages,
  resetDealId,
  getVendorDetailsAction,
  setEscrowEdit,
  setAllVendors,
  setAllListingImagesAction,
  createAllListingImagesAction,
  createVendorDetailsAction,
  updateVendorDetailsAction,
  getContractStatusAction,
  selectVendorDetailsAction,
  saveFileTypeChangeAction,
  setSelectedId,
  resetListings,
  fetchDealTimelineAction,
  updateDealTimelineAction,
  toggleTimelineChecklistAction,
  setDealIdTimelineAction,
  setRadiusFeeAction,
  uploadCSVToS3Action,
  uploadS3LocationAction,
  //change for listing
  tcOverviewListingAction,
  saveListingStepsAction,
  setListingStateAction,
  getListingTypeAction,
  setIsCreatingDealAction,
  uploadW2FormAction,
  deleteW2FormAction,
  getAllAgentForRosterPageAction,
  getAllAccountForRosterPageAction,
  getAllSaasAccountsAction
} from "./actions"

function mapStateToProps(state) {
  const { brokerDashboard, agent } = state
  const { preferences } = agent
  const {
    dealsListingResponse,
    globalAssetsResponse,
    tcOverviewResponse,
    tcDataDownloadResponse,
    rosterPageDataDownloadResponse,
    allAgentsDataDownloadResponse,
    allSaasAccountsDataDownloadAction,
    allAccountsDataDownloadAction,
    rosterPageAgentsResponse,
    rosterPageAllAgentsResponse,
    rosterPageAllAccountsResponse,
    rosterPageSaasAccountsResponse,
    agentYearEndWrapResponse,
    agentPipelinePageResponse,
    referralSourcesResponse,
    secureIdForTC,
    tcFilesResponse,
    getTCListResponse,
    getTCAgentAssignedResponse,
    tcLoader,
    getDealStatusTimelineResponse,
    isTimelineDrawerOpen,
    isModalViewOpen,
    getListingCoverSheetResponse,
    getContractsDetailsResponse,
    createListingCoverSheetResponse,
    updateListingCoverSheetResponse,
    getListingTypeResponse,
    uploadSellerProfileImageResponse,
    dealAgentId,
    loaderOnAgentInfo,
    escrowIdToEdit,
    dealToView,
    totalListingCount,
    isEscrowDrawerOpen,
    getVendorDetailsResponse,
    isEditEscrow,
    allVendors,
    isUploadImage,
    dealIdToUpload,
    uploadDocsForListingResponse,
    allListingImages,
    getContractStatusResponse,
    fetchDealTimelineResponse,
    isOpenTimelineChecklist,
    dealIdTimeline,
    selectedId,
    radiusFee,
    saveListingStepsResponse,
    tcOverviewListingResponse,
    listingState,
    isCreatingDeal,
    uploadW2FormResponse,
  } = brokerDashboard
  const TCListfinal = getTCListMapped(state)
  return {
    dealsListingResponse,
    globalAssetsResponse,
    tcOverviewResponse,
    tcDataDownloadResponse,
    rosterPageDataDownloadResponse,
    allAgentsDataDownloadResponse,
    allAccountsDataDownloadAction,
    allSaasAccountsDataDownloadAction,
    rosterPageAgentsResponse,
    rosterPageAllAgentsResponse,
    rosterPageAllAccountsResponse,
    rosterPageSaasAccountsResponse,
    agentYearEndWrapResponse,
    agentPipelinePageResponse,
    referralSourcesResponse,
    secureIdForTC,
    tcFilesResponse,
    preferences,
    getTCListResponse,
    getTCAgentAssignedResponse,
    TCListfinal,
    tcLoader,
    getDealStatusTimelineResponse,
    isTimelineDrawerOpen,
    isModalViewOpen,
    getListingCoverSheetResponse,
    getContractsDetailsResponse,
    createListingCoverSheetResponse,
    updateListingCoverSheetResponse,
    getListingTypeResponse,
    uploadSellerProfileImageResponse,
    dealAgentId,
    loaderOnAgentInfo,
    escrowIdToEdit,
    dealToView,
    totalListingCount,
    isEscrowDrawerOpen,
    getVendorDetailsResponse,
    isEditEscrow,
    allVendors,
    isUploadImage,
    dealIdToUpload,
    allListingImages,
    uploadDocsForListingResponse,
    getContractStatusResponse,
    selectedId,
    fetchDealTimelineResponse,
    isOpenTimelineChecklist,
    dealIdTimeline,
    radiusFee,
    uploadCSVToS3Action,
    uploadS3LocationAction,
    saveListingStepsResponse,
    tcOverviewListingResponse,
    listingState,
    isCreatingDeal,
    uploadW2FormResponse,
  }
}

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      getAllDealsListing: getAllDealsListingActions.request,
      createDeals: createDealAction.request,
      getAssetsDashboard: getAssetsDashboardAction.request,
      createNewGlobalAsset: createNewGlobalAssetAction.request,
      getAgentForRosterPage: getAgentForRosterPageAction.request,
      getAgentYearEndWrap: getAgentYearEndWrapAction.request,
      downloadAgentsRosterPage: rosterPageDataDownloadAction.request,
      downloadAllAgentsRosterPage: allAgentsDataDownloadAction.request,
      downloadAllAccountsRosterPage: allAccountsDataDownloadAction.request,
      downloadAllSaasAccountsRosterPage: allSaasAccountsDataDownloadAction.request,
      clearResults: getAgentForRosterPageAction.clear,
      getAgentPipelinePage: getAgentPipelinePageAction.request,
      clearAgentPipelinePageResults: getAgentPipelinePageAction.clear,
      getReferralSources: getReferralSourcesAction.request,
      deleteGlobalAsset: deleteGlobalAssetAction.request,
      updateAgentSettings: updateUserSettingsAction.request,
      // TC
      searchClientByText: searchClientByText.request,
      getSecureIdForTC: getSecureIdForTCAction.request,
      getFileForTC: getFileForTCAction.request,
      fetchUserReferralSettings: fetchUserReferralSettingsAction.request,
      saveStepsForProcuct: saveStepsForProductAction.request,
      saveListingSteps: saveListingStepsAction.request,
      tcOverviewListing: tcOverviewListingAction.request,
      saveFileTypeChange: saveFileTypeChangeAction.request,
      tcOverview: tcOverviewAction.request,
      tcDataDownload: tcDataDownloadAction.request,
      updateCommissionDemand: updateCommsionDemandAction.request,
      deleteCommissionDemand: deleteCommissionDemandActions.request,
      clearTcList: getAllDealsListingActions.clear,
      getTCList: getTCListAction.request,
      getDealStatusTimeline: getDealStatusTimelineAction.request,
      toggleTimelineDrawer: toggleTimelineDrawerAction.call,
      toggleModalView: toggleModalViewAction.call,
      toggleEscrowDrawer: toggleEscrowDrawerAction.call,
      toggleUploadImageDrawer: toggleUploadImageDrawerAction.call,
      setDealIdToUpload: setDealIdToUploadAction.call,
      uploadDocsForListing: uploadDocsForListingAction.request,
      resetListingImages: resetListingImages.call,
      resetDealId: resetDealId.call,
      updateTCAgent: updateTCAgentAction.request,
      updateAgentOptedTC: updateAgentOptedTCAction.request,
      getTCAgentOpted: getTCAgentAssignedAction.request,
      sendSlackNotifyOnStatusChange: sendSlackDealStatusChangeAction.request,
      showTCLoader: showTCLoaderActions.call,
      fetchDealTimeline: fetchDealTimelineAction.request,
      updateDealTimeline: updateDealTimelineAction.request,
      toggleTimelineChecklist: toggleTimelineChecklistAction.call,
      setDealIdTimeline: setDealIdTimelineAction.call,
      setRadiusFee: setRadiusFeeAction.call,

      // contracts
      getContractsDetails: getContractsDetails.request,
      // listings
      getListingCoverSheet: getListingCoverSheet.request,
      createListingCoverSheet: createListingCoverSheet.request,
      updateListingCoverSheet: updateListingCoverSheet.request,
      getListingType: getListingTypeAction.request,
      uploadSellerProfileImage:uploadSellerProfileImageAction.request,
      setDealToView: setDealToView.call,
      setLoaderOnAgentInfo: setLoaderOnAgentInfo.call,
      setEscrowIdToEdit: setEscrowIdToEdit.call,
      setDealAgentId: setDealAgentId.call,
      setTotalListingCount: setTotalListingCount.call,
      
      getVendorDetails: getVendorDetailsAction.request,
      setEscrowEdit: setEscrowEdit.call,
      setAllVendors: setAllVendors.call,
      setAllListingImages: setAllListingImagesAction.call,
      createAllListingImages: createAllListingImagesAction.request,
      createVendorDetails: createVendorDetailsAction.request,
      updateVendorDetails: updateVendorDetailsAction.request,
      getContractStatus: getContractStatusAction.request,
      selectVendorDetails: selectVendorDetailsAction.request,
      setSelectedId: setSelectedId.call,
      resetListings: resetListings.call,
      uploadCSVtoS3: uploadCSVToS3Action.request,
      uploadS3Location: uploadS3LocationAction.request,
      setListingState: setListingStateAction.call,
      setIsCreatingDeal: setIsCreatingDealAction.call,
      uploadW2Form: uploadW2FormAction.request,
      deleteW2Form: deleteW2FormAction.request,
      getAllAgentsForRosterPage: getAllAgentForRosterPageAction.request,
      getAllAccountsForRosterPage: getAllAccountForRosterPageAction.request,
      getSaasAccountsForRosterPage:getAllSaasAccountsAction.request,
    },
    dispatch
  ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)
