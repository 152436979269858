import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as Actions from "./actions";

function mapStateToProps(state) {
  const { consumerApp } = state;
  console.log("here is the state ", state);

  return {
    ...consumerApp,
  };
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      fetchWaitlistTable: Actions.fetchWaitlistAction.request,
      saveWaitlistStatus: Actions.saveWaitlistStatusAction.request,
      uploadAppAssets: Actions.uploadAppAssetsAction.request,
      updateAppCustomisations: Actions.updateAppCustomisationsAction.request,
      fetchAppCustomisations: Actions.fetchAppCustomisationsAction.request,
      selectedApp: Actions.selectedAppAction.call,
      fetchConsumerTable: Actions.fetchConsumerAction.request,
      fetchSearchBoardTable: Actions.fetchSearchBoardAction.request,
      fetchProperties: Actions.fetchPropertiesAction.request,
      fetchFamilyMembers: Actions.fetchFamilyMembersAction.request,
      fetchConsumerTeams: Actions.fetchConsumerTeamsAction.request,
      joinTheWaitlistAPI: Actions.joinTheWaitlistAction.request,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
