import React, {useEffect, useState} from "react"
import styled from "styled-components"
import { Tabs } from "antd";
import { add, get, throttle, isEmpty } from "lodash"

import { AppstoreOutlined, DownOutlined } from "@ant-design/icons";
import { Dropdown, Menu} from 'antd';
import CustomTable from "dumbComponents/BrokerDashboard/RoasterDashboard/CustomTable";
import container from "container/BrokerDashboard/globalPagesContainer"
import agentSearchContainer from "container/BrokerDashboard/agentSearchContainer"
import FilterBoard from "../RoasterDashboard/FilterBoard";
import teamContainer from "container/BrokerDashboard/teamContainer.js"

const OuterWrap = styled.div`
  padding: 64px 73px 64px 73px;
`

const BorderWrap = styled.div`
  border: 1px solid #e5e7eb;
  border-radius: 14px;
`;

const OverViewHeader=styled.div`
padding:20px 24px 19px 24px;
display:flex;
flex-direction:row;
justify-content:space-between;
`
const OverViewCards=styled.div`
margin:24px;
margin-top:16px;
display:grid;
grid-template-columns:repeat(4,1fr);
justify-content:space-between;
gap:36.6px;
`
const Card=styled.div`
border: 1px solid #E5E7EB;
border-radius:8px;
padding:24px;
height:134px;
`

const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    padding-top:12px;
    padding-bottom:12px;
  }
  .ant-tabs-tab {
    font-size: 14px;
    font-weight: 500;
    color: #1f2937;
    padding: 8px 24px;
  }
 
  .ant-tabs-tab-active {
    color: #6366f1;
    background: #EEF2FF;
    border-bottom: 2px solid #4F46E5;
  }
     .ant-tabs-tab + .ant-tabs-tab {
    margin-left: 0;
  }

  .ant-tabs-tab:hover {
    background: #EEF2FF;
  }
`;
const SearchDropdownContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content:space-between;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  padding: 8px 12px;
  width: ${(props) => props.width || "200px"} !important;
  cursor: pointer;
  height:41px;
`;

const { TabPane } = Tabs;


const formatValues=(num)=> {
  if (num >= 1000000000000) {
    return (num / 1000000000000).toFixed(2) + "T"; // Trillions
  } else if (num >= 1000000000) {
    return (num / 1000000000).toFixed(2) + "B"; // Billions
  } else if (num >= 1000000) {
    return (num / 1000000).toFixed(2) + "M"; // Millions
  }
  return num; 
}

const NewRoster = ({getAllAgentsForRosterPage,rosterPageAllAgentsResponse,rosterPageAllAccountsResponse,rosterPageSaasAccountsResponse,getAllAccountsForRosterPage,getSaasAccountsForRosterPage,...props}) => {
  const [activeTab, setActiveTab] = useState("2");
  const { data:agentData=[], isFetching: isFetchingAgentData, isFetchingMore: isFetchingMoreAgentData } = rosterPageAllAgentsResponse

  const overview = agentData?.overview || {};
  const results = agentData?.results || [];
  const { data:accountData=[], isFetching: isFetchingAccounts, isFetchingMore: isFetchingMoreAccounts } = rosterPageAllAccountsResponse

  const { data: saasUsersData = [], isFetching: isFetchingSaasData, isFetchingMore: isFetchingMoreSaasData} = rosterPageSaasAccountsResponse || {};
    console.log(agentData,"saasr")

  useEffect(() => {
    console.log("heer")
    const payload = {
      limit: 50,
    }
    if(activeTab==="1"){
      getAllAccountsForRosterPage({limit:50})
    }
    if(activeTab==="2"){
      console.log("heer")
      getAllAgentsForRosterPage({limit:50})
    }
    if(activeTab==="3"){
      getSaasAccountsForRosterPage({limit:50})
    }
  }, [activeTab])
  
  const handleChangeTab = (key) => {
    setActiveTab(key)
  }

return (
  <OuterWrap>
    {(!isFetchingAgentData || !isFetchingAccounts || isFetchingSaasData) && <BorderWrap>
   <OverViewHeader>
        <p style={{ fontWeight: "500px", fontSize: "18px" }}>Overview</p>
        <Dropdown trigger={["click"]}>
                <SearchDropdownContainer>
                  <span>Filter by: All </span>
                  <DownOutlined />
                </SearchDropdownContainer>
              </Dropdown>
      </OverViewHeader>
      <OverViewCards>
        <Card>
          <p style={{fontWeight:500, fontSize:"16px", color:"#6B7280",lineHeight:"19.36px"}}>Total Agents</p>
          <p style={{fontWeight:600, fontSize:"36px", lineHeight:"44px"}}>{overview.total_agents || 0}</p>
        </Card>
        <Card>
          <p style={{fontWeight:500, fontSize:"16px", color:"#6B7280",lineHeight:"19.36px"}}>Closed Deals</p>
          <p style={{fontWeight:600, fontSize:"36px", lineHeight:"44px"}}>{overview.total_deals}</p>
        </Card>
        <Card>
          <p style={{fontWeight:500, fontSize:"16px", color:"#6B7280",lineHeight:"19.36px"}}>In-Contract Deals</p>
          <p style={{fontWeight:600, fontSize:"36px", lineHeight:"44px"}}>{overview.total_in_contracts}</p>
        </Card>
        <Card>
          <p style={{fontWeight:500, fontSize:"16px", color:"#6B7280",lineHeight:"19.36px"}}>Total Price</p>
          <p style={{fontWeight:600, fontSize:"36px", lineHeight:"44px"}}>{formatValues(overview.total_price)}</p>
        </Card>
        <Card>
          <p style={{fontWeight:500, fontSize:"16px", color:"#6B7280",lineHeight:"19.36px"}}>Total Radius Fees</p>
          <p style={{fontWeight:600, fontSize:"36px", lineHeight:"44px"}}>{formatValues(overview.total_radius_fees)}</p>
        </Card>
        <Card>
          <p style={{fontWeight:500, fontSize:"16px", color:"#6B7280",lineHeight:"19.36px"}}>Total Gross Commission</p>
          <p style={{fontWeight:600, fontSize:"36px", lineHeight:"44px"}}>{formatValues(overview.total_gross_commission)}</p>
        </Card>
        <Card>
          <p style={{fontWeight:500, fontSize:"16px", color:"#6B7280",lineHeight:"19.36px"}}>Total Agent Commission</p>
          <p style={{fontWeight:600, fontSize:"36px", lineHeight:"44px"}}>{formatValues(overview.total_agent_commission)}</p>
        </Card>
        <Card>
          <p style={{fontWeight:500, fontSize:"16px", color:"#6B7280",lineHeight:"19.36px"}}>Average Annual Gross Commission</p>
          <p style={{fontWeight:600, fontSize:"36px", lineHeight:"44px"}}>{formatValues(overview.average_annual_gross_commission)}</p>
        </Card>
        <Card>
          <p style={{fontWeight:500, fontSize:"16px", color:"#6B7280", lineHeight:"19.36px"}}>Total Past Volume (Lasts 12 months)</p>
          <p style={{fontWeight:600, fontSize:"36px", lineHeight:"44px"}}>{formatValues(overview.total_past_volume)}</p>
        </Card>
      </OverViewCards>
    </BorderWrap>}

    <StyledTabs defaultActiveKey="1" activeKey={activeTab} onChange={handleChangeTab}>
      <TabPane
        tab={
          <span>
            <AppstoreOutlined /> Brokerage accounts
          </span>
        }
        key="1"
      >
        <BorderWrap>
          <FilterBoard activeTab={activeTab} data={accountData} isFetching={isFetchingAgentData} isFetchingMore={isFetchingMoreAgentData} />
        </BorderWrap>
      </TabPane>

      <TabPane
        tab={
          <span>
            <AppstoreOutlined /> Brokerage agents
          </span>
        }
        key="2"
      >
        <BorderWrap>
        <FilterBoard activeTab={activeTab} data={agentData} isFetching={isFetchingAccounts} isFetchingMore={isFetchingMoreAccounts} />
        </BorderWrap>
      </TabPane>

      <TabPane
        tab={
          <span>
            <AppstoreOutlined /> SaaS subscribed agents
          </span>
        }
        key="3"
      >
        <BorderWrap>
        <FilterBoard activeTab={activeTab} data={saasUsersData} isFetching={isFetchingSaasData} isFetchingMore={isFetchingMoreSaasData}/>
        </BorderWrap>
      </TabPane>
    </StyledTabs>
  </OuterWrap>

);
}
// export default NewRoster
export default teamContainer(agentSearchContainer(container(NewRoster)))