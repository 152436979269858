/* eslint-disable max-len */
import axios from "axios"

const baseUrl = `${process.env.REACT_APP_API_BASE_URL}/v1.0`

const baseUrlV2 = `${process.env.REACT_APP_API_BASE_URL}/v2.0`

const baseUrlV3 = `${process.env.REACT_APP_API_BASE_URL}/v3.0`;

export const getRequestsListAPI = (query = "") => {
  return axios.get(`${baseUrl}/get-rar-resources${query}`)
}

export const changeRequestStatusAPI = payload => axios.post(`${baseUrl}/change-rar-resource-status`, payload)

export const changeLinkAPI = payload => axios.post(`${baseUrl}/change-rar-resource-link`, payload)

export const getRARTeamListAPI = (query = "") => axios.get(`${baseUrl}/brokerage-teams${query}`)

// export const changeRARTeamAPI = (agent_id, is_admin, brokerage_team_id) => {
//   return axios.post(`${baseUrl}/brokerage-teams?agentId=${agent_id}&is_admin=${is_admin}&brokerage_team_id=${brokerage_team_id}`)
// }

export const deleteRARTeamAPI = teamId => axios.delete(`${baseUrl}/brokerage-teams/${teamId}`)

export const getAgentTypesAPI = () => axios.get(`${baseUrl}/agent-type-filter`)

export const getOrientationInfoAPI = agentId => axios.get(`${baseUrl}/onboarding-orientation-admin?agent_id=${agentId}`)

export const updateOrientationInfoAPI = payload => axios.put(`${baseUrl}/onboarding-orientation-admin`, payload)

export const createOrientationInfoAPI = payload => axios.post(`${baseUrl}/onboarding-orientation-admin`, payload)

export const getBrandingInfoAPI = agentId => axios.get(`${baseUrl}/assets-user-info-admin?agent_id=${agentId}`)

export const getBrandingAssetsAPI = secureId => axios.get(`${baseUrl}/branding-assets-fetch`, {
  headers: {
    "secure-id": secureId,
  },
})

export const createBrandingInfoAPI = payloadInfo => axios.post(`${baseUrl}/save-branding-info-admin`, payloadInfo)

export const updateBrandingInfoAPI = payloadInfo => axios.put(`${baseUrl}/update-branding-info-admin`, payloadInfo)

export const createBrandingAssetsAPI = payload => axios.post(`${baseUrl}/save-asset-info-admin`, payload)

export const updateBrandingAssetsAPI = payloadAssets => axios.put(`${baseUrl}/update-asset-info-admin`, payloadAssets)

export const fetchUserAgentTypeAPI = agentId => axios.get(`${baseUrl}/user-agent-type?agentId=${agentId}`)

export const getSetupProfileInfoAPI = agentId => axios.get(`${baseUrl}/onboarding-setup-profile-admin?agent_id=${agentId}`)

export const updateSetupProfileInfoAPI = payload => axios.put(`${baseUrl}/onboarding-setup-profile-admin`, payload)

export const changeUserAgentTypeAPI = (agentId, userTypeId) => axios.post(`${baseUrl}/user-agent-type`, {
  agentId,
  userTypeId,
})

export const tcOverviewAPI = (query = "") => axios.get(`${baseUrl}/tc-overview?overview=1${query}`)

//get call listings
export const tcOverviewListingAPI = (query = "") => {
  return axios.get(`${baseUrl}/tc-overview-listing?overview=1${query}`)
}
export const downloadTcDataAPI = (query = "") => {
  return axios.get(`${baseUrl}/brokerage-deals-download${query}`)
}

export const getDealsAPI = (query = "") => axios.get(`${baseUrl}/brokerage-deals${query}`)

export const getSecureIdAPI = agentId => axios.get(`${baseUrlV2}/user/secure_id?getAgentDetails=true&agentId=${agentId}`)

export const getVendorDetailsAPI = (agentId, dealId) => axios.get(`${baseUrl}/listing-cover/${dealId}/select_vendor?agent_id=${agentId}`)
export const createVendorDetailsAPI = (agentId, dealId, payload) => axios.put(`${baseUrl}/listing-cover/${dealId}?agent_id=${agentId}`, payload)
export const updateVendorDetailsAPI = (agentId, vendorId, payload) => axios.put(`${baseUrl}/listing-cover/vendor/${vendorId}?agent_id=${agentId}`, payload)
export const selectVendorDetailsAPI = (dealId, agentId, payload) => axios.put(`${baseUrl}/listing-cover/${dealId}?agent_id=${agentId}`, payload)

export const getAgentDetailsAPI = secureId => axios.get(`${baseUrlV2}/user/info`, {
  headers: {
    "secure-id": secureId,
  },
})

export const createDealAPI = payload => axios.post(`${baseUrl}/brokerage-deals`, payload)

export const editDealAPI = (dealId, payload) => axios.put(`${baseUrl}/brokerage-deals/${dealId}`, payload)

export const getAgentAddonsAPI = planId => axios.get(`${baseUrlV2}/stripe/add-ons?plan_id=${planId}`)

export const getBenefitsAPI = query => axios.get(`${baseUrlV2}/stripe/add-ons${query}`)

export const checkOutBrokerPlanAPI = (payload, secureId) => axios.post(`${baseUrlV2}/stripe/checkout-blink`, payload, {
  headers: {
    "secure-id": secureId,
  },
})

export const checkCouponAPI = payload => axios.post(`${baseUrlV2}/stripe/applyCoupon`, payload)

export const getUSStatesAPI = (query = "") => axios.get(`${baseUrlV2}/states${query}`)

export const saveAgentStatesAPI = (agentId, payload) => axios.put(`${baseUrlV2}/states/${agentId}`, payload)

export const getAssetDashboardAssetsAPI = () => axios.get(`${baseUrl}/rar-assets-global-admin`)

export const createNewGlobalAssetAPI = payload => axios.post(`${baseUrl}/rar-assets-global-admin`, payload)

export const getAgentAssetsAPI = agentId => axios.get(`${baseUrl}/rar-assets-specific-agent-admin?agentId=${agentId}`)

export const updateAgentAssetAPI = payload => axios.post(`${baseUrl}/rar-assets-specific-agent-admin`, payload)

export const getSignedURLAPI = (bucket, filename) => axios.get(`https://us-central1-sturdy-chimera-767.cloudfunctions.net/file-uploader?bucket=${bucket}&filename=${filename}`)

export const getBrokerAgentAPI = (query = "") => axios.get(`${baseUrlV2}/rar/brokerage-agents${query}`)

export const getAgentYearWrapAPI = (query = "") => axios.get(`${baseUrlV2}/fetch-banner-yearwrap${query}`)

export const getBrokerTeamsOverviewAPI = (query = "") => axios.post(`${baseUrl}/brokerage-teams-overview${query}`)

export const getBrokerageTeamDownloadAPI = (query = "") => axios.get(`${baseUrl}/brokerage-teams-export${query}`)

export const brokerDataDownloadAPI = (query = "") => axios.get(`${baseUrlV2}/rar/brokerage-agents${query}`)

export const getAgentPipelineAPI = (query = "") => axios.get(`${baseUrl}/agent-pipeline-metrics${query}`)

export const getReferralSourcesAPI = () => axios.get(`${baseUrlV2}/dashboard-referral-sources`)

export const cancelPlanAPI = (payload, secureId) => axios.post(`${baseUrlV2}/stripe/unsubscribe`, payload, {
  headers: {
    "secure-id": secureId,
  },
})

export const getSubscribedAddonsAPI = secureId => axios.get(`${baseUrlV2}/stripe/subscribed-add-ons`, {
  headers: {
    "secure-id": secureId,
  },
})

export const deleteGlobalAssetAPI = assetId => axios.post(`${baseUrl}/rar-assets-global-admin/delete/${assetId}`)
export const updateSubscriptionAPI = (payload, secureId) => axios.post(`${baseUrlV2}/user/addCustomizedSubscriptionSettings`, payload, {
  headers: {
    "secure-id": secureId,
  },
})

export const getAgentCardDetailsAPI = secureId => axios.get(`${baseUrl}/stripe/customers/payment`, {
  headers: {
    "secure-id": secureId,
  },
})

export const searchClientAPI = (payload, secureId) => fetch(`${baseUrlV2}/clients/search`, {
  method: "POST",
  body: JSON.stringify(payload),
  headers: {
    "secure-id": secureId,
  },
})

export const getTCFileAPI = tcId => axios.get(`${baseUrl}/brokerage-deals-documents/${tcId}`)

export const getContractStatusAPI = () => axios.get(`${baseUrl}/brokerage-contract-statuses`)

export const saveFileTypeChangeAPI = (dealId, payload) => axios.put(`${baseUrl}/update-contract/${dealId}`, payload)

export const saveStepsAPI = payload => axios.post(`${baseUrl}/brokerage-deals-steps`, payload)

// export const saveListingStepsAPI = payload => axios.post(`${baseUrl}/brokerage-listing-steps`, payload)


export const saveListingStepsAPI = (payload) => axios.post(`${baseUrl}/brokerage-listing-steps`, payload)

export const getCurrentCitiesServedAPI = username => axios
  .get(`${baseUrl}/user/cities-neighbourhoods-served?profileName=${username}`)

export const getNeighbourHoodFromCitiesAPI = city => axios
  .get(`${baseUrl}/getNeighbourhoods?city=${city}`)

export const saveNeighbourhoodsAPI = payload => axios
  .post(`${baseUrl}/user/neighbourhoods-served`, payload)

export const deleteNeighbourhoodAPI = payload => axios
  .put(`${baseUrl}/user/neighbourhoods-served`, payload)

export const getAllResourcesAPI = (q = "") => axios
  .get(`${baseUrl}/get-all-rar-documents-for-admins${q}`)

export const createResourceAPI = payload => axios.post(`${baseUrl}/create-rar-documents`, payload)

export const updateResourceAPI = payload => axios.post(`${baseUrl}/update-rar-documents`, payload)

export const deleteResourceAPI = payload => axios.post(`${baseUrl}/delete-rar-documents`, payload)

export const updateCommissionDemandAPI = payload => axios.post(`${baseUrl}/brokerage-deals-commission-demands`, payload)

export const deleteCommissionDemandAPI = dealId => axios.delete(`${baseUrl}/brokerage-deals-commission-demands/${dealId}`)

export const getTCListAPI = () => axios.get(`${baseUrl}/brokerage-tc-list`)

export const updateTCtoDealAPI = payload => axios.put(`${baseUrl}/assign-deal-tc`, payload)

export const updateAgentOptedTCAPI = payload => axios.post(`${baseUrl}/assign-agent-tc`, payload)

export const getTCAgentAssignedAPI = agentId => axios.get(`${baseUrl}/brokerage-tc-list?agent_id=${agentId}`)

export const getDealStatusTimelineAPI = listingId => axios.get(`${baseUrl}/brokerage-deal-timeline-listing?listing_id=${listingId}`)

export const slackNotifyDealStatusChangeAPI = query => axios.get(`${baseUrl}/deals-status-change-notification${query}`)

export const createAllListingImagesAPI = payload => axios.post(`${baseUrl}/brokerage-listing-images`, payload)

export const getListingCoverSheetAPI = path => axios.get(`${baseUrl}/listing-cover/${path}`)
export const getContractsDetailsAPI = dealId => axios.get(`${baseUrl}/brokerage-deals?dealId=${dealId}`)
export const createListingCoverSheetAPI = payload => axios.post(`${baseUrl}/listing-cover`, payload)
export const updateListingCoverSheetAPI = ({ payload, dealId }) => axios.put(`${baseUrl}/listing-cover/${dealId}`, payload)
export const getListingTypeAPI = (payload) => axios.get(`${baseUrl}/brokerage-listings-mappings?state=${payload}`,payload)

export const createDocusignTemplateAPI = payload => axios.post(`${baseUrl}/brokerage-docs/templates`, payload)
export const getDocusignTemplateAPI = () => axios.get(`${baseUrl}/brokerage-docs/templates`)
export const deleteDocusignTemplateAPI = id => axios.delete(`${baseUrl}/brokerage-docs/templates/${id}`)
export const getListingTotalCommonTemplateAPI = () => axios.get(`${baseUrl}/brokerage-docs/common-templates-count`)

export const fetchDealTimelineAPI = dealId => axios.get(`${baseUrl}/contract-timeline-product?deal_id=${dealId}`)
export const updateDealTimelineAPI = (dealId, payload) => axios.put(`${baseUrl}/update-contract-timeline/${dealId}`, payload)

export const createOffersDocusignTemplateAPI = payload => axios.post(`${baseUrl}/brokerage-offers-docs/templates`, payload)
export const getOffersDocusignTemplateAPI = () => axios.get(`${baseUrl}/brokerage-offers-docs/templates`)
export const deleteOffersDocusignTemplateAPI = id => axios.delete(`${baseUrl}/brokerage-offers-docs/templates/${id}`)

export const getOffersTotalCommonTemplateAPI = () => axios.get(`${baseUrl}/brokerage-offers-docs/common-templates-count`)

export const getAgentBrokerageInfoAPI = agentId => axios.get(`${baseUrlV2}/agent-brokerage-info?agentId=${agentId}`)
export const postAgentBrokerageInfoAPI = payload => axios.post(`${baseUrlV2}/agent-brokerage-info`, payload)
export const updateAgentBrokerageInfoAPI = (agentId, payload) => axios.put(`${baseUrlV2}/agent-brokerage-info/${agentId}`, payload)

export const addCreditCardDetailsAPI = payload => axios.post(`${baseUrl}/email/add-card-details`, payload)
export const getAgentBrokerageInfoMetadataAPI = () => axios.get(`${baseUrlV2}/agent-brokerage-info-metadata`)

//ICA APIS
export const fetchICAListingsAPI = agentId => axios.get(`${baseUrl}/brokerage-ica/envelopes?agentId=${agentId}`)
export const fetchAgentICADetailsAPI = agentId => axios.get(`${baseUrl}/user/onboarding/get-ica-info?agentId=${agentId}`)
export const triggerDocusignSenderViewAPI = payload => axios.post(`${baseUrl}/brokerage-ica/envelopes/sender-view`, payload)
export const triggerDocusignRecieverViewAPI = payload => axios.post(`${baseUrl}/brokerage-ica/envelopes/receiver-view`, payload)
export const modifyICAStatusAPI = payload => axios.post(`${baseUrl}/brokerage-ica/envelopes/sending`, payload)
export const mergeReviewSendAPI = (payload, agentId) => axios.put(`${baseUrl}/brokerage-ica/envelopes?agentId=${agentId}`, payload)
export const uploadICAAPI = payload => axios.post(`${baseUrl}/brokerage-ica/envelopes/documents`, payload)
export const uploadTeamAgreementAPI = payload => axios.post(`${baseUrl}/brokerage-ica/templates/documents`, payload)
export const inviteWithoutSubscriptionAPI = (payload, agentId, fetchIca) => axios.post(`${baseUrl}/user/onboarding/invite-without-subscription?agent_id=${agentId}&fetchIca=${fetchIca}`, payload)
export const fetchTeamAgreementsAPI = (type, agentId, teamId) => axios
  .get(`${baseUrl}/brokerage-ica/templates?skip=0&limit=100&type=${type}&agentId=${agentId}${teamId ? `&teamId=${teamId}` : ""}`)
export const sendOnboardingEmailAPI = payload => axios.post(`${baseUrl}/email/brokerage/onboarding`, payload)
export const getOnboardingURLAPI = agentId => axios.get(`${baseUrl}/user/brokerage/onboarding-url?agent_id=${agentId}`)
export const fetchFullySignedICAAPI = agentId => axios.get(`${baseUrl}/brokerage-ica/envelopes/signed-documents?agentId=${agentId}`)
export const uploadFullySignedICAAPI = payload => axios.post(`${baseUrl}/brokerage-ica/envelopes/signed-documents`, payload)
export const saveTeamAgreementAPI = (teamId, payload) => axios.put(`${baseUrl}/brokerage-teams/${teamId}`, payload)
export const triggerTemplateEditViewAPI = payload => axios.post(`${baseUrl}/brokerage-ica/templates/edit-view`, payload)
export const getTemplateDownloadURLAPI = (templateId, agentId, teamId) => axios.get(`${baseUrl}/brokerage-ica/templates/documents?templateId=${templateId}&agentId=${agentId}&teamId=${teamId}`)

//stripe
export const saveStripeTokenAPI = (payload, secureId) => axios.post(`${baseUrl}/stripe/customers`, payload, {
  headers: {
    "secure-id": secureId,
  },
})

export const getTentativeBrokerageSubscriptionDetailsAPI = agentId => axios.get(`${baseUrlV2}/user/tentative/brokerage-info?agent_id=${agentId}`)
export const fetchRecruiterAndAsmInfoAPI = agentId => axios.get(`${baseUrl}/soul/admin-user?getRecruiters=true&getAsmAssignments=true&agentId=${agentId}`)
export const updateRecruiterAndAsmInfoAPI = (agentId, payload) => axios.post(`${baseUrl}/soul/user/onboarding/recruiter-info?agent_id=${agentId}`, payload)
export const fetchAgentAsmRecruiterInfoAPI = agentId => axios.get(`${baseUrl}/soul/user/onboarding/get-recruiter-info?agent_id=${agentId}`)
export const uploadS3LocationAPI = payload => axios.post(`${baseUrl}/import-agent-transaction`, payload)
export const triggerVoidAndDeleteAPI = payload => axios.post(`${baseUrl}/brokerage-ica/envelopes/void-or-delete`, payload)
export const triggerDeleteFullyLoadedICAAPI = payload => axios.post(`${baseUrl}/brokerage-ica/envelopes/delete-fully-signed-ica`, payload)
export const updateSubscriptionDateAPI = payload => axios.post(`${baseUrlV3}/stripe/extendTrial`, payload)
export const uploadW2FormAPI = payload => axios.post(`${baseUrl}/upload-w2-documents`, payload)
export const defaultPaymentAPI = (secureId, payload) => axios.post(`${baseUrl}/stripe/customers/payment-method/default`, payload, {
  headers: {
    "secure-id": secureId,
  },
})
export const editPaymentAPI = (secureId, payload) => axios.put(`${baseUrl}/stripe/customers/payment-method`, payload, {
  headers: {
    "secure-id": secureId,
  },
})
export const getClientSecretAPI = (secureId, payload) => axios.post(`${baseUrl}/stripe/customers/secret`, payload, {
  headers: {
    "secure-id": secureId,
  },
})

export const deletePaymentAPI = (secureId, payload) => axios.post(`${baseUrl}/stripe/customers/payment-method/delete`, payload, {
  headers: {
    "secure-id": secureId,
  },
})

export const fetchPaymentAPI = (secureId) => axios.get(`${baseUrl}/stripe/customers/payment`, {
  headers: {
    "secure-id": secureId,
  },
})

export const fetchInvoiceDataAPI = (secureId) => axios.get(`${baseUrlV3}/stripe/invoices`, {
  headers: {
    "secure-id": secureId,
  },
})
export const fetchSubStatusDataAPI = (secureId, productType) => axios.get(`${baseUrlV2}/stripe/subscriptions/status?productName=${productType}`, {
  headers: {
    "secure-id": secureId,
  },
})
export const fetchEnoDataAPI = (secureId) => axios.get(`${baseUrlV2}/stripe/subscriptions/status?productName=eoi`, {
  headers: {
    "secure-id": secureId,
  },
})
export const deleteW2FormAPI = (payload) => {
  return axios.delete(`${baseUrl}/delete-w2-documents`, {
    data: payload,
  })
}
