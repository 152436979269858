import React from "react"
import styled from "styled-components"
import { get } from "lodash"
import queryString from "query-string"
import root from "window-or-global"
import { Switch, Route, Redirect, Link } from "react-router-dom"
import AgentDeals from "components/AgentDeals"
import container from "container/BrokerDashboard/globalPagesContainer"
import agentContainer from "container/BrokerDashboard/editAgentContainer"
import subscriptionContainer from "container/BrokerDashboard/subscriptionContainer"
import Deals from "components/Deals"
import SidePabel from "./SidePanel"
import Subscription from "./Subscription"
import SubscriptionV2 from "./SubscriptionV2"
import Assets from "./Assets"
import AssetsDashboard from "./AssetsDashboard"
import AddDeal from "./AddDeal"
import DealsList from "./DealsList"
import DealsDashboard from "dumbComponents/BrokerDashboard/DealsDashbaord"
import RecruitAndEarnAnalytics from '../../../dumbComponents/RecruitAndEarn/Analytics';
import EditAgentInfo from "./EditAgentInfo"
import OrientationInfo from "./OrientationInfo"
import Offers from "dumbComponents/OffersDashboard"
import ICA from "./ICA"
import EditAgentInfoDashboard from "./EditAgentInfoDashboard"
import SetupProfileInfo from "./SetupProfileInfo"
import ViewDeal from "./ViewDeal"
import ViewPage from "./Docusign/ViewPage"
import SidePanelV2 from "./SidePanelV2"
import { TeamModal, InviteWithoutSubscriptionModal } from "./SubscriptionV2/components/index"

const Wrap = styled.div`
  display: flex;
`
const Main = styled.div`
  flex: 1;
`

const Tabs = styled.div`
  display: flex;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 20px 0px;
  > a {
    color: #100063;
    margin: 0px 20px;
    text-decoration: none;
  }
`

class AgentDashboard extends React.PureComponent {
  state = {}

  componentDidMount() {
    const {
      fetchUserReferralSettings,
      agentDetailsResponse,
      getOrientationInfo,
      getBrandingInfo,
      getBrandingAssets,
      getSetupProfileInfo,
      setTeamIDFromURL,
      agentBrokerageInfoResponse,
    } = this.props
    const agentId = get(this.props, "match.params.agentId")

    const { search } = root.location
    const parsedQuery = queryString.parse(search)
    const teamIdFromParams = parsedQuery.addToTeam || ""
    const {
      data: brokerageInfo,
      isFetching: fetchingBrokerageInfo,
    } = agentBrokerageInfoResponse || {}

    if (teamIdFromParams) {
      setTeamIDFromURL(teamIdFromParams)
    }

    if (!fetchingBrokerageInfo && brokerageInfo && brokerageInfo.brokerage_team_id) {
      setTeamIDFromURL(brokerageInfo.brokerage_team_id)
    }

    this.setState({
      agentId,
    })
    getBrandingAssets({ agentId })
    fetchUserReferralSettings({ agentId })
    getOrientationInfo({
      agentId,
    })
    getBrandingInfo({
      agentId,
    })
    getSetupProfileInfo({ agentId })
  }

  render() {
    const { agentId } = this.state
    const {
      selectedPlan,
      toggleTeamModal,
      toggleTeamCreateModal,
      isInviteWithoutSubscriptionModalOpen,
      toggleInviteWithoutSubscriptionModal,
    } = this.props

    console.log('asdsad', selectedPlan)

    return (
      <>
        <Wrap>
          <SidePanelV2 />
          <Main>
            <Tabs>
              <Link to={`/broker/dashboard/${agentId}/subscription`}>
                Subscription
              </Link>
              {/* <Link to={`/broker/dashboard/${agentId}/subscriptionV2`}>
                Old Subscription
              </Link>
              <Link>
              {selectedPlan && (typeof selectedPlan === "object" && Object.keys(selectedPlan).length > 0) && (
              </Link> */}
              {selectedPlan && (
                <Link to={`/broker/dashboard/${agentId}/ica`}>
                  ICA
                </Link>
              )}
              <Link to={`/broker/dashboard/${agentId}/assets`}>
                Assets
              </Link>
              <Link to={`/broker/dashboard/${agentId}/deals`}>
                Transactions
              </Link>
              {/* <Link to={`/broker/dashboard/${agentId}/agent-invite-analytics`}>
                Invites
              </Link> */}
              <Link to={`/broker/dashboard/${agentId}/edit-agent-info`}>
                Edit Agent Details
              </Link>
              <Link to={`/broker/dashboard/${agentId}/deals-info`}>
                Deals
              </Link>
              <Link to={`/broker/dashboard/${agentId}/orientation-info`}>
                Onboarding Details
              </Link>
              <Link to={`/broker/dashboard/${agentId}/offers-info?&agentId=${agentId}`}>
                Offers
              </Link>
            </Tabs>
            <Switch>
              <Route exact path="/broker/dashboard/:agentId/subscriptionV2" component={Subscription} />
              <Route exact path="/broker/dashboard/:agentId/subscription" component={SubscriptionV2} />
              <Route exact path="/broker/dashboard/:agentId/ica" component={ICA} />
              {/* https://stagingblink.radiusagents.com/broker/dashboard/ica/documents-callback?agentId=11026584&envelopeId=4b9272b8-1785-4f0c-be2a-87dbbef65763&event=Save */}
              <Route exact path="/broker/dashboard/:agentId/assets" component={AssetsDashboard} />
              <Route exact path="/broker/dashboard/:agentId/deals" component={DealsDashboard} />
              <Route exact path="/broker/dashboard/:agentId/offers-info" component={Offers} />
              <Route exact path="/broker/dashboard/:agentId/deals/edit/:dealId" component={AddDeal} />
              <Route exact path="/broker/dashboard/:agentId/deals/view/:dealId" component={ViewDeal} />
              {/* <Route exact path="/broker/dashboard/:agentId/deals/view/:dealId/:email" component={ViewDeal} /> */}
              <Route exact path="/broker/dashboard/:agentId/edit-agent-info" component={EditAgentInfoDashboard} />
              <Route exact path="/broker/dashboard/:agentId/deals-info" component={ViewPage} />
              <Route exact path="/broker/dashboard/:agentId/orientation-info" component={OrientationInfo} />
              <Route exact path="/broker/dashboard/:agentId/deals/create" component={AddDeal} />
              <Route exact path="/broker/dashboard/:agentId/deals/create-old" component={Deals} />
              {/* <Redirect path="/broker/dashboard/:agentId/subscription" to="/broker/dashboard/:agentId/deals"/> */}
              <Route exact path="/broker/dashboard/:agentId/agent-invite-analytics" component={RecruitAndEarnAnalytics} />
              <Redirect path="/broker/dashboard" to="/broker/requests" />
              <Redirect path="/broker/dashboard/:agentId/subscription" to="/broker/dashboard/:agentId/deals" />
            </Switch>
          </Main>
        </Wrap>
        {toggleTeamModal && (
          <TeamModal
            show
            toClose={() => {
              toggleTeamCreateModal(false)
            }}
          />
        )}
        {isInviteWithoutSubscriptionModalOpen && (
          <InviteWithoutSubscriptionModal
            show
            toClose={() => {
              toggleInviteWithoutSubscriptionModal({
                bool: false,
                data: null,
              })
            }}
          />
        )}
      </>
    )
  }
}

export default subscriptionContainer(agentContainer(container(AgentDashboard)))
